import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";

export default function PageLinkBoxes(props) {
  const [value, setValue] = useState(0);

  return (
    <BottomNavigation
      value={value}
      onChange={(e, n) => {
        setValue(n);
      }}
      showLabels
      style={{ flexWrap: "wrap" }}
    >
      {props.links &&
        Array.isArray(props.links) &&
        props.links.map((link, i) => (
          <BottomNavigationAction
            key={i}
            label={link.title}
            icon={link.icon}
            component={HashLink}
            to={
              !link.externalLink
                ? `#${link.linkTo ? link.linkTo : ""}`
                : link.externalLink
            }
            smooth
            style={{ textAlign: "center", minWidth: "unset" }}
          />
        ))}
    </BottomNavigation>
  );
}
