import { Button, Container, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import hospital from "../../../../../assets/imgs/backgrounds/hospital-front.jpg";
import mntPass from "../../../../../assets/imgs/backgrounds/mountain-canyon.jpg";
import radiology from "../../../../../assets/imgs/backgrounds/radiology.jpg";
import ImgCard from "./img-card/img-card";
import FMCImgCard from "./img-card/FMC-img-card/FMC-img-card";
import SSClinicImgCard from "./img-card/SSClinic-img-card/SSClinic-img-card";
import { HashLink } from "react-router-hash-link";
import { useDispatch } from "react-redux";
import Quality from "./img-card/quality-assurance/quality";
import "../../../../../css/utils/utils.scss"

const useStyles = makeStyles((theme) => ({
  containerRoot: {
    background: `url(${mntPass})`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "50%",
    backgroundSize: "cover",
  },
  mask: {
    marginBottom: -20,
    marginTop: -100,
    "@media(max-width: 767px)": {
      marginTop: -50,
      marginBottom: -10,
    },
  },
}));

export default function ImgCardSection() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const setPanel = (id) => dispatch({ type: "SET_PANEL", payload: id });

  return (
    <div className={classes.containerRoot}>
      <Container maxWidth={"lg"} style={{ margin: "4rem auto" }}>
        <Grid container spacing={4}>
          <Grid
            item
            sm={12}
            md={6}
            aria-label={"Family Medical Center"}
            id="fmc-img-box"
          >
            <FMCImgCard />
          </Grid>
          <Grid item sm={12} md={6} aria-label="Surgical Specialty Clinic">
            <SSClinicImgCard />
          </Grid>
          <Grid item sm={6} md={4}>
            <ImgCard
              image={<img src={hospital} alt={"About us"} />}
              lightBg
              title={"About Us/FAQ"}
              content={
                <p>
                  Weiser Memorial Hospital is a full service not-for-profit
                  community hospital that has been serving the healthcare needs
                  of Washington County and surrounding areas since 1950.
                </p>
              }
              link={
                <Button
                  variant={"contained"}
                  color={"primary"}
                  style={{ margin: "0.5rem" }}
                  component={HashLink}
                  to={"/about/#top"}
                >
                  Learn More
                </Button>
              }
            />
          </Grid>
          <Grid item sm={6} md={4}>
            <Quality />
          </Grid>
          <Grid item sm={6} md={4}>
            <ImgCard
              image={<img src={radiology} alt={"TwentyFour Seven Services"} />}
              lightBg
              title={"24/7 Services"}
              content={
                <div>
                  <p>
                    We have physicians in house and an emergency department
                    staffed with Board Certified Emergency Room RN’s 24 hours a
                    day, seven days a week, treating over 4,000 patients per
                    year.
                  </p>
                </div>
              }
              link={
                <Button
                  variant={"contained"}
                  color={"primary"}
                  style={{ margin: "0.5rem" }}
                  component={HashLink}
                  to={"/services#emergency-services"}
                  onClick={() => setPanel("emergency-services")}
                >
                  Learn More
                </Button>
              }
            />
          </Grid>
        </Grid>
      </Container>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className={classes.mask}
      >
        <path
          fill="#fff"
          fillOpacity="1"
          d="M0,160L48,170.7C96,181,192,203,288,218.7C384,235,480,245,576,256C672,267,768,277,864,282.7C960,288,1056,288,1152,266.7C1248,245,1344,203,1392,181.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
}
