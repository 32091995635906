import { makeStyles } from "@material-ui/core";
import React from "react";
import Footer from "../components/footer/footer";
import MainMenu from "../components/menu-bar/main-menu";

export default function PageFrame(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      color: "black",
      background: "white",
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MainMenu />
      {props.children}
      <Footer />
    </div>
  );
}
