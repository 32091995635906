import {
  makeStyles,
  Grid,
  Button,
  Divider,
  Paper,
  Grow,
} from "@material-ui/core";
import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import river from "../../../../../assets/imgs/backgrounds/river.jpeg";
import steve from "../../../../../assets/imgs/portrait/ceo-steve.jpg";
import { useDispatch } from "react-redux";

export default function CeoSection() {
  const [readMore, setReadMore] = useState(false);
  const useStyles = makeStyles((theme) => ({
    ceoContent: {
      background: `rgba(${theme.palette.primary.rgb}, 0.75)`,
    },
    ceoImage: {
      background: "white",
      color: "black",
      padding: 5,
      paddingBottom: 0,
      borderTopRightRadius: 20,
      "& img": {
        width: "100%",
        maxWidth: 400,
        borderTopRightRadius: 20,
      },
    },
    content: {
      "& a": {
        color: "white",
        textDecoration: "underline",
      },
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div
      style={{
        minHeight: "100vh",
        background: `url(${river})`,
        backgroundSize: "cover",
        backgroundPosition: "50% 90%",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        margin: "4rem auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          wrap={"wrap"}
          className={classes.ceoContent}
          style={{
            color: "white",
            padding: 10,
            margin: "2rem",
            maxWidth: 850,
          }}
        >
          <Grid
            item
            md
            style={{
              display: "flex",
              flexFlow: "column",
              justifyContent: "center",
              margin: 20,
            }}
          >
            <h1>Steven Hale, CEO</h1>
            <Divider />
            <div
              className={classes.content}
              style={{
                height: "auto",
                maxWidth: 500,
                overflowY: "hidden",
              }}
            >
              <p>
                I would like to take this opportunity to thank the community for
                the continued support we receive at Weiser Memorial Hospital,{" "}
                <HashLink to={"/services#family-medical-center"}>
                  Family Medical Center
                </HashLink>{" "}
                and the{" "}
                <HashLink to={"/services#surgical-specialty-clinic"}>
                  Weiser Memorial Hospital Surgical and Specialty Clinic
                </HashLink>
                . It is a privilege to serve so many of you. Not only have you
                given us the opportunity to provide{" "}
                <HashLink to={"/services#top"}>healthcare services</HashLink>,
                but many of you have given countless hours of{" "}
                <HashLink to={"/foundation#friends-of-the-hospital"}>
                  volunteer service
                </HashLink>{" "}
                to the hospital and gracious{" "}
                <HashLink to={"/foundation#ways-of-giving"}>
                  financial donations
                </HashLink>{" "}
                to the Foundation.
                <br />
                <br />
                We are fortunate to have a hospital in our community. We
                recognize that it is your support and the work of our dedicated
                physicians and staff that makes it possible. We have a wonderful
                group of Family Physicians, Physician’s Assistants, and Family
                Nurse Practitioners who are committed to the care of their
                patients and to the healthcare of our community. Their
                dedication can be seen at all hours and as I visit out in the
                community, I frequently hear positive comments about our family
                practice physicians and how fortunate we are to have them here
                in Weiser.
              </p>
              <Grow in={readMore} mountOnEnter unmountOnExit>
                <p>
                  In addition to our family practice physicians, our community
                  benefits from the presence of many different specialists.
                  These specialties include a fulltime orthopaedic surgeon, two
                  general surgeons, a podiatrists, and three cardiologists.
                  Weiser is fortunate to have such talented physicians willing
                  to travel from all areas of the Treasure Valley to see
                  patients more conveniently here in Weiser.
                  <br />
                  <br />
                  As a hospital, we appreciate the opportunity we have to meet
                  your emergency care needs as well. Weiser Memorial staffs its
                  <HashLink
                    to={"/services#emergency-services"}
                    onClick={() =>
                      dispatch({
                        type: "SET_PANEL",
                        payload: "emergency-services",
                      })
                    }
                  >
                    {" "}
                    emergency room
                  </HashLink>{" "}
                  with 24/7 physicians who specialize in emergency care, as well
                  as{" "}
                  <HashLink
                    to={"/services#lab-services"}
                    onClick={() =>
                      dispatch({ type: "SET_PANEL", payload: "lab-services" })
                    }
                  >
                    24/7 lab
                  </HashLink>{" "}
                  and{" "}
                  <HashLink
                    to={"/services#imaging-services"}
                    onClick={() =>
                      dispatch({
                        type: "SET_PANEL",
                        payload: "imaging-services",
                      })
                    }
                  >
                    radiology services
                  </HashLink>
                  . These are highly trained emergency doctors, who have worked
                  in busy hospital emergency departments. You are in great hands
                  when you come to the Weiser ER. I have complete faith in the
                  emergency care our physicians and staff provide.
                  <br />
                  <br />
                  The future for Weiser Memorial Hospital is bright. There are{" "}
                  <HashLink to={"/about#CHNA"}>many challenges</HashLink> ahead
                  for all aspects of healthcare, but we are embracing the
                  challenges and building on our successes. If you haven’t been
                  to Weiser Memorial in recent years, I invite you to come see
                  the many changes that have taken place. Our biggest project
                  for last year was the Nurses’ Station renovation project,
                  which was made possible due to the dedicated fundraising of
                  our <HashLink to={"/foundation#top"}>Foundation</HashLink> and{" "}
                  <HashLink to={"/foundation#friends-of-the-hospital"}>
                    Friends of the Hospital
                  </HashLink>
                  . If you haven’t chosen your family doctor, or looked into the
                  care you can get from Weiser Memorial, I encourage you to look
                  to us for your care. We are here to provide{" "}
                  <HashLink
                    to={"/about#hospital-quality"}
                    onClick={() =>
                      dispatch({
                        type: "SET_PANEL",
                        payload: "hospital-quality",
                      })
                    }
                  >
                    high quality
                  </HashLink>{" "}
                  healthcare for our community.
                  <br />
                  <br />
                  <br />
                  Sincerely,
                  <br />
                  Steven Hale, FACHE
                  <br />
                  Chief Executive Officer
                </p>
              </Grow>
            </div>
            <div>
              <Button
                variant={"text"}
                color={"secondary"}
                onClick={() => setReadMore(!readMore)}
              >
                {!readMore ? "Read More" : "Show Less"}
              </Button>
            </div>
          </Grid>
          {/* <ScrollAnimation animateIn="fadeInRight" animateOnce duration={1}> */}
          <Grid item md>
            <Paper style={{ display: "flex", flexFlow: "column", padding: 10 }}>
              <img src={steve} alt={"Steven Hale CEO"} />
              <p>
                Steven Hale, FACHE
                <br />
                Chief Executive Officer
              </p>
              <HashLink to={"/about#leadership"}>Hospital Leadership</HashLink>
            </Paper>
          </Grid>
          {/* </ScrollAnimation> */}
        </Grid>
      </div>
      {/* </Overlay> */}
    </div>
  );
}
