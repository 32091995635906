import React from "react";
import groupPic from "../../../../../../../assets/imgs/portrait/familymedical-group.jpg";
import { makeStyles, Grid } from "@material-ui/core";

export default function FMCImage(props) {
  const useStyles = makeStyles((theme) => ({
    fmcDr: {
      color: props.light ? "initial" : "white",
      "& p": {
        marginTop: 0,
      },
    },
  }));
  const classes = useStyles();

  let names = [
    "Andrea Wheeler-NP",
    "Melissa Hathhorn PMHNP",
    "Brad Terry DO",
    "Jordan Blanchard MD",
    "Melissa Smith MD",
  ];

  return (
    <div style={{ padding: 10 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        <div className={classes.fmcDr}>
          <img
            style={{ maxHeight: 270, margin: 5, borderRadius: 10 }}
            src={groupPic}
            alt="Family Medical Providers"
          />
          <Grid container spacing={1} justifyContent="center">
            {names.map((name) => (
              <Grid item key={name}>
                <small>{name}</small>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      {!props.noAnimate ? (
        // <ScrollAnimation
        //   animateIn="bounce"
        //   initiallyVisible
        //   animateOnce
        //   duration={1.75}
        // >
        <div style={{ color: props.light ? "initial" : "white" }}>
          <h3 style={{ margin: 0 }}>
            <b>(208) 549-4424</b>
          </h3>
          <h4 style={{ margin: 0 }}>
            8 A.M.–5 P.M. MONDAY - FRIDAY • OPEN THURSDAY UNTIL 7 P.M.
          </h4>
          <p style={{ margin: 0 }}>
            <i>*Walk-ins available for minor ailments.</i>
          </p>
        </div>
      ) : (
        // </ScrollAnimation>
        <div style={{ color: props.light ? "initial" : "white" }}>
          <h3 style={{ margin: 0 }}>
            <b>(208) 549-4424</b>
          </h3>
          <h4 style={{ margin: 0 }}>
            8 A.M.–5 P.M. MONDAY - FRIDAY • OPEN THURSDAY UNTIL 7 P.M.
          </h4>
          <p style={{ margin: 0 }}>
            <i>*Walk-ins welcome.</i>
          </p>
        </div>
      )}
    </div>
  );
}
