import React from "react";
import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  darkContent: {
    background: theme.palette.primary.main,
    color: "white",
    padding: 10,
    marginBottom: 10,
  },
}));

export default function Bistro(props) {
  const classes = useStyles();

  return (
    <>
      <h2>5th Street Bistro</h2>
      <div className={classes.darkContent}>
        <h2>Bistro is open for dine-in or to-go orders.</h2>
        <h2>Hours: 7am – 4:30pm</h2>
        <h4>
          *Please understand that wait times may vary depending on patient
          needs.
        </h4>
      </div>
      <List className={classes.darkContent}>
        <ListItem>
          <ListItemText>
            Patients can place orders from the menu and take advantage of our
            great daily specials, homemade soups and fresh salad bar.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText></ListItemText>
        </ListItem>
      </List>
    </>
  );
}
