import React from "react";

export default function PhysicianDirectory(props) {
  return (
    <div style={{ maxWidth: 500, margin: "0 auto" }} id={"physician-directory"}>
      <h2>Physician's Directory List</h2>
      <div className={"dark-content"}>
        <h3>CARDIOLOGY</h3>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p>
              Lyndon Box, M.D. <br />
              (First Tuesday){" "}
            </p>
            <p>
              Mathew Nelson, M.D., <br />
              FACC, FASE (Second & Fourth Tuesday){" "}
            </p>
            <p>
              Karl Undesser, M.D., PhD, <br />
              Cardiology (Third Tuesday){" "}
            </p>
          </div>
          <a href={"tel:+12085493152"}>
            <h4>(208) 549-3152</h4>
          </a>
        </div>
      </div>

      <div className={"light-content"}>
        <h3>GENERAL SURGERY</h3>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Christopher Liby, DO</p>
          <a href={"tel:+12085493152"}>
            <h4>(208) 549-3152</h4>
          </a>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Tony Tesnohledik, M.D.</p>
          <a href={"tel:+12085493152"}>
            <h4>(208) 549-3152</h4>
          </a>
        </div>
      </div>

      <div className={"dark-content"}>
        <h3>OBSTETRICS AND GYNECOLOGY</h3>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Robert Mairs, D.O.</p>
          <a href={"tel:+12085493152"}>
            <h4>(208) 549-3152</h4>
          </a>
        </div>
      </div>

      <div className={"light-content"}>
        <h3>{"Orthopedics and Sports Medicine".toUpperCase()},</h3>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Matthew Kai Elliott, DO</p>
          <a href={"tel:+12085493152"}>
            <h4>(208) 549-3152</h4>
          </a>
        </div>
      </div>

      <div className={"dark-content"}>
        <h3>PODIATRY </h3>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Clark Johnson, D.P.M</p>
          <a href={"tel:+12085493152"}>
            <h4>(208) 549-3152</h4>
          </a>
        </div>
      </div>

      <div className={"light-content"}>
        <h3>FAMILY MEDICAL CENTER</h3>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p>Jordan Blanchard, M.D.</p>
            <p>Brad Terry, D.O.</p>
            <p>Selena Ankarberg, FNP</p>
            <p>Sarah Martin, FNP</p>
          </div>
          <a href={"tel:+12085494424"}>
            <h4>(208) 549-4424</h4>
          </a>
        </div>
      </div>

      <div className={"dark-content"}>
        <h3>TWO RIVERS MEDICAL CLINIC</h3>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p>Suzanna Hubele, M.D.</p>
            <p>Lore Wootton, M.D.</p>
            <p>Kent L. Hamilton, PAC</p>
          </div>
          <a href={"tel:+12085490211"}>
            <h4>(208) 549-0211</h4>
          </a>
        </div>
      </div>
    </div>
  );
}
