import {
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import emergencyLogo from "../../../../assets/imgs/logos/wmh/EmergencyServicesLogo.svg";
import WaveTitle from "../../../components/wave-title/wave-title";

const useStyles = makeStyles((theme) => ({
  list: {
    background: theme.palette.primary.main,
    color: "white",
    "& li": {
      textAlign: "center",
    },
  },
  staffChip: {
    background: theme.palette.secondary.main,
  },
}));

export default function EmergencyServices(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={2} justifyContent={"space-evenly"}>
      <Grid item md={12}>
        <WaveTitle title={"Emergency Services"} mask={"smooth-arch"} />
        <img
          src={emergencyLogo}
          alt={"Emergency Services Logo"}
          style={{ maxWidth: "100%", marginTop: 20 }}
        />
      </Grid>
      <Grid item md={11}>
        <div>
          <Chip
            style={{ margin: 5 }}
            className={classes.staffChip}
            label={
              <p>
                Open <b>24</b> Hours a Day - <b>7</b> Days a Week
              </p>
            }
          />
          <Chip
            style={{ margin: 5 }}
            className={classes.staffChip}
            label={
              <p>
                On-Site <b>24</b> Hour Radiology & Lab Services
              </p>
            }
          />
        </div>
        <p>
          We understand that accidents and medical emergencies occur
          unexpectedly. These events can be stressful not only for you, but for
          family and friends too. So, it's comforting to know there is a
          professional medical team here in your community trained to respond at
          a moment's notice.
        </p>
      </Grid>
      <Grid item>
        <Grid item>
          <Grid container justifyContent={"space-around"} alignItems={"center"}>
            <Grid item md={6}>
              <Paper className={classes.list}>
                <List>
                  <ListItem>
                    <ListItemText>Chest Pain/Cardiac Care</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Obstetric/Gynecological Care</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Occupational Medicine</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Orthopedic Care (Bones)</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Pediatric Care (Children)</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Trauma Care</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Triage (Prioritizing Patients)</ListItemText>
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            <Grid item md={4}>
              <p>
                We have physicians in house and an emergency department staffed
                with Board Certified Emergency Room RN’s 24 hours a day, seven
                days a week, treating over 4,000 patients per year. If for some
                reason, our patients need to go to a different facility, we have
                ready access to Air Ambulance helicopters and ground ambulance
                transport.
              </p>
              <div>
                <h3>Links</h3>
                <a
                  href={
                    "https://www.stlukesonline.org/health-services/service-groups/air-st-lukes"
                  }
                  target={"_blank"}
                >
                  Air St. Luke's
                </a>
                <br />
                <a
                  href={"https://www.lifeflight.org/membership/"}
                  target={"_blank"}
                >
                  Life Flight
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
