import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  makeStyles,
  ListItemText,
  Paper,
} from "@material-ui/core";
import { Phone } from "@material-ui/icons";
import React from "react";
import ContactCard from "../../../components/page-components/contact-components/contact-card/contact-card";
import WaveTitle from "../../../components/wave-title/wave-title";
import FMCImage from "../../../components/page-components/landing-page-components/card-section/img-card/FMC-img-card/FMC-img";
import FMCLogo from "../../../../assets/imgs/logos/wmh/FMC_logo.svg";

const useStyles = makeStyles((theme) => ({
  FMCGridRoot: {
    padding: "0.5rem",
    "& li": {
      padding: "0 10px",
    },
  },
  FMCImageRoot: {
    background: theme.palette.primary.main,
  },
}));

export default function FMCComponent(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      justifyContent={"center"}
      align={"center"}
      wrap={"wrap-reverse"}
      className={classes.FMCGridRoot}
    >
      <Grid item md={6}>
        <div className={classes.FMCImageRoot}>
          <FMCImage noAnimate />
        </div>
        <Divider style={{ height: 5, margin: "1rem" }} />
        <div style={{ width: "100%", textAlign: "center" }}>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <a href={"tel:+12085494424"}>
              <Button color={"secondary"} variant={"contained"}>
                <Phone style={{ margin: 0 }} />
                Call Now
              </Button>
            </a>
            <a
              href="http://www.familymedicalweiser.org/"
              style={{ textDecoration: "none", color: "white" }}
              target={"_blank"}
            >
              <Button color={"primary"} variant={"contained"}>
                familymedicalweiser.org
              </Button>
            </a>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ContactCard
              address={
                <>
                  <p>360 E Liberty Street</p>
                  <p>Weiser, ID 83672</p>
                </>
              }
              href={"360 E Liberty Street Weiser, ID 83672"}
              phone={"208-549-4424"}
            />
          </div>
        </div>
      </Grid>
      <Grid item md={6}>
        <WaveTitle title={"Family Medical Center"} mask={"gentle-wave"} />
        <img src={FMCLogo} alt={"FMC logo"} style={{ maxWidth: "100%" }} />
        <p>
          We offer personalized family care with the convenience of a medical
          lab, x-ray, ultra-sound, and CT scanning less than a block away. Our
          same-day appointments and extended clinic hours assure that you and
          your family can access quality care whenever you need it.
        </p>
        <Paper style={{ marginBottom: 10 }}>
          <List>
            <Grid container justifyContent={"space-around"}>
              <Grid item xl={6}>
                <ListItem>
                  <ListItemText>Same Day Appointments</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Extended Clinic Hours</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Friendly and Comforting Staff</ListItemText>
                </ListItem>
              </Grid>
              <Grid item xl={6}>
                <ListItem>
                  <ListItemText>Excellent Medical Doctors</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Care for the Entire Family</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Welcoming New Patients</ListItemText>
                </ListItem>
              </Grid>
            </Grid>
          </List>
        </Paper>
        <Button color={"secondary"} variant={"contained"}>
          <a
            href="http://www.familymedicalweiser.org/"
            style={{ textDecoration: "none", color: "initial" }}
            target={"_blank"}
          >
            Learn More
          </a>
        </Button>
      </Grid>
    </Grid>
  );
}
