import React, { useEffect } from "react";
import steth from "../../../assets/imgs/backgrounds/services-header-stethiscopes-crop.jpg";
import FMCComponent from "../../components/page-components/services/FMC-component";
import SSClinicComponent from "../../components/page-components/services/SSClinic-component";
import EmergencyServices from "../../components/page-components/services/emergency-services";
import OtherServicesComponent from "../../components/page-components/services/other-services/other-services-component";
import Page from "../page";
import Helmet from "react-helmet";
import SwingBedComponent from "../../components/page-components/services/swing-bed-component";

export default function Careers() {
  const pageSections = [
    {
      title: "Surgical & Specialty Clinic",
      linkTo: "surgical-specialty-clinic",
      content: <SSClinicComponent />,
    },
    {
      title: "Emergency Services",
      linkTo: "emergency-services",
      content: <EmergencyServices />,
    },
    {
      title: "Transitional Care",
      linkTo: "swing-bed",
      content: <SwingBedComponent />,
    },
    {
      title: "Family Medical Center",
      linkTo: "family-medical-center",
      content: <FMCComponent />,
    },
    {
      title: "Other Services",
      linkTo: "other-services",
      content: <OtherServicesComponent />,
    },
  ];

  useEffect(() => {
    document.title = "Services";
  }, []);

  return (
    <>
      <Helmet>
        <title>Services</title>
        <meta
          name="description"
          content="When you turn to Weiser Memorial Hospital you're turning to some of the most skilled and caring professionals in their fields. ER Services, Surgical Services, Imaging."
        />
        <link
          rel="canonical"
          href="http://weisermemorialhospital.org/services"
        />
      </Helmet>
      <Page
        background={steth}
        title={"Services"}
        sections={pageSections}
        svgID={3}
        noHeaders
        centered
        imgStyles={{
          marginTop: "20rem",
        }}
      />
    </>
  );
}
