import React from "react";
import { Button } from "@material-ui/core";
import { ArrowRight, ErrorOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";

import "../../../../../css/menu/covid-banner.scss";

export default function COVIDBanner() {
  return (
    <div className={"covid-banner"}>
      <Button variant={"text"} component={Link} to={"/covid"}>
        <ErrorOutline style={{ color: "aliceblue" }} />
        <h2>COVID-19 - Testing and Vaccination Information</h2>
        <ArrowRight style={{ color: "aliceblue" }} />
      </Button>
    </div>
  );
}
