import {
  AnkarbergImg,
  BlanchardImg,
  RyanImg,
  SmithImg,
  TerryImg,
  HathhornImg,
  MartinImg,
  GallegosImg,
  AliImg,
} from "../../doctor-images/doctor-images";
import React from "react";
export const FAMILY_PRACTICE_PROVIDER = "Family Practice Provider";
export const NURSE_PRACTITIONER = "Nurse Practitioner";
export const BEHAVIORAL_HEALTH = "Behavioral Health";

const profiles = [
  {
    name: "Jordan Blanchard, M.D.",
    img: <BlanchardImg />,
    quote:
      "I have always wanted to be a rural family practice doctor, and my wife and I are excited to be here in Weiser. I look forward to this opportunity to really get to know my patients and to be able to follow them through a lifetime of care. In addition to family care, my medical interests include women’s health, OB, prenatal, pediatrics, seniors, and urgent care. If you have a medical concern, or if it’s just been too long since you have had a checkup, please come see me at Family Medical Center.",
    education: [
      "Brigham Young University – Idaho B.S. Biology  2007",
      "University of Utah School of Medicine, Salt Lake City, Utah  2011  M.D.",
    ],
    residency: "Utah Valley Family Medicine Residency, Provo, Utah 2014",
    certifications: [
      "Basic Life Support (BLS)",
      "DOT (Dept. Of Transportation) Examiner Certification",
      "Advanced Cardiovascular Life Support (ACLS)",
      "Advanced Life Support in Obstetrics (ALSO)",
      "Pediatric Advanced Life Support (PALS)",
      "Advanced Trauma Life Support (NRP)",
      "Neonatal Resuscitation Program (NPR)",
      "Advanced Wilderness Life Support (ATLS)",
    ],
    category: FAMILY_PRACTICE_PROVIDER,
  },
  {
    name: "Brad Terry, D.O.",
    img: <TerryImg />,
    quote:
      "I feel honored to be part of a great team and have the opportunity to help so many amazing people. If you have a medical concern, or if it’s just been too long since you have had a checkup, please come see me at Family Medical Center.",
    education: [
      "Northern Virginia Community College A.S. General Studies",
      "George Mason University B.A. History",
      "Lincoln Memorial University",
      "DeBusk College of Osteopathic Medicine",
    ],
    residency: "Utah Valley Family Medicine Residency, Provo, Utah",
    certifications: [
      "Basic Life Support (BLS)",
      "Advanced Cardiovascular Life Support (ACLS)",
      "Advanced Life Support in Obstetrics (ALSO)",
      "Pediatric Advanced Life Support (PALS)",
      "Advanced Trauma Life Support (NRP)",
      "Neonatal Resuscitation Program (NPR)",
      "Advanced Wilderness Life Support (ATLS)",
    ],
    category: FAMILY_PRACTICE_PROVIDER,
  },
  {
    name: "Ryan Ankarberg, PA-C",
    img: <RyanImg />,
    category: FAMILY_PRACTICE_PROVIDER,
  },
  {
    name: "Selena Ankarberg, FNP",
    img: <AnkarbergImg />,
    className: "np",
    category: NURSE_PRACTITIONER,
  },
  {
    name: "Sara Martin, FNP",
    img: <MartinImg />,
    className: "np",
    category: NURSE_PRACTITIONER,
  },
  {
    name: "Melissa Smith, MD",
    img: <SmithImg />,
    className: "behavioral",
    category: BEHAVIORAL_HEALTH,
  },
  {
    name: "Melissa Hathhorn, PMHNP-BC",
    img: <HathhornImg />,
    className: "behavioral",
    category: BEHAVIORAL_HEALTH,
  },
  {
    name: "Stephanie Gallegos, LMSW",
    img: <GallegosImg />,
    className: "behavioral",
    category: BEHAVIORAL_HEALTH,
  },
  {
    name: "Ali Capurro, LPC",
    img: <AliImg />,
    className: "behavioral",
    category: BEHAVIORAL_HEALTH,
  },
];

export default profiles;
