import {
  AppBar,
  makeStyles,
  Tabs,
  Tab,
  Button,
  Grid,
  Container,
} from "@material-ui/core";
import React, { useState } from "react";
import giving from "../../../../../assets/imgs/backgrounds/giftpresentyellowbow-crop-u6073.jpg";
import foundation from "../../../../../assets/imgs/logos/foundation.jpg";
import BoardMembers from "./board-members/board-member";
import { HashLink } from "react-router-hash-link";
import Scholarship from "../../foundation/scholarship/scholarship";

const useStyles = makeStyles((theme) => ({
  tabsTitle: {
    margin: "1rem auto",
    "@media(max-width: 767px)": {
      marginLeft: 10,
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function FoundationSection() {
  const [tab, setTab] = useState(0);
  const classes = useStyles();

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <Container maxWidth={"xl"} style={{ margin: "2rem auto" }}>
      <AppBar position={"static"}>
        <h2 className={classes.tabsTitle}>
          Weiser Memorial Hospital Foundation
        </h2>
        <Tabs
          value={tab}
          onChange={(e, v) => setTab(v)}
          aria-label={"the Weiser Memorial Hospital Foundation"}
          centered={window.innerWidth <= 767 ? false : true}
          variant={window.innerWidth <= 767 ? "scrollable" : null}
          scrollButtons={"auto"}
        >
          <Tab label={"The Foundation"} {...a11yProps(0)} />
          <Tab label={"Ways Of Giving"} {...a11yProps(1)} />
          <Tab label={"Board Of Directors"} {...a11yProps(2)} />
          <Tab label={"Healthcare Scholarship"} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel
        value={tab}
        index={0}
        style={{ display: "flex", flexFlow: "column" }}
      >
        <Container maxWidth={"md"} className={classes.panel}>
          <h2 className={"primary-title"}>
            Weiser Memorial Hospital Foundation
          </h2>
          <p>
            The Weiser Memorial Hospital Foundation was founded in 1988 as a
            citizen-driven effort to develop resources for Weiser Memorial
            Hospital. In 2000, the Foundation reorganized, incorporated and
            received 501(c)3 designation from the IRS. Since then the Foundation
            has secured more than $4 million through fundraising and grants to
            update the hospital’s medical equipment and building. The Foundation
            organizes and hosts one of the community’s largest events, the
            Festival of Trees. This long-standing, week long community event
            involves more than 300 volunteers and raises approximately $100,000
            annually.
          </p>
          <Button
            variant={"contained"}
            color={"primary"}
            component={HashLink}
            to={"/foundation#"}
          >
            Learn More
          </Button>
        </Container>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Container
          maxWidth={"md"}
          className={classes.panel}
          style={{ justifyContent: "space-between" }}
        >
          <Grid container alignItems={"center"} justifyContent={"center"} spacing={3}>
            <Grid item sm style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={giving}
                    alt={"giving hands"}
                    style={{
                      maxWidth: 100,
                      margin: 20,
                      borderTopLeftRadius: 20,
                    }}
                  />
                  <img
                    src={foundation}
                    alt={"Weiser Memorial Hospital Foundation"}
                    style={{ maxWidth: 100 }}
                  />
                </div>
                <h3>There are many ways to give to the foundation-</h3>
              </div>
              <hr />
              <p>
                Gifts can be arranged in a variety of ways. In all instances,
                donors may restrict gifts to specific purposes.
              </p>
              <Button
                variant={"contained"}
                color={"primary"}
                component={HashLink}
                to={"/foundation#ways-of-giving"}
              >
                Ways To Give
              </Button>
            </Grid>
          </Grid>
        </Container>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <BoardMembers />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <Scholarship smallImg />
      </TabPanel>
    </Container>
  );
}
