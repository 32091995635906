import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Paper,
} from "@material-ui/core";
import React from "react";
import { ExpandMoreRounded } from "@material-ui/icons";
import ContactCard from "../../page-components/contact-components/contact-card/contact-card";
import WaveTitle from "../../wave-title/wave-title";
import swingBed1 from "../../../../assets/imgs/portrait/swingbed1.jpg";
import waitingRoom from "../../../../assets/imgs/portrait/waiting-room.jpg";
import nurses from "../../../../assets/imgs/portrait/oldercouple-laptop.jpg";
import ResourceList from "../../resources/resourceList";

const useStyles = makeStyles((theme) => ({
  calendar: {
    background: theme.palette.primary.main,
    "& li span": {
      fontSize: "0.75rem",
    },
    "& b": {
      fontSize: "1rem",
    },
  },
  oddSchedule: {
    background: theme.palette.secondary.main,
  },
  staffChip: {
    background: theme.palette.secondary.main,
  },
  procedureChip: {
    padding: 5,
    background: theme.palette.primary.main,
    color: "white",
  },
  procedureSection: {
    background: theme.palette.primary.main,
    padding: 10,
  },
}));

export default function SwingBedComponent(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      justifyContent={"center"}
      align={"center"}
      className={classes.FMCGridRoot}
    >
      <Grid item md={6}>
        <WaveTitle
          title={"Transitional Care (Swing Bed)"}
          mask={"smooth-arch"}
        />
        <h2 className={"primary-title"}>
          Transitional Care is Recovery and Rehabilitation for patients
          recovering from an acute illness or surgery.
        </h2>
        <p>
          At Weiser Memorial Hospital, we understand there are times when a
          patient no longer needs the acute medical care they have received in
          our, or other hospitals, and they may not feel strong enough to return
          home. For those patients we offer a unique and comforting alternative
          to a nursing home.
        </p>
        <img
          style={{ maxWidth: "90%" }}
          src={waitingRoom}
          alt={"Swingbed waiting room"}
        />
        <p className={"primary-title"}>HOW IT WORKS</p>
        <Paper className={"dark-content"}>
          <List>
            <ListItem>
              <ListItemText>
                1. Your case manager or doctor contacts us.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. We get preauthorization from Medicare.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. We work closely with your discharging hospital to ensure
                a safe transition to our Transitional Care Program.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                4. You recover close to home and community.
              </ListItemText>
            </ListItem>
          </List>
        </Paper>
      </Grid>
      <Grid item md={6}>
        <img
          style={{ maxWidth: "100%" }}
          src={nurses}
          alt={"swing bed recovery 1"}
        />
        <Divider style={{ height: 5, margin: "1rem" }} />
        <h2 className={"primary-title"}>We’re ready to help with recovery.</h2>
        <p className={"primary-title"}>PROVIDING:</p>
        <List>
          <ListItem>
            <ListItemText>
              <b>A personalized plan of care.</b>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText>
              <b>
                Bedside Rounds that engage you, your family, and your care team
                to help you reach your goals.
              </b>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText>
              <b>
                Hospital level nurse staffing to keep you safe and meet your
                needs.
              </b>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText>
              <b>
                Promotes a home-like environment accommodating family and
                individualized activity programs, as well as therapy in several
                environments.
              </b>
            </ListItemText>
          </ListItem>
          <Divider />
          <Divider />
          <ListItem>
            <ListItemText>
              <b>
                Our on-site physician, therapy, radiology, laboratory, and
                pharmacy teams will address all your medical needs.
              </b>
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
      <Grid item md={12}>
        <h2 className={"primary-title"}>Our Care Team Is Here For You</h2>
        <p>
          Our Transitional Care Program centers on teamwork, communication, and
          collaboration. We work with you and your loved ones, to create a
          personalized plan and support your goals of recovery. We will also
          meet with you on a regular basis to celebrate successes and adjust
          your plan, as needed.
        </p>
        <img style={{ maxWidth: "70%" }} src={swingBed1} alt={"Swingbed"} />
        <h2 className={"primary-title"}>Care and Recovery We Provide</h2>
        <List style={{ maxWidth: 400, background: "aliceblue" }}>
          <ListItem>
            <ListItemText>
              <b>
                - Recovery After Complex Surgery- Cardiac, orthopedic, abdominal
                and more.
              </b>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText>
              <b>- Respiratory Needs- Specialized treatments and support.</b>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText>
              <b>- Wound Care- Special attention for wound healing.</b>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText>
              <b>
                - Intravenous (I.V.) Antibiotics- To treat a variety of
                infection.
              </b>
            </ListItemText>
          </ListItem>
          <Divider />
          <Divider />
          <ListItem>
            <ListItemText>
              <b>
                - Specialized Therapy- Including physical, occupational and
                array of supportive services.
              </b>
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
      <Grid item md={12}>
        <h2 className={"primary-title"}>Commonly Asked Questions</h2>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreRounded />}
            aria-controls={"patient-stay"}
            id="patient-stay-content"
          >
            <Typography>
              <b>How long do patients typically stay in Transitional Care?</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Most stays in Transitional Care are a few days to a few weeks,
              however, some patients may stay for up to 100 days if they have
              daily qualifying skilled care needs. The majority of patients in
              our program improve their health and rehabilitation status during
              their stay, and the majority of program patients who lived at home
              prior to their hospitalization are discharged back home after
              Transitional Care.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreRounded />}
            aria-controls={"involved-care"}
            id="involved-care-content"
          >
            <Typography>
              <b>Who is involved in your care?</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <ListItemText>
                  <b>- PHYSICIANS</b>
                  <br />
                  Physicians care for each patient and are available 24 hours a
                  day. These physicians may also request consultations with
                  other specialists during your stay.
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText>
                  <b>- NURSES</b>
                  <br />
                  Qualified nurses provide daily, individualized care to each
                  patient. A Registered Nurse case manager also coordinates with
                  the referring healthcare facility to ensure that you have a
                  smooth transition into the program. In addition, your case
                  manager works with your insurance company to determine
                  eligibility and coverage for benefits.
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText>
                  <b>- ACTIVITIES DIRECTOR</b>
                  <br />
                  Our Activity Director completes a comprehensive evaluation of
                  our patient’s activity preferences, including religious
                  activities, when they transition into swing bed. The clinical
                  team assists in creating therapeutic activities that will keep
                  patients physically and mentally active. Our goal is helping
                  you return to your normal activities in preparation for your
                  return home.
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText>
                  <b>- DIETICIAN</b>
                  <br />
                  Our licensed dietician can complete a dietary evaluation to
                  determine your nutritional needs, track your progress make
                  dietary recommendations, and provide nutritional education.
                  Our chef knows that meals are an important part of the
                  rehabilitation process. That is why our patients get homemade
                  food items, hot from the oven or grille, served with a variety
                  of fresh fruits and vegetables.
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText>
                  <b>- PHARMACISTS</b>
                  <br />
                  Our clinically trained pharmacists are available 24/7 to
                  advise physicians on the dosages, interactions, and side
                  effects of medications. They can also answer patient questions
                  about prescription drugs.
                </ListItemText>
              </ListItem>
              {/* <Divider />
              <ListItem>
                <ListItemText>
                  <b>- SOCIAL WORKER</b>
                  <br />
                  Our social worker provides psychological support, care
                  coordination, and discharge planning. She interviews each new
                  patient to obtain information that helps the clinical team
                  create a personalized plan of care. They all work together to
                  ensure that the appropriate health care and social service
                  support and resources have been identified and coordinated for
                  you upon discharge.
                </ListItemText>
              </ListItem> */}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreRounded />}
            aria-controls={"insurance-coverage"}
            id="insurance-coverage-content"
          >
            <b>Is Transitional Care covered by my insurance plan?</b>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Transitional Care is predominantly covered by the Medicare “Swing
              Bed” benefit. Some other insurance providers may cover this care
              as well. If you are having a planned hospitalization and think you
              might need care after your stay, we can check if Transitional Care
              would be covered so you can plan ahead of time to come to our
              programs.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreRounded />}
            aria-controls={"same-as"}
            id="same-as-content"
          >
            <b>Is “Transitional Care” the same as “Swing Bed”?</b>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Our program is called Transitional Care because it is a model
              focused on helping patients transition from a hospital stay to
              their highest level of independence at home or in another setting.
              We use hospital-level resources, team processes, best practices,
              and extra clinical education to support this “transition”. Since
              most patients receive this care under Medicare, this level of care
              is sometimes also referred to as “Swing Bed.”
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreRounded />}
            aria-controls={"nursing-home"}
            id="nursing-home-content"
          >
            <b>
              How is Transitional Care different from the care received at a
              Skilled Nursing Facility or nursing home?
            </b>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Because we are a hospital, we can deliver Transitional Care with
              high levels of safety, quality, and flexibility with
              hospital-based resources including on-site lab, radiology, and
              immediate access to physicians and other caregivers. Our
              hospital-based Transitional Care program provides up to 2 – 3
              times more available nurse hours per patient day compared to most
              skilled nursing facilities. We hold Bedside Rounds with patient,
              family, and care team together on a scheduled basis, so everyone
              understands your plan of care, identifies things that need to be
              addressed, and plans for a safe discharge. <br />
              Helping you get back to your active life.
            </p>
          </AccordionDetails>
        </Accordion>
        <h2 className={"primary-title"}>Resources</h2>
        <ResourceList />
      </Grid>{" "}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexFlow: "column",
        }}
      >
        <ContactCard
          address={
            <>
              <p>645 East 5th Street</p>
              <p>Weiser, ID 83672</p>
            </>
          }
          href={"645 East 5th Street Weiser, ID 83672"}
          phone={"208-549-3152"}
          fax={"208-549-4422"}
        />
      </div>
    </Grid>
  );
}
