import React, { useEffect } from "react";
import patientRightsBackground from "../../../assets/imgs/backgrounds/lookout-2000x1054.jpg";
import PatientInformation from "../../components/page-components/information/patient-information/patient-information";
import VisitorInformation from "../../components/page-components/information/visitor-information/visitor-information";
import PayABill from "../../components/page-components/information/patient-information/information-components/billing/billing";
import CostEstimate from "../../components/page-components/information/patient-information/information-components/cost-estimate/cost-estimate";
import Page from "../page";
import Helmet from "react-helmet";

export default function Careers() {
  const pageSections = [
    {
      title: "Patient Information",
      linkTo: "patient-information",
      content: <PatientInformation />,
    },
    {
      title: "Visitor Information",
      linkTo: "visitor-information",
      content: <VisitorInformation />,
    },
    {
      title: "Cost Estimate",
      linkTo: "costestimator",
      content: <CostEstimate />,
    },
    {
      title: "Billing",
      linkTo: "billing",
      content: <PayABill />,
    },
  ];

  useEffect(() => {
    document.title = "Information";
    document.description = "";
  }, []);

  return (
    <>
      <Helmet>
        <title>Patient/Visitor Information</title>
        <meta
          name="description"
          content="Weiser Memorial Hospital employees and health care providers will show you respect, maintain your dignity and make you feel as comfortable as possible while you are receiving care from us."
        />
      </Helmet>
      <Page
        background={patientRightsBackground}
        title={"Information"}
        sections={pageSections}
        svgID={3}
        noHeaders
        imgStyles={{
          marginTop: "20rem",
        }}
      />
    </>
  );
}
