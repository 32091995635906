import { Button, Grow } from "@material-ui/core";
import React, { useState } from "react";
import Iframe from "react-iframe";

export default function CostEstimate(props) {
  const [showEstimator, setShowEstimator] = useState(false);

  return (
    <div id="costestimator">
      <h2 className={"primary-title"}>Cost Estimator</h2>
      <h3>
        <i>
          Price transparency helps our patients discover the necessary costs{" "}
          before having a procedure.
        </i>
      </h3>
      <Button
        variant={"contained"}
        onClick={() => setShowEstimator(!showEstimator)}
        color={"primary"}
      >
        Start Estimate
      </Button>
      <Grow in={showEstimator} unmountOnExit>
      <div
        style={{ display: "flex", justifyContent: "center", overflow: "auto" }}
      >
        <Iframe
          url="https://rcm.trubridge.com/remitreppcePortal/default.aspx?Guid=906af5ad496c413294e853cb8df3ae87 "
          width="530px"
          height="850px"
          allowFullScreen
        />
      </div>
      </Grow>
    </div>
  );
}
