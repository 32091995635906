import React, { useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Paper,
} from "@material-ui/core";
import { Phone } from "@material-ui/icons";

export default function ContactSelector(props) {
  const [selected, setSelected] = useState(0);

  const PhoneLink = (props) => {
    return (
      <a
        href={`tel:+1-208${props.number.replace("-", "")}`}
        style={{ color: "initial", margin: 5.7 }}
      >
        <Button variant={"contained"} color={"secondary"}>
          (208) {props.number}
        </Button>
      </a>
    );
  };

  return (
    <Paper
      elevation={0}
      style={{
        display: "flex",
        alignItems: "baseline",
        flexWrap: "wrap",
        justifyContent: "center",
        padding: 10,
        margin: 5,
        marginTop: 10,
      }}
    >
      {!props.noIcon && <Phone />}
      <FormControl style={{ display: "flex", alignItems: "center" }}>
        <div>
        {!props.noLabel && (
          <InputLabel id="locations">
            {props.title ? props.title : "Contact"}
          </InputLabel>
        )}
        <Select
          labelId="locations"
          label={"Contact"}
          id="locations"
          aria-label={"Select a contact location"}
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
        >
          {props.contacts &&
            Array.isArray(props.contacts) &&
            props.contacts.map((c, i) => (
              <MenuItem aria-label={c.label} value={i} key={i}>
                {c.label}
              </MenuItem>
            ))}
        </Select>
      <p style={{fontSize: "0.8rem", marginTop: 0}}><b>*select a contact</b></p>
      </div>
      </FormControl>
      <div style={{ margin: 10 }}>
        {props.contacts &&
          Array.isArray(props.contacts) &&
          props.contacts
            .filter((c, i) => i === selected)
            .map((c) => <PhoneLink number={c.number} key={c.number} />)}
      </div>
    </Paper>
  );
}
