import { Container, makeStyles } from "@material-ui/core";
import React from "react";
import Overlay from "../../../../../components/overlay/overlay";
import evening from "../../../../../../assets/imgs/backgrounds/evening_ride.jpg";
import MembersImgs from "./members";

const useStyles = makeStyles((theme) => ({
  sectionRoot: {
    width: "100vw",
    height: "100%",
    background: `url(${evening}) ${theme.palette.secondary.main}`,
    backgroundAttachment: "fixed",
    backgroundPosition: "50%",
    margin: "8rem auto",
    paddingTop: "4rem",
    paddingBottom: "4rem",
  },
}));

export default function MembersCardsSection() {
  const year = new Date().getFullYear();
  const classes = useStyles();

  return (
    <div className={classes.sectionRoot}>
      <Overlay color={"rgba(23,23,23,0.25)"}>
        <Container maxWidth={"lg"} style={{ textAlign: "center" }}>
          <h1 style={{ color: "white" }}>
            We are thankful to all our community volunteers who support Weiser
            Memorial Hospital and the community we serve.
          </h1>
        </Container>
        <hr style={{ width: "75%", marginBottom: "2rem" }} />
        <div
          style={{ display: "flex", flexFlow: "column", alignItems: "center" }}
        >
          <h2 style={{ color: "white" }}>
            {`${year - 1} - ${year}`} Hospital Board
          </h2>
          <MembersImgs />
        </div>
      </Overlay>
    </div>
  );
}
