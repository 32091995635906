import React from "react";
import { Container, makeStyles } from "@material-ui/core";

export default function PageHeader(props) {
  const useStyles = makeStyles((theme) => ({
    headerRoot: {
      height: !props.background?"30vh":"80vh",
      "& img": {
        width: "100%",
        "@media(max-width: 767px)": {
          width: "200%",
        },
      },
    },
    contentRoot: {
      background: theme.palette.primary.main,
      color: "white",
      "& h2": {
        margin: 0,
        marginLeft: 12,
      },
    },
    mask: {
      "& svg": {
        bottom: -25,
        "@media(max-width: 767px)": {
          bottom: -1,
        },
      },
    },
  }));

  const classes = useStyles();

  return (
    <Container
      maxWidth={"xl"}
      className={classes.headerRoot}
      style={{
        paddingTop: "4rem",
        marginBottom: "1rem",
        position: "relative",
        overflow: "hidden",
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      disableGutters
    >
      <Container
        style={{
          display: "flex",
          flexFlow: "column",
          position: "absolute",
          top: 94,
        }}
        className={classes.contentRoot}
        maxWidth={false}
      >
        <div style={{ top: "1.8rem", paddingTop: 10 }}>
          <h2>{props.title ? props.title : ""}</h2>
        </div>
      </Container>
      {props.background && (
        <img
          src={props.background || ""}
          alt={"background"}
          style={{
            height: "auto",
            ...props.imgStyles,
          }}
        />
      )}
    </Container>
  );
}
