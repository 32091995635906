import React, { useEffect } from "react";
import phone from "../../../assets/imgs/backgrounds/tele.jpg";
import ContactInfo from "../../components/page-components/contact-components/contact-info/contact-info";
import PhysicianDirectory from "../../components/page-components/contact-components/physician-directory/physician-directory";
import Page from "../page";
import Helmet from "react-helmet";

export default function Contact() {
  const pageSections = [
    {
      title: "Contact Information",
      linkTo: "contact-information",
      content: <ContactInfo />,
    },
    {
      title: "Physician Directory",
      linkTo: "physician-directory",
      content: <PhysicianDirectory />,
    },
  ];

  useEffect(() => {
    document.title = "Contact Us";
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="Weiser Memorial Hospital is always here to answer questions and take concerns."
        />
      </Helmet>
      <Page
        background={phone}
        title={"Contact Us"}
        sections={pageSections}
        svgID={1}
        noHeaders
        centered
      />
    </>
  );
}
