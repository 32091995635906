import {
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import JobOpenings from "../job-openings/job-openings";

const useStyles = makeStyles((theme) => ({
  list: {
    background: theme.palette.primary.main,
    color: "white",
    "& li": {
      padding: "0 8px",
    },
  },
  listTwo: {
    background: theme.palette.secondary.main,
    padding: 10,
  },
}));

export default function EmploymentCriteria(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      alignItems={"center"}
      justifyContent={"center"}
      wrap={"wrap-reverse"}
    >
      {/* Employment Criteria */}
      <Grid item md={6} lg={6}>
        <div>
          <h2>Employment Criteria</h2>
          <div>
            <List className={classes.list}>
              <ListItem>
                <ListItemText>Must apply for a posted position</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Must follow our written application procedures (see Employment
                  Notice below)
                </ListItemText>
                {/* todo link to Employment notice */}
              </ListItem>
              <ListItem>
                <ListItemText>
                  Must meet minimum qualifications indicated on the job posting
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Must not have withdrawn from consideration
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Applications will be accepted only when there is an open
                  and/or posted position
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Separate application for each job applied for
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Qualified candidates will be formally contacted for an
                  interview
                </ListItemText>
              </ListItem>
            </List>
          </div>
        </div>
      </Grid>
      <Grid item md={6} lg={6}>
        <h2>
          Weiser Memorial Hospital offers a great place to work, competitive
          salaries, and <a href={"#benefits-summary"}>excellent benefits</a>.
        </h2>
        <p>
          If you have the desire to help our organization to deliver high
          quality healthcare with a personal touch, please consider joining our
          team of Extraordinary People
        </p>
        <Paper className={classes.listTwo}>
          <p>
            <i>
              *As a Registered Nurse employed at Weiser Memorial Hospital you
              may qualify for the Federal Government Nursing Education Loan
              Repayment Program. In exchange for two years of service,
              participants receive 60 percent of their total qualifying nursing
              education loan balance. For more information on eligibility and
              requirements please visit the following website:{" "}
              <a href="https://www.govloans.gov/loans/nursing-education-loan-repayment-program/" target={"_blank"}>
                govloans.gov/loans/nursing-education-loan-repayment-program/
              </a>
            </i>
          </p>
        </Paper>
      </Grid>{" "}
      <Grid item md={12}>
        <JobOpenings />
      </Grid>
    </Grid>
  );
}
