import React from "react";
import { Grid } from "@material-ui/core";
import jax from "../../../../../assets/imgs/portrait/jaxson-110.jpg";
import LaborLogo from "../../../../../assets/imgs/logos/wmh/labor_delivery_logo.svg";

export default function LaborDelivery(props) {
  return (
    <>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item md={8}>
          <h2 className={"primary-title"}>Labor & Delivery</h2>
          <img
            src={LaborLogo}
            alt={"Labor & Delivery Logo"}
            style={{ maxWidth: "100%", marginTop: "1rem" }}
          />
          <h3>
            <i>Care For You and Your New Arrival...</i>
          </h3>
          <p>
            <b>
              Weiser Memorial Hospital provides delivery services that will
              insure a comfortable and safe delivery
            </b>
            . We make sure that your new arrival will have all the advantages of
            excellent care in your own local community.
          </p>
          <p>
            Our family-friendly birthing rooms are designed to give Mom and Baby
            a high level of comfort, with recliner chairs and large, sunny
            windows. Family and friends are welcome to visit in our spacious
            rooms.
          </p>
          <p>
            Our up to date technology and training will assure a safe
            delivery/C-Section here in your local hospital. Our nursing staff
            are certified in neonatal resuscitation, and regularly attend
            advanced training classes.
          </p>
          <p>
            Weiser Memorial Hospital hosts 40-50 births per year, so when
            planning the childbirth experience, visit and tour our facility
            first!
          </p>
        </Grid>
        <Grid item md={4}>
          <img src={jax} alt={"new born baby"} style={{ maxWidth: "100%" }} />
        </Grid>
      </Grid>
    </>
  );
}
