import React from "react";
import {
  Grid,
} from "@material-ui/core";

export default function NoticeToPatients(props) {

  return (
    <Grid
      container
      spacing={2}
      justifyContent={"center"}
      alignItems={"center"}
      style={{ textAlign: "center" }}
    >
      <Grid item md={6}>
        <h2>Health Services Financial Policy</h2>
        <p>
          Weiser Memorial Hospital and Family Medical Center <b>serves all patients
          regardless of inability to pay</b>. Discounts for essential services are
          offered depending upon family size and income. You may apply for a
          discount at the admissions desk.
        </p>
        <h2>NOTICE:</h2>
        <h3>
          WEISER MEMORIAL HOSPITAL AND FAMILY MEDICAL CENTER HAVE ADOPTED THE
          FOLLOWING POLICIES FOR CHARGES FOR HEALTH CARE SERVICES.
        </h3>
        <p>
          We will charge persons receiving health services at the usual and
          customary rate prevailing in this area. Health services will be
          provided at no charge, or at a reduced charge, to persons unable to
          pay for services. In addition, persons will be charged for services to
          the extent that payment will be made by a third party authorized or
          under legal obligation to pay the charges.
        </p>
        <p>
          We will charge persons receiving health services at the usual and
          customary rate prevailing in this area. Health services will be
          provided at no charge, or at a reduced charge, to persons unable to
          pay for services. In addition, persons will be charged for services to
          the extent that payment will be made by a third party authorized or
          under legal obligation to pay the charges.
        </p>
        <p>
          We will not discriminate against any person receiving health services
          because of their inability to pay for the services, or because payment
          for the health service will be made under <a href={"https://www.ssa.gov/OP_Home/ssact/title18/1800.htm"} rel={"nofollow noopen"} target={"_blank"}>Part A or B of Title XVIII
          (“Medicare”)</a> or <a href={"https://www.ssa.gov/OP_Home/ssact/title19/1900.htm"} rel={"nofollow noopen"} target={"_blank"}>Title XIX (“Medicaid”)</a> of the Social Security Act.
        </p>
        <p>
          We will accept assignment under the Social Security Act for all
          services for which payment may be made under Part B of Title XVIII
          (“Medicare”) of the Act.
        </p>
        <p>
          We have an agreement with the State agency which administers the State
          plan for medical assistance under Title XIX (Medicaid”) of the Social
          Security Act to provide services to persons entitled to medical
          assistance under the plan.
        </p>
      </Grid>
    </Grid>
  );
}
