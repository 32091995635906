import { Button, Divider, Grid, Paper } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { Mail, Person, Phone } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    width: "inherit",
  },
  address: {
    "& p": {
      margin: 0,
    },
  },
}));

export default function PayOptions(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent={"center"}
      spacing={3}
      className={"dark-content"}
      style={{ marginTop: "2rem", marginBottom: "2rem", padding: 0 }}
    >
      <Grid item sm={4} className={classes.gridItem}>
        <Paper style={{ padding: 5 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Person style={{ height: 50, width: 50 }} /> <h2>Pay in Person</h2>
          </div>
          <Divider style={{ height: 5, width: "100%" }} />
          <div className={classes.address}>
            <p style={{ marginTop: 0, marginBottom: 10 }}>
              Payments can be made at either <b>Weiser Memorial Hospital</b> or{" "}
              <b>Family Medical Center</b> in Weiser.
            </p>
            <Grid container spacing={2} justifyContent={"space-around"}>
              <Grid item md={7}>
                <a
                  href={
                    "http://maps.google.com/?q=650 State Street Weiser, ID 83672"
                  }
                  style={{ color: "blue" }}
                >
                  <p>Payment Drop Box</p>
                  <p>650 State Street</p>
                  <p>Weiser, ID 83672</p>
                </a>
              </Grid>
              <Grid item md={7}>
                <a
                  href={
                    "http://maps.google.com/?q=Weiser Memorial Hospital 645 East 5th Street Weiser, ID 83672"
                  }
                  style={{ color: "blue" }}
                >
                  <p>Weiser Memorial Hospital</p>
                  <p>645 East 5th Street</p>
                  <p>Weiser, ID 83672</p>
                </a>
              </Grid>
              <Grid item md={7}>
                <a
                  href={
                    "http://maps.google.com/?q=Family Medical Center 360 East Liberty Weiser, ID 83672"
                  }
                  style={{ color: "blue" }}
                >
                  <p>Family Medical Center</p>
                  <p>360 East Liberty</p>
                  <p>Weiser, ID 83672</p>
                </a>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
      <Grid item sm={4} className={classes.gridItem}>
        <Paper style={{ padding: 5 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Phone style={{ height: 50, width: 50 }} /> <h2>Pay by Phone</h2>
          </div>
          <Divider style={{ height: 5, width: "100%" }} />
          <div>
            <a
              href={"tel:+12085494408"}
              style={{
                margin: "10px auto",
                marginBottom: 0,
                textDecoration: "none",
              }}
            >
              <Button color={"secondary"} variant={"contained"}>
                Call (208) 549-4408
              </Button>
            </a>
            <p>
              Please have your billing statement with you at the time that you
              call.
            </p>
          </div>
        </Paper>
      </Grid>
      <Grid item sm={4} className={classes.gridItem}>
        <Paper style={{ padding: 5 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Mail style={{ height: 50, width: 50 }} /> <h2>Pay by Mail</h2>
          </div>
          <Divider style={{ height: 5, width: "100%" }} />
          <div className={classes.address}>
            <p style={{ marginTop: 0, marginBottom: 10 }}>
              <b>Payments can be mailed to:</b>
            </p>
            <a
              href={
                "http://maps.google.com/?q=Weiser Memorial Hospital 645 East 5th Street Weiser, ID 83672"
              }
              style={{ color: "blue" }}
            >
              <p>Weiser Memorial Hospital</p>
              <p>645 East 5th Street</p>
              <p>Weiser, ID 83672</p>
            </a>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
