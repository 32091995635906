import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, ClickAwayListener, Grow, IconButton } from "@material-ui/core";
import {
  ExitToApp,
  Info,
  Payment,
  Phone,
  RoomServiceRounded,
} from "@material-ui/icons";
import { HashLink } from "react-router-hash-link";

import Translate from "./translate/translate";

import "../../../../css/menu/menu.scss";

const linkList = [
  {
    // to: "/information#",
    title: "INFORMATION",
    component: "button",
    icon: <Info />,
    subItems: [
      {
        title: "Patient Information",
        to: "/information#patient-information",
      },
      {
        title: "Visitor Information",
        to: "/information#visitor-information",
      },
      {
        title: "Billing",
        to: "/information#billing",
      },
      {
        title: "Resources",
        to: "/resources",
      },
    ],
  },
  {
    // to: "/services#",
    title: "SERVICES",
    component: "button",
    icon: <RoomServiceRounded />,
    subItems: [
      {
        title: "Family Medical",
        to: "/services#family-medical-center",
      },
      {
        title: "Surgical Clinic",
        to: "/services#surgical-specialty-clinic",
      },
      {
        title: "Transitional Care",
        to: "/services#swing-bed",
      },
      {
        title: "Emergency Services",
        to: "/services#emergency-services",
      },
      {
        title: "Other Services",
        to: "/services#other-services",
      },
    ],
  },
  {
    component: "a",
    href: "https://mycarecorner.net/Default.aspx",
    title: "PATIENT PORTAL",
    icon: <ExitToApp />,
  },
  {
    component: "a",
    href:
      "https://zb.rpropayments.com/Login/CheckOutFormLogin/ZXtUJ5Nc-cu9Qzg-Uv2Uf2Wm-G4-",
    title: "PAY A BILL",
    icon: <Payment />,
  },
];

// export default function MenuBarTop(props) {
//   const TopBarButton = ({ to, href, icon, title, subItems, component }) => {
//     const [openSub, setOpenSub] = useState([false, []]);
//     const setSubs = () => {
//       setOpenSub([true, subItems]);
//     };

//     const closeSubs = () => {
//       setOpenSub([false, []]);
//     };

//     return (
//       <ClickAwayListener onClickAway={closeSubs}>
//         <div className={"top-button-container"}>
//           <Button
//             variant={"text"}
//             className={"link-icon"}
//             component={component ? component : HashLink}
//             to={to ? to : "/#"}
//             href={href ? href : null}
//             target={href ? "__blank" : null}
//             onClick={setSubs}
//           >
//             <IconButton label={title}>{icon}</IconButton>
//             <span className={`button-title`}>{title}</span>
//           </Button>
//           <Grow in={openSub[0]}>
//             <div className={"submenu-container"}>
//               {Array.isArray(openSub[1]) &&
//                 openSub[1].map((s, i) => (
//                   <Button component={HashLink} to={s.to} onClick={closeSubs}>
//                     {s.title}
//                   </Button>
//                 ))}
//             </div>
//           </Grow>
//         </div>
//       </ClickAwayListener>
//     );
//   };

//   return (
//     <div className={"menu-top-bar"}>
//       <div style={{ marginLeft: 20 }} className={"translate"}>
//         <Translate />
//       </div>
//       <div className={"button-group"}>
//         {linkList.map((l, i) => (
//           <TopBarButton {...l} key={i} />
//         ))}
//         <IconButton component={HashLink} to={"contact"}>
//           <Phone />
//         </IconButton>
//         {/* <IconButton>
//           <MapSharp />
//         </IconButton> */}
//       </div>
//     </div>
//   );
// }

export default function MenuBarTop() {
  const TopBarButton = ({
    to,
    href,
    icon,
    title,
    subItems,
    component,
    ...rest
  }) => {
    const [openSub, setOpenSub] = useState([false, []]);
    const dispatch = useDispatch();

    const setSubs = () => {
      setOpenSub([true, subItems]);
    };

    const closeSubs = () => {
      setOpenSub([false, []]);
    };

    return (
      <ClickAwayListener onClickAway={closeSubs} {...rest}>
        <div className={"top-button-container"}>
          <Button
            variant={"text"}
            className={"link-icon"}
            component={component ? component : HashLink}
            to={to ? to : "/#"}
            href={href ? href : null}
            target={href ? "__blank" : null}
            onClick={setSubs}
            startIcon={icon}
          >
            {/* <Icon label={title}>{icon}</Icon> */}
            <span className={`button-title`}>{title}</span>
          </Button>
          <Grow in={openSub[0]}>
            <div className={"submenu-container"}>
              {Array.isArray(openSub[1]) &&
                openSub[1].map((s, i) => {
                  const component = s.func ? Button : HashLink;
                  const onClick = s.func ? () => dispatch(s.func) : closeSubs;

                  return (
                    <Button
                      component={component}
                      to={s.to}
                      onClick={onClick}
                      key={i}
                    >
                      {s.title}
                    </Button>
                  );
                })}
            </div>
          </Grow>
        </div>
      </ClickAwayListener>
    );
  };

  return (
    <div className={"menu-top-bar"}>
      <div style={{ marginLeft: 20 }} className={"translate"}>
        <Translate />
      </div>
      <div className={"button-group"}>
        {linkList.map((l, i) => (
          <TopBarButton {...l} key={i} />
        ))}
        <IconButton component={HashLink} to={"contact"}>
          <Phone />
        </IconButton>
      </div>
    </div>
  );
}
