import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Hidden,
  IconButton,
  Paper,
} from "@material-ui/core";
import { ExpandMore, Facebook } from "@material-ui/icons";
import React from "react";
import balanced from "../../../../../assets/imgs/backgrounds/balanced.jpg";
import ContactCard from "../../contact-components/contact-card/contact-card";
import ChildbirthClasses from "./childbirth-classes/childbirth-classes";
import CPRClasses from "./cpr-classes/cpr-classes";
import FitFallProof from "./fit-fall-proof/fit-fall-proof";

export default function CommunityHealth(props) {
  return (
    <div>
      <h2 className={"primary-title"}>Community Health</h2>
      <Grid container justifyContent={"center"} alignItems={"center"} spacing={2}>
        <Grid item sm={6}>
          <p>
            Weiser Memorial Hospital encourages our patients and community to be
            active in understanding their health. Through Community Health we
            work to identify the needs of our area and develop programs which
            make the healthy option the easy option. Weiser Memorial Hospital is
            involved in the community in many ways. Stay up to date on classes
            and events with our Facebook page
          </p>
          <p>
            We are proud to work with many other organizations in the area to
            offer a broad range of services to educate and make an impact on the
            health and wellness of our communities. We aim to provide
            accessible, exceptional, and sustainable options for the people we
            serve.
          </p>
          {/* <h4>
            <i>
              Visit our{" "}
              <HashLink to={"#calendar-of-events"}>CALENDAR OF EVENTS</HashLink>{" "}
              for current information:
            </i>
          </h4> */}
          <Paper className={"dark-content"} id={"programs"}>
            <h4>HOSPITAL SPONSORED PROGRAMS</h4>
            <ul>
              <li>Bountiful Baskets</li>
              <li>Car Seat Safety</li>
              <li>Childbirth Education</li>
              <li>CPR Classes</li>
              <li>
                Fit and Fall Proof Classes -{" "}
              </li>
            </ul>
          </Paper>

          <p>
            For schedules and other information regarding community education
            and outreach, please call Weiser Memorial Hospital’s Community
            Health Worker, Jenni Rodriguez at 208-549-0370 ext 4438.
          </p>
          <ContactCard
            label={
              <>
                <p>Jenni Rodriguez</p>
                <p>COMMUNITY HEALTH WORKER</p>
              </>
            }
            phone={"208-549-0370"}
          />
          <IconButton
            component={"a"}
            href={"https://www.facebook.com/WeiserMemorialHospital"}
            target={"_blank"}
          >
            <Facebook />
          </IconButton>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={6} style={{ textAlign: "center" }}>
            <img
              style={{ maxWidth: "100%", maxHeight: 500 }}
              src={balanced}
              alt={"balanced rocks"}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12}>
          <h2 className={"primary-title"}>CLASSES</h2>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="open-childbirth-classes"
              id="childbirth-classes"
            >
              CHILDBIRTH EDUCATION
            </AccordionSummary>
            <AccordionDetails>
              <ChildbirthClasses />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="open-cpr-classes"
              id="cpr-classes"
            >
              CPR CLASSES
            </AccordionSummary>
            <AccordionDetails>
              <CPRClasses />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="open-fit-fall-proof"
              id="fit-fall-proof"
            >
              FIT & FALL PROOF
            </AccordionSummary>
            <AccordionDetails>
              <FitFallProof />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
}
