import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import PatientRights from "./information-components/patient-rights";
import PatientSafety from "./information-components/patient-safety";
import FoodNutrition from "./information-components/food-nutrition/food-nutrition";
import NoticeToPatients from "./information-components/notice-to-patients";
import HIPAA from "../../../../../assets/imgs/pdf-imgs/HIPAA.png";
import cg from "../../../../../assets/imgs/pdf-imgs/non-discrimination.png";
import ContactCard from "../../contact-components/contact-card/contact-card";

export default function PatientInformation(props) {
  return (
    <div id={"patient-information"}>
      <h2>Patient Information</h2>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="open-patient-rights"
          id="patient-rights"
        >
          PATIENTS RIGHTS
        </AccordionSummary>
        <AccordionDetails>
          <PatientRights />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="open-patient-rights"
          id="patient-rights"
        >
          COMPLAINTS AND GRIEVANCES
        </AccordionSummary>
        <AccordionDetails
          style={{
            flexFlow: "column",
          }}
        >
          <a
            href={process.env.PUBLIC_URL + "/files/nondiscrimination.pdf"}
            target="_blank_"
          >
            <img
              src={cg}
              alt={"Complaints & Grievances"}
              style={{ maxWidth: "100%" }}
            />
          </a>
          <hr />
          <h3>
            DNV provides five channels for submitting a hospital complaint:
          </h3>
          <ContactCard
            label="DNV Healthcare USA Inc."
            phone="866-496-9647"
            fax="281-870-4818"
            address="Attn: Hospital Complaints
          4435 Aicholtz Road, Suite 900
          Cincinnati, OH 45245"
            href="https://www.google.com/maps/place/4435+Aicholtz+Rd+%23+900,+Cincinnati,+OH+45245/@39.0958891,-84.2855628,17z/data=!3m1!4b1!4m6!3m5!1s0x8841a9f64c5328ad:0xd9da68c8402b460e!8m2!3d39.0958891!4d-84.2833741!16s%2Fg%2F11nsw757mr"
            website="https://www.dnvhealthcareportal.com/patient-complaint-report"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={"forms-content"}
          id="forms"
        >
          FORMS
        </AccordionSummary>
        <AccordionDetails>
          <List
            subheader={
              <ListSubheader component={"div"}>Downloads</ListSubheader>
            }
          >
            <a
              href={
                process.env.PUBLIC_URL +
                "/files/downloads/forms/wmh_surgicalspecialtyclinic_patientregistration_fill-in.pdf"
              }
            >
              <ListItem>
                <ListItemText>Patient Registration Form</ListItemText>
              </ListItem>
            </a>
            <a
              href={
                process.env.PUBLIC_URL +
                "/files/downloads/forms/wmh_surgical-specialtyclinic_medicalhistory_form.pdf"
              }
            >
              <ListItem>
                <ListItemText>Medical History Form</ListItemText>
              </ListItem>
            </a>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="open-patient-safety"
          id="patient-safety"
        >
          PATIENT SAFETY
        </AccordionSummary>
        <AccordionDetails>
          <PatientSafety />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="open-patient-safety"
          id="patient-safety"
        >
          PRIVACY PRACTICES (HIPAA)
        </AccordionSummary>
        <AccordionDetails>
          <a
            href={process.env.PUBLIC_URL + "/files/HIPAA_Notice.pdf"}
            target="_blank_"
          >
            <img
              src={HIPAA}
              alt={"HIPAA NOTICE"}
              style={{ maxWidth: "100%" }}
            />
          </a>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="open-food-and-nutrition"
          id="food-and-nutrition"
        >
          FOOD & NUTRITION
        </AccordionSummary>
        <AccordionDetails>
          <FoodNutrition />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="open-financial-notice"
          id="financial-notice"
        >
          FINANCIAL NOTICE TO PATIENTS
        </AccordionSummary>
        <AccordionDetails>
          <NoticeToPatients />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
