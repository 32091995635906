import React, { useEffect } from "react";
import covidTitle from "../../../assets/imgs/backgrounds/covid-title.png";
import Page from "../page";
import { Grid, Card } from "@material-ui/core";
import Helmet from "react-helmet";
import COVIDComponent from "../../components/page-components/landing-page-components/COVID-COMPONENT/covid-component";
import ContactCard from "../../components/page-components/contact-components/contact-card/contact-card";

export default function COVIDPage() {
  const pageSections = [
    {
      title: "COVID Info",
      linkTo: "",
      content: (
        <>
          <div className={"covid-page-root"}>
            <h2 className={"primary-title"}>WHAT IS COVID-19?</h2>
            <p>
              COVID-19 is a respiratory condition caused by a coronavirus. Some
              people are infected but don’t notice any symptoms. Most people
              will have mild symptoms and get better on their own. But about 1
              in 6 will have severe problems, such as trouble breathing. The
              odds of more serious symptoms are higher if you’re older or have
              another health condition like diabetes or heart disease.
            </p>
          </div>
          <h3 className="text-primary">
            Where and when can you get a COVID-19 test?
          </h3>
          <Grid container spacing={2} style={{ background: "aliceblue" }}>
            <Grid item md={4}>
              <Card>
                <ContactCard
                  label="Weiser Memorial Hospital"
                  phone="208-549-0370"
                  address="645 5th Street, Weiser"
                  href="645 5th Street, Weiser"
                />
                <small>
                  <b>
                    <br /> **(MUST HAVE SYMPTOMS & REQ URGENT MEDICAL CARE)
                  </b>
                </small>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card>
                <ContactCard
                  label="Family Medical Center"
                  phone="208-549-4424"
                  address="360 East Liberty, Weiser"
                  href="360 East Liberty, Weiser"
                />
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card>
                <ContactCard
                  label="Two Rivers Medical Clinic"
                  phone="208-549-0211"
                />
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card>
                <ContactCard
                  label="St. Alphonsus/Fruitland"
                  phone="208-381-9500"
                />
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card>
                <ContactCard
                  label="St. Luke's Fruitland"
                  phone="208-452-8050"
                />
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card>
                <ContactCard label="Rite Aid/Ontario" phone="541-889-3390" />
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card>
                <ContactCard label="Walgreens/Ontario" phone="541-889-6288" />
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card>
                <ContactCard
                  label="Valley Family Health/Ontario"
                  phone="541-889-2340"
                />
              </Card>
            </Grid>
          </Grid>
          <COVIDComponent />
        </>
      ),
    },
  ];

  useEffect(() => {
    document.title = "Covid Information";
  }, []);

  return (
    <>
      <Helmet>
        <title>Covid</title>

        <meta
          name="description"
          content="Information on the COVID-19 virus as well as testing and vaccine info."
        />
      </Helmet>
      <Page
        background={covidTitle}
        title={"COVID"}
        sections={pageSections}
        noHeaders
      />
    </>
  );
}
