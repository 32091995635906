import { Grid } from "@material-ui/core";
import React from "react";
import elderlyAtComputer from "../../../../../../assets/imgs/portrait/oldercouple-laptop.jpg";

export default function WillTrust(props) {
  return (
    <Grid container spacing={3}>
      <Grid item md={12} style={{ textAlign: "center" }}>
        <img src={elderlyAtComputer} alt={"will or trust donations"} style={{maxWidth: '100%'}}/>
      </Grid>
      <Grid item md={12}>
        <h2 className={"primary-title"}>Will or Trust</h2>
        <p>
          Choosing to include the Weiser Memorial Hospital Foundation in your
          estate plans will leave a lasting legacy on patients and programs that
          need your support. Bequests to Sequoia can include cash, securities,
          real estate, other property, a percentage of the residue of your
          estate, or all the residue of your estate.
        </p>
      </Grid>
    </Grid>
  );
}
