const initState = {
  url: "https://github.com/wmhospital/",
  menu: {
    open: false,
  },
  pagePanel: "default",
  chatbotOpen: false,
  covidPopup: false,
  isIE: false,
  searchResults: [],
  COVIDQandA: null,
};

function rootReducer(state = initState, action) {
  switch (action.type) {
    case "TOGGLE_MENU":
      return Object.assign({}, state, {
        menu: {
          ...state.menu,
          open: !state.menu.open,
        },
      });
    case "SET_PANEL":
      return Object.assign({}, state, {
        pagePanel: action.payload,
      });
    case "SET_COVID_POPUP":
      return Object.assign({}, state, {
        covidPopup: action.payload,
      });
    case "SET_isIE":
      return Object.assign({}, state, {
        isIE: action.payload,
      });
    case "SET_CHATBOT_OPEN":
      return Object.assign({}, state, {
        chatbotOpen: action.payload,
      });
    case "SET_SEARCH_RESULTS":
      return Object.assign({}, state, {
        searchResults: action.payload
      })
    case "SET_COVID_Q_AND_A": 
      return Object.assign({}, state, {
        COVIDQandA: action.payload,
      })
    default:
      return state;
  }
}

export default rootReducer;
