import React, { useEffect, useState } from "react";

export default function Overlay(props) {
    const [color, setColor] = useState("transparent");

    useEffect(() => {
        if(props.color){
            setColor(props.color);
        }
    }, [props.color, ]);

    return (
        <div style={{
            minHeight: "100%",
            width: "100%",
            zIndex: 1,
            background: color,
        }}>
            <div style={{
                zIndex: 2,
            }}>
                {props.children}
            </div>
        </div>
    )
}