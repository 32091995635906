import { Button, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { Info } from "@material-ui/icons";

export default function BoardMeetings(props) {
  const meetings = require("./data/board-meetings.json");

  return (
    <div>
      <h2 style={{ textAlign: "center" }} className={"primary-title"}>
        Board Meetings
        <Button
          href={
            process.env.PUBLIC_URL + "/files/board-meetings/MEETING_NOTICE.pdf"
          }
          target="__blank"
        >
          <Info />
        </Button>
        <br />
        <br />
        <Button
          href={
            process.env.PUBLIC_URL +
            "/files/board-meetings/MEETING_SCHEDULE.pdf"
          }
          target="__blank"
        >
          Meeting Schedule
        </Button>
      </h2>
      <Grid
        container
        spacing={2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {meetings.map((m, i) => (
          <Grid item key={i}>
            <a
              href={`${process.env.PUBLIC_URL + "/files/board-meetings/"}${
                m.fileName
              }`}
              target={"_blank"}
            >
              <Button variant={"contained"} color={"primary"}>
                {m.title}
              </Button>
            </a>
          </Grid>
        ))}
        <Grid item>
          <Button type="link" component={Link} to="/archive">
            ...Meeting Archive
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
