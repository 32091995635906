import { Fade } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import PageContentSection from "../components/base-page-components/page-content-section/page-content-section";
import PageHeader from "../components/base-page-components/page-header-section/page-header";
import PageLinkBoxes from "../components/base-page-components/page-link-boxes/page-link-boxes";

export default function Page(props) {
  const [headers, setHeader] = useState(true);
  const [sections, setSections] = useState(null);
  const [isCentered, setCentered] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (props.noHeaders) {
      setHeader(false);
    }
    if (props.sections) {
      setSections(props.sections);
    }
    if (props.centered) {
      setCentered(true);
    }
    setLoaded(true);
  }, [props.noHeaders, props.sections, props.centered]);

  return (
    <Fade in={loaded} timeout={700}>
      <div>
        <PageHeader
          background={props.background ? props.background : null}
          title={props.title ? props.title : ""}
          centered={isCentered}
          svgID={props.svgID}
          bgContain={props.bgContain}
          imgStyles={props.imgStyles || {}}
        />
        <PageLinkBoxes links={sections} />
        <PageContentSection
          sections={sections}
          headers={headers}
          title={props.title ? props.title : ""}
        />
      </div>
    </Fade>
  );
}
