import React from "react";
import ImgCard from "../img-card";
import quality from "../../../../../../../assets/imgs/backgrounds/qualityassurance.jpg";
import { Button } from "@material-ui/core";
import { HashLink } from "react-router-hash-link";
import { useDispatch } from "react-redux";

export default function Quality() {
  const dispatch = useDispatch();
  const setPanel = (id) => dispatch({ type: "SET_PANEL", payload: id });

  return (
    <ImgCard
      image={<img src={quality} alt={"Hospital Quality"} />}
      title={"Quality Assurance"}
      content={
        <p>
          Weiser Memorial Hospital (WMH) is an accredited healthcare
          organization through DNV GL NIAHO Healthcare, Inc. Weiser Memorial
          makes the health of our patients, community, and staff priority.
        </p>
      }
      link={
        <Button
          variant={"contained"}
          color={"primary"}
          style={{ margin: "0.5rem" }}
          component={HashLink}
          to={"/about#hospital-quality"}
          onClick={() => setPanel("hospital-quality")}
        >
          Learn More
        </Button>
      }
    />
  );
}
