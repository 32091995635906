import blanchard from "../../../../../../../../assets/imgs/portrait/drblanchard.jpg";
import wheeler from "../../../../../../../../assets/imgs/portrait/anndi_wheeler.jpg";
import terry from "../../../../../../../../assets/imgs/portrait/brad_terry.jpg";
import hathhorn from "../../../../../../../../assets/imgs/portrait/melissa-hathhorn.jpg";
import smith from "../../../../../../../../assets/imgs/portrait/melissa-smith.jpg";
import ryan from "../../../../../../../../assets/imgs/portrait/ryan-ankarberg.jpg";
import gallegos from "../../../../../../../../assets/imgs/portrait/Stephanie_Gallegos.jpg";
// import allProvidersImg from "../../../../../../../../../assets/imgs/portraits/familymedical-group.jpg";
// import pcProviders from "../../../../../../../../../assets/imgs/portraits/primary-care-providers.jpg";
import ankarberg from "../../../../../../../../assets/imgs/portrait/ankarberg.jpg";
import martin from "../../../../../../../../assets/imgs/portrait/martin.jpg";
import ali from "../../../../../../../../assets/imgs/portrait/Ali_Capurro.jpg";

export const docList = [
  {
    src: blanchard,
    name: "Jordan Blanchard, M.D.",
  },
  {
    src: terry,
    name: "Brad Terry, D.O.",
  },
  {
    src: ryan,
    name: "Ryan Ankarberg, PA-C",
  },
  {
    src: wheeler,
    name: "Andrea Wheeler N.P.",
  },
  {
    src: smith,
    name: "Melissa Smith, MD",
  },
  {
    src: hathhorn,
    name: "Melissa Hathhorn, PMHNP-BC",
  },
  {
    src: gallegos,
    name: "Stephanie Gallegos, LMSW",
  },
  {
    src: ankarberg,
    name: "Selena Ankarberg, FNP",
  },
  {
    src: martin,
    name: "Sarah Martin, FNP",
  },
  {
    src: ali,
    name: "Ali Capurro, LPC",
  },
  // {
  //   src: allProvidersImg,
  //   name: "Family Medical Providers",
  // },
  // {
  //   src: pcProviders,
  //   name: "Primary Care Providers",
  // },
];
