import React, { useEffect } from "react";
import Helmet from "react-helmet";
import Page from "../page";
import { Button } from "@material-ui/core";

export default function MeetingArchive({ meetings }) {
  const archive = require("../../components/page-components/about/leadership/board-meetings/data/meeting-archive.json");
  const pageSections = [
    {
      title: "Meeting Archive",
      linkTo: "",
      content: (
        <ul>
          {archive.map((m, i) => (
            <li key={i}>
              <a
                href={`${process.env.PUBLIC_URL + "/files/board-meetings/"}${
                  m.fileName
                }`}
                target={"_blank"}
              >
                <Button type="link" color={"primary"}>
                  {m.title}
                </Button>
              </a>
            </li>
          ))}
        </ul>
      ),
    },
  ];

  useEffect(() => {
    document.title = "Meeting Archive";
  }, []);

  return (
    <>
      <Helmet>
        <title>Archive</title>

        <meta name="description" content="Board Meeting Archive." />
      </Helmet>
      <Page
        //   background={covidTitle}
        title={"Archive"}
        sections={pageSections}
        noHeaders
      />
    </>
  );
}
