import React from "react";
import { AppBar, Toolbar, makeStyles, IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import wmh from "../../../assets/imgs/logos/wmh/wmhlogoweb.svg";
import fmc from "../../../assets/imgs/logos/wmh/FMC_logo.svg";
import surgical from "../../../assets/imgs/logos/wmh/surgical_speciality_logo.svg";
import ContactSelector from "../page-components/contact-components/contact-selector/contact-selector";
import { MenuRounded } from "@material-ui/icons";
import { HashLink } from "react-router-hash-link";
import TopLinkBar from "./top-link-bar/menu-bar-top";
import COVIDBanner from "./top-link-bar/covid-banner/covid-banner";
import WMHSidebar from "../sidebar/sidebar";

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "white",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
  },
  imgContainer: {
    "& img": {
      margin: 10,
      maxHeight: 70,
    },
    "&.main": {
      minWidth: 280,
      "@media(max-width: 767px)": {
        minWidth: 220,
      },
      "@media(max-width: 300px)": {
        minWidth: 120,
      },
    },
  },
  imgContainerSml: {
    minWidth: "fit-content",
    "& img": {
      width: 150,
    },
  },
  menuButton: {
    "& > span": {
      display: "flex",
      flexFlow: "column",
      height: 2.5,
      width: 30,
      margin: 2.5,
      border: ".5px solid #232323",
      background: "#232323",
      borderRadius: 10,
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  menuContactTop: {
    "@media(max-width: 650px)": {
      display: "none",
    },
  },
  surgery: {
    "@media(max-width: 1112px)": {
      display: "none",
    },
  },
  family: {
    "@media(max-width: 830px)": {
      display: "none",
    },
  },
  noSmall: {
    "@media(min-width: 650px)": {
      display: "none",
    },
  },
}));

export default function MainMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const contacts = require("../page-components/contact-components/contact-selector/contacts.json");

  const setMenu = () => {
    dispatch({ type: "TOGGLE_MENU", action: null });
  };

  return (
    <div>
      <WMHSidebar />
      <AppBar className={classes.appBar}>
        <div>
          <TopLinkBar />
        </div>
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className={classes.logoContainer}>
            <div className={`${classes.imgContainer} main`}>
              <HashLink to={"/#"}>
                <img src={wmh} alt={"Weiser Memorial Hospital"} />
              </HashLink>
            </div>
            <div className={`${classes.imgContainer} ${classes.family}`}>
              <a href={"http://www.familymedicalweiser.org/"} target={"_blank"}>
                <img src={fmc} alt={"Family Medical Center"} />
              </a>
            </div>
            <div className={`${classes.imgContainer} ${classes.surgery}`}>
              <HashLink to={"/services#surgical-specialty-clinic"}>
                <img src={surgical} alt={"Surgical and Specialty Clinic"} />
              </HashLink>
            </div>
          </div>
          <div className={classes.menuContactTop}>
            <ContactSelector noLabel noIcon contacts={contacts.main} />
          </div>
          <IconButton onClick={setMenu}>
            <MenuRounded color={"primary"} />
          </IconButton>
        </Toolbar>
        <COVIDBanner />
      </AppBar>
    </div>
  );
}
