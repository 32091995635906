import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { HashLink } from "react-router-hash-link";
// import sunset from "../../../assets/imgs/backgrounds/farm-sunset.jpg";

const useStyles = makeStyles((theme) => ({
  rootFooter: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    // width: "100vw",
    minHeight: 100,
    background: theme.palette.primary.main,
    // background: `${theme.palette.primary.main} url(${sunset})`,
    // backgroundSize: "cover",
    // backgroundPosition: "50% 70%",
    zIndex: 2,
    "& p": {
      color: "white",
      margin: 0,
    },
  },
  lemonster: {
    textAlign: "center",
    textDecoration: "none",
    color: "#88aa00",
  },
  linkList: {
    display: "flex",
    flexWrap: "wrap",
    margin: 20,
    color: "white",
    "& ul": {},
    "& li": {
      display: "flex",
      flexFlow: "column",
      "& a": {
        color: "white",
        padding: 0,
      },
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const year = new Date().getFullYear();
  const dispatch = useDispatch();

  const setPanel = (id) => {
    dispatch({ type: "SET_PANEL", payload: id });
  };

  const ListLink = ({ href, title, id }) => (
    <ListItem
      component={HashLink}
      to={href}
      onClick={id ? () => setPanel({ id }) : null}
    >
      <ListItemText>{title}</ListItemText>
    </ListItem>
  );

  return (
    <div className={classes.rootFooter}>
      <div className={classes.linkList}>
        <List subheader={"Services"}>
          <ListItem>
            <ListLink
              title={"Family Medical Center"}
              href={"/services#family-medical-center"}
            />
            <ListLink
              title={"Surgical & Specialty Clinic"}
              href={"/services#surgical-specialty-clinic"}
            />
            <ListLink
              title={"Transitional Care"}
              href={"/services#swing-bed"}
            />
            <ListLink
              title={"Emergency Services"}
              href={"/services#emergency-services"}
            />
            <ListLink
              title={"Cardiopulmonary Services"}
              href={"/services#cardio-services"}
              id={"cardio-services"}
            />
            <ListLink
              title={"Imaging/Laboratory Services"}
              href={"/services#imaging-services"}
              id={"imaging-services"}
            />
          </ListItem>
        </List>
        <List subheader={"Information"}>
          <ListItem>
            <ListLink
              title={"Patient Information"}
              href={"/information#patient-information"}
            />
            <ListLink
              title={"Visitor Information"}
              href={"/information#visitor-information"}
            />
            <ListLink
              title={"Billing/Financial Assistance"}
              href={"/information#billing"}
            />
            <ListLink title={"Resources"} href={"/resources"} />
          </ListItem>
        </List>
        <List subheader={"Community"}>
          <ListItem>
            <ListLink
              title={"Community Health"}
              href={"/community#community-health"}
            />
            {/* <ListLink
              title={"Daisy Award"}
              href={"/community#daisy-award"}
            /> */}
            {/* <ListLink
              title={"In the News"}
              href={"/community#in-the-news"}
            /> */}
            <ListLink title={"CHNA"} href={"/community#CHNA"} />
          </ListItem>
        </List>
        <List subheader={"Weiser Memorial Hospital Foundation"}>
          <ListItem>
            <ListLink
              title={"The Foundation"}
              href={"/foundation#the-foundation"}
            />
            <ListLink
              title={"Ways of Giving"}
              href={"/foundation#ways-of-giving"}
            />
            <ListLink
              title={"Friends of the Hospital"}
              href={"/foundation#friends-of-the-hospital"}
            />
            <ListLink
              title={"Festival of Trees"}
              href={"/foundation#festival-of-trees"}
            />
          </ListItem>
        </List>
      </div>
      <div style={{ textAlign: "center", marginBottom: 20 }}>
        <p>&copy; Weiser Memorial Hospital {year}</p>
      </div>
    </div>
  );
}
