import { Grid, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import steve from "../../../../../assets/imgs/portrait/ceo-steve.jpg";
import pam from "../../../../../assets/imgs/portrait/pam-stampfli.jpg";
import hilary from "../../../../../assets/imgs/portrait/hilary_kile.jpg";
import Members from "../../landing-page-components/card-section/board-members-cards/members";
import BoardMeetings from "./board-meetings/board-meetings";

const useStyles = makeStyles((theme) => ({
  title: {
    padding: 10,
    "& h3": {
      margin: 0,
    },
    "& h4": {
      marginTop: 0,
    },
  },
  grid: {
    "& h4": {
      marginBottom: 0,
    },
    "& li": {
      margin: 0,
      fontSize: "0.85rem",
    },
  },
  school: {
    marginLeft: 20,
    "& h5": {
      marginBottom: 0,
    },
    "& p": {
      marginTop: 0,
    },
  },
}));

export default function Leadership(props) {
  const classes = useStyles();

  const adminBios = [
    {
      img: {
        src: steve,
        alt: "CEO Steven D. Hale",
      },
      name: "Steven D. Hale, CEO, MHA, FACHE",
      title: "Chief Executive Officer",
      education: [
        {
          degree: "Bachelor of Science, Biology",
          school: (
            <a href={"https://www.nwmissouri.edu/"}>
              Northwest Missouri State University, Maryville, MO
            </a>
          ),
        },
        {
          degree: "Masters, Health Care Administration",
          school: (
            <a href={"https://www.baylor.edu/"}>Baylor University, Waco, TX</a>
          ),
        },
      ],
      affiliations: [
        <a href={"https://www.ache.org/FACHE"}>
          American College of Healthcare Executives, Fellow (Since 2007)
        </a>,
        <a href={"https://ameddregiment.amedd.army.mil/merit/o2m3.html"}>
          Order of Military Medical Merit, Member #6680
        </a>,
        "Veterans of Foreign Wars, Life Member",
        "Idaho Hospital Association",
      ],
      awards: [
        <a href={"https://hcmacny.org/ache-regents-awards/"}>
          Army Regent’s Senior Healthcare Executive Award of Excellence 2009
        </a>,
        <a href={"http://awards.paeaonline.org/"}>
          <p>
            US Army-Baylor Healthcare Administration Programs’ <br />
            Preceptor of the Year Award, 2006
          </p>
        </a>,
      ],
    },
    {
      img: {
        src: pam,
        alt: "CFO Pamela Stampfli",
      },
      name: "Pamela Stampfli – CFO, MBA, CPA",
      title: "Chief Financial Officer",
      education: [
        {
          degree: "Bachelors of Business Administration in Accountancy",
          school: (
            <a href={"https://www.boisestate.edu/"}>
              Boise State University, Idaho
            </a>
          ),
        },
        {
          degree: "MBA, Healthcare Management",
          school: (
            <a href="https://www.wgu.edu/">Western Governors University</a>
          ),
        },
      ],
      affiliations: [
        "Certified Public Accountant (CPA) - Idaho License #CP-3779",
        <a href={"https://www.idcpa.org/"}>Idaho Society of CPA’s Member</a>,
        "Healthcare Financial Management Association (HFMA)",
        "Idaho HFMA Board Secretary",
        "IHA Healthcare Business Ventures board member"
      ],
    },
    {
      img: {
        src: hilary,
        alt: "Hilary Kile",
      },
      name: "Hilary Kile, RN",
      title: "Chief Nursing Officer",
      education: [
        {
          degree: "Master of Science, Nursing, Public Health",
          school: (
            <a href="https://explore.gcu.edu/">Grand Canyon University</a>
          ),
        },
        {
          degree: "Bachelor of Science, Nursing",
          school: (
            <a href="https://www.ohsu.edu/">
              Oregon Health Science University ‐ Portland, OR
            </a>
          ),
        },
        {
          degree: "Associate Degree, Nursing",
          school: (
            <a href="https://www.tvcc.cc/">
              Treasure Valley Community College ‐ Ontario, OR
            </a>
          ),
        },
      ],
      awards: [
        "Advanced Cardiac Life Support (ACLS) Provider",
        "Pediatric Advanced Life Support (PALS) Provider",
        "Neonatal Resuscitation Program (NRP) Provider",
        "American Heart Association CPR Certification in Infection Control (CIC)",
        "Rural Health Chief Nursing Officer Certification (RHCNOC)",
      ],
      affiliations: [
        "Member, Association for Professionals in Infection Control and Epidemiology",
        "Member, National Rural Health Association",
        "Member, Nurse Leaders of Idaho",
      ],
    },
  ];

  const Bio = ({ img, title, name, education, affiliations, awards }) => {
    return (
      <Grid
        container
        spacing={2}
        style={{ margin: "2rem auto" }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item sm={4}>
          <Paper style={{ maxWidth: "fit-content", overflow: "hidden" }}>
            {img && img.src ? (
              <img
                src={img.src}
                alt={img.alt}
                style={{ marginBottom: -5, maxWidth: 220 }}
              />
            ) : (
              <div style={{ height: 300, background: "grey" }}>
                <h2
                  style={{
                    transform:
                      "rotate(45deg) translateX(98px) translateY(84px)",
                  }}
                >
                  COMING SOON!
                </h2>
              </div>
            )}
          </Paper>
        </Grid>
        <Grid item sm={8} className={classes.grid}>
          <div className={`${classes.title} dark-content`}>
            <h3>{name && name}</h3>
            <h4>{title && title}</h4>
          </div>
          {education && (
            <div>
              <h4>Education</h4>
              <hr />
              {Array.isArray(education) &&
                education.map((e, i) => (
                  <div key={i * 0.333} className={classes.school}>
                    <h5>{e.degree}</h5>
                    <p>{e.school}</p>
                  </div>
                ))}
            </div>
          )}
          {affiliations && (
            <div>
              <h4>Affiliations</h4>
              <hr />
              <ul>
                {Array.isArray(affiliations) &&
                  affiliations.map((e, i) => <li key={i * 0.111}>{e}</li>)}
              </ul>
            </div>
          )}
          {Array.isArray(awards) && (
            <div>
              <h4>Awards & Certifications</h4>
              <hr />
              <ul>
                {Array.isArray(awards) &&
                  awards.map((e, i) => <li key={i * 0.222}>{e}</li>)}
              </ul>
            </div>
          )}
        </Grid>
        <hr />
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      <h2 className={"primary-title"} style={{ textAlign: "center" }}>
        Leadership
      </h2>
      <Grid item xs={12}>
        <h2 style={{ textAlign: "center" }}>Administration</h2>
        {adminBios.map((a, i) => (
          <Bio
            key={i}
            img={a.img}
            name={a.name}
            title={a.title}
            education={a.education}
            affiliations={a.affiliations}
            awards={a.awards}
          />
        ))}
      </Grid>
      <Grid item xs={12} style={{ background: "white" }}>
        <h2 className={"primary-title"} style={{ textAlign: "center" }}>
          Hospital Board
        </h2>
        <Members />
      </Grid>
      <Grid item xs={12}>
        <BoardMeetings />
      </Grid>
    </Grid>
  );
}
