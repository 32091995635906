import React, { useEffect } from "react";
import Page from "../page";
import WaysOfGiving from "../../components/page-components/foundation/ways-of-giving/ways-of-giving";
import helping from "../../../assets/imgs/backgrounds/helping-hands.jpg";
import Friends from "../../components/page-components/foundation/friends/friends-of-the-hospital";
import TheFoundation from "../../components/page-components/foundation/the-foundation/the-foundation";
import FestivalOfTrees from "../../components/page-components/foundation/fesitval-of-trees/festival-of-trees";
import Helmet from "react-helmet";
import Scholarship from "../../components/page-components/foundation/scholarship/scholarship";

export default function Careers() {
  const pageSections = [
    // TODO Add scholarship award winners
    {
      title: "The Foundation",
      linkTo: "the-foundation",
      content: <TheFoundation />,
    },
    {
      title: "Festival of Trees",
      linkTo: "festival-of-trees",
      content: <FestivalOfTrees />,
    },
    {
      title: "Ways of Giving",
      linkTo: "ways-of-giving",
      content: <WaysOfGiving />,
    },
    {
      title: "Healthcare Scholarship",
      linkTo: "scholarship",
      content: <Scholarship />,
    },
    {
      title: "Friends of the Hospital",
      linkTo: "friends-of-the-hospital",
      content: <Friends />,
    },
  ];

  useEffect(() => {
    document.title = "Foundation";
  }, []);

  return (
    <>
      <Helmet>
        <title>The Foundation</title>

        <meta
          name="description"
          content="The Weiser Memorial Hospital Foundation was founded in 1987 as a citizen-driven effort to develop resources for Weiser Memorial Hospital. In 2000, the Foundation reorganized, incorporated and received 501c3 designation from the IRS."
        />
      </Helmet>
      <Page
        title={"Foundation"}
        background={helping}
        sections={pageSections}
        svgID={3}
        noHeaders
      />
    </>
  );
}
