import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { ArrowBackOutlined, Home } from "@material-ui/icons";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";

export default function PageNotFound404() {
  useEffect(() => (document.title = "404 Page Not Found"));
  const history = useHistory();

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexFlow: "column",
      }}
    >
      <Helmet>
        <title>404 Page Not Found</title>
        <meta name="description" content="ERROR 404: We can't find this page" />
      </Helmet>
      <h1>ERROR 404</h1>
      <h2>Page Not Found</h2>
      <Button component={Link} to="/">
        <Home />
        Home
      </Button>
      <Button onClick={() => history.goBack()}>
        <ArrowBackOutlined />
        Back
      </Button>
    </div>
  );
}
