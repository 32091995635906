import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  Paper,
} from "@material-ui/core";
import React from "react";
import { ExpandMoreRounded, Phone } from "@material-ui/icons";
import SSClinicImg from "../landing-page-components/card-section/img-card/SSClinic-img-card/SSClinic-img";
import surgery from "../../../../assets/imgs/backgrounds/surgery-photo.jpg";
import ContactCard from "../contact-components/contact-card/contact-card";
import { useSelector } from "react-redux";
import WaveTitle from "../../wave-title/wave-title";
import SSCLogo from "../../../../assets/imgs/logos/wmh/surgical_speciality_logo.svg";
import bilboard from "../../../../assets/imgs/ads/billboard-add.png";

const useStyles = makeStyles((theme) => ({
  FMCGridRoot: {
    padding: "0.35rem",
    "& li": {
      padding: "5px 10px",
    },
  },
  FMCImageRoot: {
    background: theme.palette.primary.main,
  },
  calendar: {
    background: theme.palette.primary.main,
    "& li span": {
      fontSize: "0.75rem",
    },
    "& b": {
      fontSize: "1rem",
    },
  },
  oddSchedule: {
    background: theme.palette.secondary.main,
  },
  staffChip: {
    background: theme.palette.secondary.main,
  },
  procedureChip: {
    padding: 5,
    background: theme.palette.primary.main,
    color: "white",
  },
  procedureSection: {
    background: theme.palette.primary.main,
    padding: 10,
  },
}));

export default function SSClinicComponent(props) {
  const classes = useStyles();
  const pagePanel = useSelector((state) => state.pagePanel);

  // const medStaff = [
  //   <p>
  //     <b>1</b> General Surgeons
  //   </p>,
  //   <p>
  //     <b>4</b> Family Physicians
  //   </p>,
  //   <p>
  //     <b>1</b> Obstetrician
  //   </p>,
  //   <p>
  //     <b>1</b> Orthopaedist
  //   </p>,
  //   <p>
  //     <b>3</b> Cardiologist
  //   </p>,
  //   <p>
  //     <b>1</b> Podiatrist
  //   </p>,
  //   <p>Cardiac Testing</p>,
  //   <p>General Surgeries</p>,
  //   <p>Mammograms</p>,
  //   <p>Sleep Studies (By Appointment)</p>,
  // ];

  const procedures = [
    "Colonoscopy",
    "Endoscopy",
    "Hernia Repair",
    "Hysterectomy",
    "Tubal Ligation",
    "C-Section",
    "Bowel Resection",
    "Laparoscopic Cholecystectomy",
    "Hip Replacement",
    "Uniknee/Total Knee Replacement",
    "Knee Arthroscopy",
    "Repair of fractures such as hip, leg, arm, and wrist bunionectomy",
    "Appendectomy",
    "Mastectomy (If cancer suspected)",
    "Lumpectomy",
  ];

  return (
    <Grid
      container
      spacing={3}
      justifyContent={"center"}
      align={"center"}
      className={classes.FMCGridRoot}
    >
      <Grid item md={4}>
        <WaveTitle title={"Surgical & Specialty Clinic"} mask={"smooth-arch"} />
        <img
          src={SSCLogo}
          alt={"Surgical Center Logo"}
          style={{ maxWidth: "100%", marginTop: "1rem" }}
        />
        <p>
          The Surgical & Specialty Clinic has been in service since 1986, and at
          our current location at the front entrance of the hospital since 1991.
          Our clinic is a facility where physicians practicing specialized
          medicine can provide service for our local community. We work closely
          with the Family Practice offices in making these services available
          close to home.
        </p>
        <p>
          Presently we have 4 specialties offered in the clinic. These
          specialties include Orthopedics, Cardiology, Podiatry and General
          Surgery.
        </p>
      </Grid>
      <Grid item md={8}>
        <div className={classes.FMCImageRoot}>
          <SSClinicImg />
        </div>
        <Divider style={{ height: 5, margin: "1rem" }} />
        <div style={{ width: "100%", textAlign: "center" }}>
          <a href={"tel:+12085493152"}>
            <Button color={"primary"} variant={"contained"}>
              <Phone />
              Call Now
            </Button>
          </a>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ContactCard
            address={
              <>
                <p>645 East 5th Street</p>
                <p>Weiser, ID 83672</p>
              </>
            }
            href={"645 East 5th Street Weiser, ID 83672"}
            phone={"208-549-3152"}
            fax={"208-549-3153"}
          />
        </div>
      </Grid>
      <Grid item md={12}>
        <img src={bilboard} alt={"billboard"} style={{ width: "100%" }} />
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Accordion
          defaultExpanded={pagePanel === "ss-surgical-services"}
          id={"ss-surgical-services"}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreRounded />}
            aria-controls={"departments-surgical-content"}
            id="departments-surgical-content"
          >
            <Typography>SURGICAL SERVICES</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item style={{ maxWidth: "100%" }}>
                {/* <Typography component={"div"}>
                  Surgeons and Medical Staff Include
                </Typography>
                {medStaff.map((d, i) => (
                  <Chip
                    style={{ margin: 5 }}
                    className={classes.staffChip}
                    key={i * 0.005}
                    label={d}
                  />
                ))}
                <p>
                  Surgeons at WMH are assisted by CRNFA Licensed Practical
                  Nurses and Certified Scrub Technicians who attend conferences
                  on topics such as Orthopedic Fracture Fixation and Patient
                  Safety.
                </p> */}
                <Grid
                  container
                  className={classes.procedureSection}
                  alignItems={"center"}
                  justifyContent={"center"}
                  spacing={2}
                >
                  <Grid item md={6} style={{ maxWidth: "100%" }}>
                    <h2 style={{ color: "white" }}>Procedures</h2>
                    <Paper>
                      <ul style={{ listStyle: "none", marginLeft: -40 }}>
                        {procedures.map((d, i) => (
                          <li key={i}>{d}</li>
                        ))}
                      </ul>
                    </Paper>
                  </Grid>
                  <Grid item md={6} style={{ maxWidth: "100%" }}>
                    <img
                      style={{ maxWidth: "inherit" }}
                      src={surgery}
                      alt={"surgery"}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreRounded />}
            aria-controls={"forms-content"}
            id="forms-content"
          >
            FORMS
          </AccordionSummary>
          <AccordionDetails>
            <List
              subheader={
                <ListSubheader component={"div"}>Downloads</ListSubheader>
              }
            >
              <a
                href={
                  process.env.PUBLIC_URL +
                  "/files/downloads/forms/wmh_surgicalspecialtyclinic_patientregistration_fill-in.pdf"
                }
              >
                <ListItem>
                  <ListItemText>Patient Registration Form</ListItemText>
                </ListItem>
              </a>
              <a
                href={
                  process.env.PUBLIC_URL +
                  "/files/downloads/forms/wmh_surgical-specialtyclinic_medicalhistory_form.pdf"
                }
              >
                <ListItem>
                  <ListItemText>Medical History Form</ListItemText>
                </ListItem>
              </a>
            </List>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
