import { Grid, Paper } from "@material-ui/core";
import React from "react";
import ReactPlayer from "react-player";

export default function CPRClasses(props) {
  return (
    <Grid container justifyContent={"center"} alignItems={"center"} spacing={2}>
      <Grid item md={12}>
        <Paper className={"dark-content"}>
          <h3>WHY LEARN CPR?</h3>
          <ul>
            <li>
              Effective bystander CPR can greatly increase the victim’s chance
              of survival, but a majority of victims do not receive CPR from
              bystanders.
            </li>
            <li>
              4 out of 5 cardiac arrests occur at home – this means the life you
              save with CPR is most likely going to be someone you love.
            </li>
            <li>
              Most people want to help in an emergency, but lack the confidence
              to do so. CPR training will arm you with the knowledge, skills,
              and confidence you need to change from being a bystander to a
              lifesaver!
            </li>
          </ul>
        </Paper>
      </Grid>

      <ReactPlayer
        url={"https://fb.watch/8NztGzt-BX/"}
        width={"500px"}
        controls
      />
      <Grid item md={12}>
        {/* <img src={cpr} alt="cpr schedule" style={{ maxWidth: "100%" }} /> */}
        <Paper
          style={{
            padding: "1rem",
          }}
        >
          <h3>
            Weiser Memorial Hospital offers the following certification classes:
          </h3>
          <ul>
            <li>
              Basic Life Support for Healthcare Providers Community Heartsaver
            </li>
            <li>Pediatric Heartsaver</li>
            <li>Pediatric Advanced Life Support</li>
            <li>Advanced Cardiac Life Support</li>
          </ul>
          <h3>
            Please contact <b>Danielle Marvin</b> at{" "}
            <a href="tel:2085494401">(208) 549-4401</a> to schedule a group or
            individual class.
          </h3>
        </Paper>
      </Grid>
    </Grid>
  );
}
