import React, { useEffect } from "react";
import frontView from "../../../assets/imgs/backgrounds/hospital-front.jpg";
import AboutFacts from "../../components/page-components/about/about-facts/about-facts";
import Leadership from "../../components/page-components/about/leadership/leadership";
import FAQ from "../../components/page-components/about/faq/faq";
import Page from "../page";
import Helmet from "react-helmet";

export default function About() {
  const pageSections = [
    {
      title: "About/Facts",
      linkTo: "about-facts",
      content: <AboutFacts />,
    },
    {
      title: "Leadership",
      linkTo: "leadership",
      content: <Leadership />,
    },
    {
      title: "FAQ",
      linkTo: "faq",
      content: <FAQ />,
    },
  ];

  useEffect(() => {
    document.title = "About Weiser Memorial Hospital";
  }, []);

  return (
    <>
      <Helmet>
        <title>About WMH</title>

        <meta
          name="description"
          content="Founded in 1950, Weiser Memorial Hospital was established with funding from Washington County, the City of Weiser and individual donors."
        />
      </Helmet>
      <Page
        background={frontView}
        title={"About"}
        sections={pageSections}
        svgID={3}
        noHeaders
      />
    </>
  );
}
