import React from "react";
import { makeStyles, SwipeableDrawer, IconButton } from "@material-ui/core";
import wmh from "../../../assets/imgs/logos/wmh/wmhlogoweb.svg";
import { Link } from "react-router-dom";
import MenuItems from "./menu-items";
import { useDispatch, useSelector } from "react-redux";
import { Close, Facebook, Instagram } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  sidebarRoot: {
    position: "relative",
    height: "100%",
  },
  imgContainer: {
    "& img": {
      margin: 10,
      maxHeight: 70,
    },
    "&.main": {
      minWidth: 280,
      "@media(max-width: 767px)": {
        minWidth: 220,
      },
    },
  },
  imgContainerSml: {
    minWidth: "fit-content",
    "& img": {
      width: 150,
    },
  },
}));

export default function WMHSidebar() {
  const menuState = useSelector((state) => state.menu.open);
  const classes = useStyles();
  const dispatch = useDispatch();

  const setMenu = () => {
    dispatch({ type: "TOGGLE_MENU", action: null });
  };

  return (
    <SwipeableDrawer
      swipeAreaWidth={0}
      open={menuState}
      anchor={"right"}
      onClose={setMenu}
      onOpen={setMenu}
    >
      <div className={classes.sidebarRoot}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <IconButton onClick={setMenu} style={{ margin: 10 }}>
            <Close color={"primary"} />
          </IconButton>
          <div className={classes.imgContainerSml}>
            <Link to={"/"}>
              <img
                src={wmh}
                alt={"Weiser Memorial Hospital"}
                onClick={setMenu}
              />
            </Link>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <a
              href={"https://www.facebook.com/WeiserMemorialHospital"}
              rel={"noreferrer"}
              target={"_blank"}
              style={{
                textDecoration: "none",
                color: "initial",
                padding: 0,
              }}
            >
              <IconButton>
                <Facebook color={"primary"} />
              </IconButton>
            </a>
            <a
              href={
                "https://www.instagram.com/explore/locations/1229898717085561/weiser-memorial-hospital/"
              }
              rel={"noreferrer"}
              target={"_blank"}
              style={{
                textDecoration: "none",
                color: "initial",
                padding: 0,
              }}
            >
              <IconButton>
                <Instagram color={"primary"} />
              </IconButton>
            </a>
          </div>
        </div>
        <MenuItems />
      </div>
    </SwipeableDrawer>
  );
}
