import { makeStyles, Paper } from "@material-ui/core";
import { Phone, Print } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
  address: {
    "& p": {
      margin: 0,
    },
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function ContactCard(props) {
  const classes = useStyles();

  return (
    <div className={classes.address}>
      <h4 style={{ marginBottom: 0 }}>{props.label}</h4>
      {props.address &&
        (props.href ? (
          <a href={`https://maps.google.com/maps?q=${props.href}`}>
            {props.address}
          </a>
        ) : (
          props.address
        ))}
      <Paper style={{ padding: 5, background: "aliceblue" }} elevation={0}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Phone />
          <b>
            <a href={`tel:+1${props.phone.replace("-", "")}`}>{`(${
              props.phone.split("-", 1)[0]
            }) ${props.phone.split("-")[1]}-${props.phone.split("-")[2]}`}</a>
          </b>
        </div>
        {props.fax && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Print />
            <b>{`(${props.fax.split("-", 1)[0]}) ${props.fax.split("-")[1]}-${
              props.fax.split("-")[2]
            }`}</b>
          </div>
        )}
        {props.email && (
          <div>
            <a href={`mailto:${props.email}`}>{props.email}</a>
          </div>
        )}
        {props.tail && <p>{props.tail}</p>}
      </Paper>
      {props.website && (
        <div>
          <a href={`${props.website}`}>{props.website}</a>
        </div>
      )}
    </div>
  );
}
