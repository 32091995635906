import React from "react";
// import banner from "../../../../../assets/imgs/ads/scholarship-application-banner.jpg";
// import smBanner from "../../../../../assets/imgs/ads/scholarship-application-banner-sm.jpg";
import { Button, ButtonGroup, Paper } from "@material-ui/core";

export const ApplicationButtons = () => (
  <ButtonGroup>
    <a
      href={
        process.env.PUBLIC_URL +
        "/files/downloads/forms/2024-WMH-Scholarship-Fund-Application.pdf"
      }
      target="_blank_"
    >
      <Button color="primary" variant="contained">
        Download Scholarship Application
      </Button>
    </a>
  </ButtonGroup>
);

export default function Scholarship({ smallImg }) {
  return (
    <div style={{ display: "flex", alignItems: "center", flexFlow: "column" }}>
      {/* <picture>
        <source media="(max-width: 900px)" srcSet={smBanner} />
        <img
          src={!smallImg ? banner : smBanner}
          alt="foundation scholarship"
          style={{ maxWidth: "100%", marginBottom: "1em" }}
        />
      </picture> */}
      <Paper style={{ margin: "1rem auto", padding: 20 }}>
        <h2 className={"primary-title"}>Healthcare Scholarship</h2>
        <h3 className="dark-content">
          Weiser Memorial Hospital Foundation Healthcare Scholarship
        </h3>
        <p>
          The 2024 Weiser Memorial Hospital Foundation Healthcare Scholarship is
          now closed. 2025 scholarship applications will be available in March
          of next year. The scholarship fund was established in 2021 to assist
          students in furthering their education in a healthcare-related field.
        </p>
        <p>
          In 2025, we will award seven one-time $1,000 awards to exemplary
          students who live or work in the Washington County area. Applicants
          are eligible to reapply for up to four years.
        </p>
      </Paper>

      {/* <ApplicationButtons /> */}
    </div>
  );
}
