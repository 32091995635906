import { makeStyles, IconButton } from "@material-ui/core";
import { Facebook } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
  contactInfo: {
    "& p": {
      margin: 0,
    },
  },
}));

export default function FoundationContact(props) {
  const classes = useStyles();

  return (
    <div style={{ width: "100%" }}>
      <div className={classes.contactInfo}>
        <h5>CONTACT US</h5>
        <p>Kim Burgess</p>
        <p>Executive Director</p>
        <a href="https://www.google.com/maps/place/645+E+5th+St,+Weiser,+ID+83672/@44.2487773,-116.9647665,17z/data=!3m1!4b1!4m5!3m4!1s0x54a579247a0b177d:0xfb84d0cb26c10dff!8m2!3d44.2487773!4d-116.9625778?hl=en">
          <p>645 East 5th Street</p>
          <p>Weiser, ID 83672</p>
        </a>
        <p>
          Phone: <a href="tel:1+2085494412">(208) 549-4412</a>
        </p>
        <p>Fax: (208) 414-4268</p>
        <p>
          <a href="mailto:kburgess@weiserhospital.org">kburgess@weiserhospital.org</a>
        </p>
      </div>
      <div>
        <a
          href={"https://www.facebook.com/WeiserHospitalFoundation"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton>
            <Facebook />
          </IconButton>
        </a>
      </div>
    </div>
  );
}
