import { Grid, Paper } from "@material-ui/core";
import React from "react";
import fit from "../../../../../../assets/imgs/portrait/fit-and-fall.jpg";

export default function FitFallProof(props) {
  return (
    <Grid container alignItems={"center"} justifyContent={"center"} spacing={2}>
      <Grid item md={6}>
        <h2 className={"primary-title"}>
          Fit and Fall Proof <i>**FREE!</i>
        </h2>
        <p>
          Fit and Fall Proof (FFP) is an exercise-based program to prevent
          falls. It assists older adults to improve muscle strength, while
          increasing balance, posture and flexibility. It also improves
          mobility, endurance and walking gait.
        </p>
      </Grid>
      <Grid item md={6}>
        <img src={fit} alt={"fit and safe"} style={{ maxWidth: "100%" }} />
      </Grid>
      <Grid item xs={12}>
        <h2 className={"primary-title"}>
          EVERY TUESDAY AND THURSDAY - 9:30 a.m-10:30 a.m
        </h2>
        <p>
          For information, please call{" "}
          <a href={"tel:+12085494438"}>(208) 549-4438</a>.
        </p>
        <p>
          <i>*Dates & times may not reflect actual class times.</i>
        </p>
        <Paper>
          <h3>LOCATION:</h3>
          <h3>
            <b>
              Vendome Events Center
              <br />
              309 State Street, Weiser
            </b>
          </h3>
        </Paper>
        <p>
          This is an exercise-based program to prevent falls. It assists older
          adults to:
        </p>
        <ul>
          <li>Improve muscle strength</li>
          <li>Increase balance, posture and flexibility</li>
          <li>Improve mobiltiy, endurance and walking gait</li>
        </ul>
        <blockquote>
          “Before I started Fit & Fall Proof, my balance was so bad that I could
          not stand on one foot. My wrist was so weak and painful that I could
          not open a jar. Now after completing this class and practicing the
          exercises at home I am able to stand on one foot with good balance. My
          wrist has improved so much I can open jars now without pain. I really
          enjoyed the class and plan to continue next session.”
          <p>
            <i>~ Nancy, Weiser</i>
          </p>
        </blockquote>
      </Grid>
    </Grid>
  );
}
