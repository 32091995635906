import { makeStyles, Paper, Divider } from '@material-ui/core';
import React from 'react';

export default function ImgCard(props) {
    const useStyles = makeStyles((theme) => ({
        cardRoot: {
            padding: 10,
            background: 'rgba(218, 225, 237, 0.85)',
            '& .MuiListItemIcon-root': {
                minWidth: 36
            }
        },
        cardImage: {
            textAlign: 'center',
            background: !props.lightBg?theme.palette.primary.main:theme.palette.secondary.main,
            padding: 10, 
            borderRadius: 10,
            '& img': {
                borderRadius: 10,
                maxWidth: '100%',
                maxHeight: 250,
            }
        },
        cardContent: {
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
        },
    }));
    const classes = useStyles();

    return (
        <Paper className={classes.cardRoot} {...props.rest}>
            <div className={classes.cardImage}>
                {props.image}
            </div>
            <div className={classes.cardContent}>
                <h3>{props.title}</h3>
                <Divider />
                {props.content}
            </div>
            {props.link && props.link}
        </Paper>
    )
};