import React from "react";
import { Grid, Button } from "@material-ui/core";
import fruitbowl from "../../../../../../assets/imgs/backgrounds/fruitbowl1.jpg";
import Bistro from "../../patient-information/information-components/food-nutrition/bistro";

export default function VisitorFood(props) {

  return (
    <Grid
      container
      spacing={2}
      justifyContent={"center"}
      alignItems={"center"}
      style={{ textAlign: "center" }}
    >
      <Grid item md={6}>
        <Bistro />
        <p>Information on local restaurants below.</p>
        <a
          style={{ textDecoration: "none" }}
          href={
            "https://www.google.com/search?q=restaurants+in+weiser+idaho&rflfq=1&num=10&ved=2ahUKEwig_qurhMntAhUBvp4KHWeXCRgQtgN6BAgCEAc#rlfi=hd:;si:;mv:[[44.257379199999995,-116.95632449999998],[44.2397518,-116.98127500000001]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u5!2m2!5m1!1sgcid_3american_1restaurant!1m4!1u5!2m2!5m1!1sgcid_3mexican_1restaurant!1m4!1u2!2m2!2m1!1e1!1m4!1u1!2m2!1m1!1e1!1m4!1u1!2m2!1m1!1e2!1m4!1u16!2m2!16m1!1e1!1m4!1u16!2m2!16m1!1e2!1m4!1u22!2m2!21m1!1e1!2m1!1e2!2m1!1e5!2m1!1e16!2m1!1e1!2m1!1e3!3sIAEqAlVT,lf:1,lf_ui:9"
          }
          target={"_blank"}
          rel={"noopener nofollow"}
        >
          <Button variant={"contained"} color={"secondary"}>
            Weiser Restaurants
          </Button>
        </a>
      </Grid>
      <Grid item md={6}>
        <img style={{ maxWidth: "100%" }} src={fruitbowl} alt={"fruitbowl"} />
      </Grid>
    </Grid>
  );
}
