import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Grid,
  makeStyles,
  Modal,
} from "@material-ui/core";
import { Launch, PlayCircleOutline } from "@material-ui/icons";
import ReactPlayer from "react-player";
import ScrollAnimation from "react-animate-on-scroll";

import mountainStream from "../../../../../assets/imgs/backgrounds/stream-between-mountains.jpg";
import dnvlogo from "../../../../../assets/imgs/logos/dnvlogomasterpage1.png";
import workplace from "../../../../../assets/imgs/logos/workplace.png";
import southwest from "../../../../../assets/imgs/logos/southwest-badge.png";
import ultrasound from "../../../../../assets/imgs/logos/ultrasound.png";
import Overlay from "../../../../components/overlay/overlay";

const useStyles = makeStyles((theme) => ({
  rootHeader: {
    display: "flex",
    background: `url(${mountainStream})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    height: "100vh",
    width: "100vw",
    transition: "background 9999s ease",
    "& h1": {
      fontWeight: 400,
    },
  },
  header: {
    minHeight: "100vh",
    marginTop: "4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    color: "white",
  },
  playButton: {
    display: "flex",
    alignItems: "center",
    "@media(min-width: 818px)": {
      display: "none",
    },
    "@media(max-height: 490px)": {
      display: "initial",
    },
  },
  videoPlayer: {
    display: "flex",
    alignItems: "center",
    flexFlow: "column",
    margin: 10,
    "@media(max-width: 1059px)": {
      margin: 5,
    },
    "@media(max-width: 818px)": {
      display: "none",
    },
    "@media(max-height: 490px)": {
      display: "none",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logos: {
    display: "flex",
    marginTop: "1rem",
    "flex-wrap": "wrap",
    "& img": {
      maxHeight: 100,
      margin: 10,
      "@media(max-width: 1059px)": {
        maxHeight: 70,
      },
    },
  },
  mask: {
    width: "100vw",
    marginTop: -331,
    "@media(min-width: 1800px)": {
      marginTop: -400,
    },
  },
}));

export default function Header(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const vidURL =
    "https://www.cyranoapp.media/msg/?q=679ce8a3-0a07-11ee-93b8-1230392c7b08";
  const isIE = useSelector((state) => state.isIE);
  const classes = useStyles();

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://www2.cyranoapp.media/embed/77412bf7-0ad4-11ee-bf3e-1230392c7b08";
    script.async = true;

    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  return (
    <div className={classes.rootHeader}>
      <Overlay color={"rgba(0,0,0, 0.06)"}>
        <div
          style={{
            maxHeight: "100vh",
          }}
        >
          <div className={classes.header}>
            <div style={{ margin: "1rem" }}>
              <h1
                style={{
                  color: "white",
                }}
              >
                Our Mission is <br />
                to Save Lives,
                <br /> Improve Health,
                <br /> & Build Community.
              </h1>
              <div>
                <p>Weiser Memorial Hospital</p>
              </div>
              <Grid container spacing={2}>
                <Grid item>
                  <Button variant={"contained"} color={"secondary"}>
                    <a
                      href="https://mycarecorner.net/Default.aspx"
                      style={{
                        textDecoration: "none",
                        color: "initial",
                        display: "flex",
                        alignItems: "center",
                      }}
                      target={"_blank"}
                    >
                      <Launch style={{ marginRight: 5 }} />
                      Patient Portal
                    </a>
                  </Button>
                </Grid>
                <Grid item>
                  <div className={classes.playButton}>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      onClick={() => setModalOpen(true)}
                    >
                      <PlayCircleOutline
                        style={{ color: "white", marginRight: 5 }}
                      />
                      Covid Video
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <div className={classes.logos}>
                <ScrollAnimation
                  animateIn="bounceInLeft"
                  duration={1.25}
                  animateOnce
                >
                  <img src={workplace} alt={"Best Places To Work 2022"} />
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="bounceInLeft"
                  duration={1.5}
                  animateOnce
                >
                  <img src={dnvlogo} alt={"DNC Accreditation"} />
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="bounceInLeft"
                  duration={1.75}
                  animateOnce
                >
                  <img
                    src={ultrasound}
                    alt={"American College Of Radiology Accreditation"}
                  />
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="bounceInLeft"
                  duration={2}
                  animateOnce
                >
                  <img
                    src={southwest}
                    alt={"Best places to work - Idaho Southwest"}
                  />
                </ScrollAnimation>
              </div>
            </div>
            <div className={classes.videoPlayer}>
              <div
                id="cyrano-share-77412bf7-0ad4-11ee-bf3e-1230392c7b08"
                style={{
                  display: "inline-block",
                  width: "300px",
                  height: "300px",
                }}
                class="cyr-resp"
              />
              {/* <ReactPlayer url={vidURL} width={"500px"} controls /> */}
              {/* <ButtonGroup>
                <Button
                  variant={"contained"}
                  color="primary"
                  component={Link}
                  to="/covid"
                >
                  COVID INFO
                </Button>
                <Button variant={"contained"} color="secondary">
                  <a
                    href={
                      process.env.PUBLIC_URL +
                      "/files/covid/testing-locations.pdf"
                    }
                    target="__blank"
                  >
                    Testing Locations
                  </a>
                </Button>
              </ButtonGroup> */}
            </div>
          </div>
          {!isIE && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
              className={classes.mask}
              id={"header-mask"}
            >
              <path
                fill="#fff"
                fillOpacity="1"
                d="M0,160L120,186.7C240,213,480,267,720,266.7C960,267,1200,213,1320,186.7L1440,160L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
              ></path>
            </svg>
          )}
        </div>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-label={"Weiser Memorial Hospital Video"}
          className={classes.modal}
        >
          <ReactPlayer url={vidURL} width={"400px"} controls />
        </Modal>
      </Overlay>
    </div>
  );
}
