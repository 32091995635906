import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(
  household_size,
  percent_100,
  percent_135,
  percent_140,
  percent_145,
  percent_150
) {
  return {
    household_size,
    percent_100,
    percent_135,
    percent_140,
    percent_145,
    percent_150,
  };
}

const rows = [
  createData("1", "$14,580", "$18,954", "$21,870", "$25,515", "$29,160"),
  createData("2", "$19,720", "$25,636", "$29,580", "$34,510", "$39,440"),
  createData("3", "$24,860", "$32,318", "$37,290", "$43,505", "$49,720"),
  createData("4", "$30,000", "$39,000", "$45,000", "$52,500", "$60,000"),
  createData("5", "$35,140", "$45,682", "$52,710", "$61,495", "$70,280"),
  createData("6", "$40,280", "$52,364", "$60,420", "$70,490", "$80,560"),
  createData("7", "$45,420", "$59,046", "$68,130", "$79,485", "$90,840"),
  createData("8", "$50,560", "$65,728", "$75,840", "$88,480", "$101,120"),
  createData("9", "$55,700", "$72,410", "$83,55o", "$97,475", "$111,400"),
  createData("10", "$60,840", "$79,092", "$91,260", "$106,470", "$121,680"),
  createData("Additional", "$5,140", "$6,682", "$7,710", "$8,995", "$10,280"),
];

export default function SlidingScale() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <caption>*Federal Poverty Level</caption>
        <TableHead>
          <TableRow>
            <TableCell>HOUSEHOLD SIZE</TableCell>
            <TableCell align="right">
              100% *FPL
              <br />
              ANNUAL GROSS.
            </TableCell>
            <TableCell align="right">
              130% *FPL
              <br />
              ANNUAL GROSS.
            </TableCell>
            <TableCell align="right">
              150% *FPL
              <br />
              ANNUAL GROSS.
            </TableCell>
            <TableCell align="right">
              175% *FPL
              <br />
              ANNUAL GROSS.
            </TableCell>
            <TableCell align="right">
              200% *FPL
              <br />
              ANNUAL GROSS.
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.household_size}>
              <TableCell component="th" scope="row">
                {row.household_size}
              </TableCell>
              <TableCell align="right">{row.percent_100}</TableCell>
              <TableCell align="right">{row.percent_135}</TableCell>
              <TableCell align="right">{row.percent_140}</TableCell>
              <TableCell align="right">{row.percent_145}</TableCell>
              <TableCell align="right">{row.percent_150}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
