import React from "react";
import { Chip, Grid, makeStyles } from "@material-ui/core";
import nurse from "../../../../../../assets/imgs/portrait/nursedrofficesec1.jpg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  darkContent: {
    background: theme.palette.primary.main,
    color: "white",
    padding: 10,
    marginBottom: 10,
  },
  staffChip: {
    background: theme.palette.secondary.main,
  },
}));

export default function VisitingHours(props) {
  const classes = useStyles();

  return (
    <Grid container justifyContent={"center"} wrap={"wrap-reverse"} spacing={3}>
      <Grid item md={6}>
        <Chip
          style={{ margin: 5 }}
          className={classes.staffChip}
          label="One (1) parent or guardian of a pediatric or special needs patient."
        />
        <Chip
          style={{ margin: 5 }}
          className={classes.staffChip}
          label={
            <p>
              One (1) visitor for a Labor & Delivery <br />
              patient for the duration of their stay.
            </p>
          }
        />
        <Chip
          style={{ margin: 5 }}
          className={classes.staffChip}
          label={
            <p>
              Other special circumstance for visitors will
              <br /> be determined on a case by case basis.
            </p>
          }
        />
        <h2 className={"primary-title"} style={{ marginTop: "2rem" }}>
          We encourage people to visit friends and family members
        </h2>
        <div className={classes.darkContent}>
          {/* <strike>
            <p>
              <b>
                <i>Patients may be visited at any time of day.</i>
              </b>
              <br />
              **Please note that the hospital doors are locked from 10 p.m. to 6
              a.m.
            </p>
          </strike> */}
          <p>
            Be sure to check with admissions or the main desk prior to visiting
            to make sure your visit occurs at the most appropriate time.
          </p>
          <p>
            Please{" "}
            <Link style={{ color: "white" }} to={"/contact"}>
              CONTACT US
            </Link>{" "}
            for additional information about visitation.
          </p>
        </div>
        <p style={{ marginTop: "2rem" }}>
          <i>
            <b>NOTICE: </b>
            If you are experiencing flu like symptoms, (i.e. cough, fever,
            stomach aches, etc.) we would ask that you delay coming to visit
            patients until you feel better. If circumstances prevent you from
            delaying your visit, there is hand sanitizer and face masks at each
            hospital entrance. Thank you for your consideration of the health of
            our staff and patients.
          </i>
        </p>
      </Grid>
      <Grid item md={6} className={classes.imgGrid}>
        <img
          className={classes.brochure}
          src={nurse}
          alt={"nurse at desk"}
          style={{ maxWidth: "100%" }}
        />
      </Grid>
    </Grid>
  );
}
