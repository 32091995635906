import { Button, Icon } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";

export default function OutdatedBrowser() {
  const dispatch = useDispatch();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexFlow: "column",
        height: "80vh",
        marginTop: "4rem",
      }}
    >
      <p>
        If this is an emergency dial{" "}
        <a style={{ color: "red", padding: 5 }} href={"tel:911"}>
          911
        </a>{" "}
        now.
      </p>
      <h2 className={"primary-title"}>
        We have detected that you are using an outdated & unsupported browser.
      </h2>
      <div className={"dark-content"} style={{ width: 300 }}>
        <Icon>
          <Warning color={"secondary"} style={{ height: 70, width: 70 }} />
        </Icon>
        <p>
          Continuing to use an outdated browser can lead to undesired visual
          effects and put your information at risk.
        </p>
        <p>
          We recommend updating your browser to a current version of
          <ul>
            <li>
              <a
                href={"https://www.google.com/chrome/"}
                style={{
                  color: "aliceblue",
                  textDecoration: "underline",
                }}
              >
                Chrome
              </a>
            </li>
            <li>
              <a
                href={"https://www.mozilla.org/en-US/firefox/"}
                style={{
                  color: "aliceblue",
                  textDecoration: "underline",
                }}
              >
                Firefox
              </a>
            </li>
            <li>
              <a
                href={"https://www.microsoft.com/en-us/edge"}
                style={{
                  color: "aliceblue",
                  textDecoration: "underline",
                }}
              >
                Microsoft Edge
              </a>
            </li>
            <li>
              <a
                href={"https://www.apple.com/safari/"}
                style={{
                  color: "aliceblue",
                  textDecoration: "underline",
                }}
              >
                Safari
              </a>
            </li>
          </ul>
        </p>
      </div>
      <Button
        variant={"text"}
        color={"primary"}
        onClick={() => dispatch({ type: "SET_isIE", payload: false })}
      >
        Continue to weisermemorialhospital.org
      </Button>
    </div>
  );
}
