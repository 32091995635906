import { Button, Grid, Paper } from "@material-ui/core";
import { Payment, ArrowRight } from "@material-ui/icons";
import React from "react";
// import ContactSelector from "../../../../../contact-components/contact-selector/contact-selector";
import SlidingScale from "./sliding-scale-table";

export default function FinancialAssistance(props) {
  // const contacts = require("../../../../../contact-components/contact-selector/contacts.json");
  return (
    <div id={"financial-assistance"}>
      <h2 style={{ display: "flex", alignItems: "center" }}>
        <Payment style={{ width: 50, height: 50 }} /> Financial Assistance
      </h2>
      <p>
        Weiser Memorial Hospital is here to help you understand the costs
        associated with your healthcare services and how best to meet your
        obligations. If you have any questions regarding health insurance,
        health services, billing, or related questions please do not hesitate to
        call our Patient Financial Services office at &nbsp;
        <a href={"tel:+12085494408"}>208-549-4408</a>.
      </p>
      <p>
        Financial counseling is available for self-pay patients, and for any
        patient who wants to make arrangements in advance of a planned
        procedure. Our knowledgeable staff will discuss options to assist you in
        making informed choices.
      </p>
      <p>
        This practice serves all patients regardless of their ability to pay.
        Discounts for essential services are offered based on family size and
        income. For more information, see below or call the hospital at{" "}
        <a href="tel:2085490370">208-549-0370</a>.
      </p>
      <Grid container spacing={3} style={{ marginTop: 30 }}>
        <Grid item md={12} style={{ maxWidth: "100%" }}>
          <Paper style={{ padding: 10 }}>
            <h3 style={{ textAlign: "center" }}>
              <b>
                Our office hours are from 8 A.M. to 5 P.M. Monday through
                Friday.
              </b>
            </h3>
            {/* <ContactSelector
              title={"Financial Assistance"}
              contacts={contacts.financial}
            /> */}
          </Paper>
        </Grid>
        <Grid item md={12}>
          <Grid container justifyContent={"center"}>
            <Grid item x2={12}>
              <div style={{ width: "88vw", maxWidth: 710, overflow: "auto" }}>
                <h3>Our sliding fee discount program:</h3>
                <SlidingScale />
              </div>
              <Button
                variant="link"
                component="a"
                href={
                  process.env.PUBLIC_URL +
                  "/files/finance/2023_financial_assistance_application.pdf"
                }
                target={"__blank"}
              >
                <ArrowRight />
                Financial Assistance Application
              </Button>
              <hr />
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                aria-label={"chargemaster links"}
              >
                <h3>WMH CHARGEMASTER DOWNLOAD LINKS</h3>
                <Button
                  variant={"contained"}
                  download={"WMH-Chargemaster.csv"}
                  color={"primary"}
                  style={{ margin: 5 }}
                  href={
                    process.env.PUBLIC_URL +
                    "/files/finance/Chargemaster-CURRENT.csv"
                  }
                >
                  WMH Chargemaster
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}