import { createTheme, ThemeProvider } from "@material-ui/core";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import React, { useEffect } from "react";
import "./App.css";
import Home from "./js/pages/home/home";
import PageFrame from "./js/pages/page-frame";
import ScrollToTop from "react-scroll-to-top";
import { KeyboardArrowUpRounded } from "@material-ui/icons";
import useGoogleAnalytics from "./js/components/ga/ga-hook";
import PageNotFound404 from "./js/pages/404/pagenotfound404";
import { useDispatch } from "react-redux";
import OutdatedBrowser from "./js/components/popups/outdated-browser";
import { routes } from "./routes";

const theme = createTheme({
  typography: {
    fontFamily: `"Karla", "sans-serif"`,
  },
  palette: {
    primary: {
      main: "#002b5c",
      rgb: "0, 43, 92",
    },
    secondary: {
      main: "#9fcbed",
      rgb: "159, 203, 237",
    },
    lightSecondary: {
      main: "rgba(159, 203, 237, 0.5)",
    },
    third: {
      main: "#e02f07",
    },
  },
});

function Routes() {
  const reload = () => window.location.reload();

  useGoogleAnalytics();

  return (
    <Switch>
      {/* Home Page */}
      <Route
        exact
        path="/index.html"
        render={() => <Redirect to={<Home />} />}
      />

      {routes.map((route) => (
        // Render routes from ./routes.js
        <Route
          exact
          path={route[0]}
          render={() => React.createElement(route[1])}
          key={route[0]}
        />
      ))}
      {/* Sitemap */}
      <Route exact path="/sitemap.xml" onEnter={reload} />

      <Route path="*">
        <PageNotFound404 />
      </Route>
    </Switch>
  );
}

function App() {
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const dispatch = useDispatch();

  useEffect(() => {
    isIE && dispatch({ type: "SET_isIE", payload: true });
  }, [isIE, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <PageFrame>
          {!isIE ? (
            <>
              {/* <HelpDesk /> */}
              <ScrollToTop smooth component={<KeyboardArrowUpRounded />} />
              <Routes />
            </>
          ) : (
            <OutdatedBrowser />
          )}
        </PageFrame>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
