import React, { useState } from "react";
import {
  Chip,
  Grid,
  Paper,
  makeStyles,
  Button,
  Modal,
  // ButtonGroup,
} from "@material-ui/core";
import labWork from "../../../../../assets/imgs/backgrounds/lab_work.jpg";
import labLogo from "../../../../../assets/imgs/logos/wmh/laboratory_logo.svg";

const useStyles = makeStyles((theme) => ({
  list: {
    background: theme.palette.primary.main,
    color: "white",
  },
  staffChip: {
    background: theme.palette.secondary.main,
  },
  paper: {
    maxWidth: 400,
  },
}));

export default function LabServices(props) {
  const [viewList, setViewList] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Modal
        open={viewList}
        onClose={() => setViewList(false)}
        aria-labelledby={"view-all-lab-tests"}
        aria-describedby={"View all the lab tests that WMH has to offer"}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper className={classes.paper}>
          <p>
            BLOOD BANK • Blood Typing; ABO and Rh • Direct Antiglobulin testing
            • Rh Immune globulin testing and dispensing. • Fetal Maternal
            Hemorrhage Screen • Blood Type & Antibody Screen • Blood Type &
            Compatibility Testing for Transfusion CARDIAC ENZYME • CK (Creatine
            Kinase) • CK-MB (Creatine Kinase – Muscle & Brain) • TROPONIN 1 •
            Beta Naturetic Peptide (BNP) CHEMISTRY • Basic Metabolic Panels
            (BMP) • Comprehensive Metabolic Panel (CMP) • Sodium • Potassium •
            Chloride • Total CO2 • Glucose testing and glucose tolerance testing
            • Blood Urea Nitrogen • Creatinine, serum and urine, including
            creatinine clearance and 24 hour urine level testing. • Calcium •
            Bilirubin, total and direct measurements • Alkaline Phosphatase •
            ALT • AST • Albumin • Total Protein, both serum and urine testing. •
            Amylase • Lipase • Lactic Acid • Uric Acid, serum and urine •
            Phosphorous • Magnesium • Lactate Dehydrogenase • Lipid studies •
            Cholesterol • Triglyceride • HDL Cholesterol, High Density
            Lipoprotein Cholesterol • LDL Cholesterol (calculated), Low Density
            Lipoprotein Cholesterol • Iron and Anemia testing (Iron, Iron
            Binding Capacity, Ferritin) • Liver and Kidney panels • Drug Screen
            Test • Thyroid Studies, including: Thyroid Stimulating Hormone (TSH)
            and Free Thyroxine 4 (Free T 4) • Hemoglobin A1C (Glycosylated
            Hemoglobin A1C • PSA (Prostate-Specific Antigen) • Quantitive Serum
            hCG (hormone human chorionic gonadotropin) - Pregnancy • Therapeutic
            Drug Level testing for: • Digoxin • Valproic Acid • Ethanol •
            Salicylate • Acetaminophen • Phenytoin (Dilantin) • Phenobarbital •
            Arterial Blood Gases
          </p>
        </Paper>
      </Modal>
      <Grid container>
        <Grid item>
          <h2 className={"primary-title"}>Laboratory Services</h2>
          <img src={labLogo} alt={"Cardio Logo"} style={{ maxWidth: "100%" }} />
          <div style={{ marginBottom: 10 }}>
            <Chip
              style={{ margin: 5 }}
              className={classes.staffChip}
              label={
                <p>
                  Outpatient: <b>Monday - Friday </b> from <b>7 A.M. - 5 P.M.</b>
                </p>
              }
            />
            <Chip
              style={{ margin: 5 }}
              className={classes.staffChip}
              label={
                <p>
                  Lab: <b>24 hrs</b> and <b>7 days a week</b>
                </p>
              }
            />
            <Chip
              style={{ margin: 5 }}
              className={classes.staffChip}
              label={
                <p>
                  <b>No</b> appointment required
                </p>
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              flexFlow: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>
              Weiser Memorial Hospital employs four full-time Medical
              Technologists, two part-time Medical Technologists and a
              phlebotomist/Lab Assistant in our modern, full-service Laboratory.
              Most results can be available and faxed to your Health Care
              Provider within <b>30 minutes</b> of testing. We are proud to
              announce our affiliation with the St. Luke’s Health System
              Reference Laboratory for the performance of microbiology cultures
              and ordered testing not performed at Weiser Memorial Hospital. We
              are eager to serve you, providing the best and fastest local care
              for your laboratory testing needs.
            </p>
            <img
              style={{ maxWidth: "fit-content" }}
              src={labWork}
              alt={"Lab work"}
            />
          </div>
              <Button
                variant={"text"}
                color={"primary"}
                onClick={() => setViewList(!viewList)}
                style={{margin: '10px auto'}}
              >
                Full Test List
              </Button>
        </Grid>
      </Grid>
    </>
  );
}
