import { Fade } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CardSection from "../../components/page-components/landing-page-components/card-section/first-card-section";
import ImgCardSection from "../../components/page-components/landing-page-components/card-section/img-card-section";
import MembersCardsSection from "../../components/page-components/landing-page-components/card-section/board-members-cards/members-card-section";
import SquareLinkCards from "../../components/page-components/landing-page-components/card-section/square-card-link-section";
import CeoSection from "../../components/page-components/landing-page-components/ceo-section/ceo-section";
import FoundationSection from "../../components/page-components/landing-page-components/foundation-section/foundation-section";
import Header from "../../components/page-components/landing-page-components/header/header";
import Helmet from "react-helmet";

export default function Home() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <Helmet>
        <title>Weiser Memorial Hospital</title>

        <meta
          name="description"
          content="Weiser Memorial Hospital is a full service not-for-profit community 
      hospital that has been serving the healthcare needs of Washington County and 
      surrounding areas since 1950. Weiser Memorial Hospital is an affiliate of the 
      St Luke’s Health System and offers a full spectrum of healthcare services."
        />
      </Helmet>
      <Fade in={loaded} timeout={1500}>
        <div>
          <Header />
          <CardSection />
          <ImgCardSection />
          <FoundationSection />
          <CeoSection />
          <MembersCardsSection />
          <SquareLinkCards />
        </div>
      </Fade>
    </>
  );
}
