import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";
import React, { useEffect, useState } from "react";

export default function FAQ(props) {
    const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      fetch(
        "https://raw.githubusercontent.com/wmhospital/website-data/main/FAQ.json"
      )
        .then((r) => r.json())
        .then((m) => setFaqs(m));
    }
    return () => (isSubscribed = false);
  }, []);

  return (
    <div>
      <h2>FAQ</h2>
      {faqs.map((f, i) => (
        <Accordion key={i}>
          <AccordionSummary
            expandIcon={<ExpandMoreRounded />}
            aria-controls={`${f.question}-open`}
            id={f.question}
          >
            {f.question}
          </AccordionSummary>
          <AccordionDetails>{f.answer}</AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
