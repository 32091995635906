import {
  ButtonBase,
  Container,
  Grid,
  makeStyles,
  Paper,
} from "@material-ui/core";
import React from "react";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: "4rem auto",
  },
  cardRoot: {
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    margin: "0.5rem",
    background: `rgba(${theme.palette.primary.rgb}, 0.85)`,
    color: "white",
    transition: "0.5s",
    textAlign: 'center',
    "&:hover": {
      background: `rgba(${theme.palette.primary.rgb}, 0.25)`,
    },
  },
  cardImage: {
    position: "absolute",
    zIndex: 0,
    width: "100%",
    height: "100%",
    "& img": {
      width: "auto",
      maxHeight: "100%",
      borderRadius: 10,
    },
  },
  cardContent: {
    zIndex: 2,
  },
  buttonBase: {
    maxWidth: 180,
    height: 130,
    border: "1.2px solid rgba(23,23,23,0.25)",
    borderRadius: 10,
    overflow: "hidden",
  },
}));

export default function SquareCards() {
  const classes = useStyles();

  const Square = (props) => {
    return (
      <ButtonBase
        focusRipple
        className={classes.buttonBase}
        component={HashLink}
        to={props.link}
      >
        <div className={classes.cardImage}>
          <img src={props.image} alt={props.title} />
        </div>
        <div className={classes.cardContent}>
          <Paper className={classes.cardRoot}>
            <b>{props.content && props.content}</b>
          </Paper>
        </div>
      </ButtonBase>
    );
  };

  return (
    <Container maxWidth={"md"} className={classes.root}>
      <h1 className={"primary-title"}>Useful Links</h1>
      <Grid container spacing={1} style={{ justifyContent: "center" }}>
        <Grid item>
          <Square
            content={"Patient/Visitor Information"}
            link={"/information#"}
          />
        </Grid>
        <Grid item>
          <Square
            content={"Weiser Memorial Hospital Foundation"}
            link={"/foundation#"}
          />
        </Grid>
        <Grid item>
          <Square
            content={"Friends of The Hospital"}
            link={"/foundation#friends-of-the-hospital"}
          />
        </Grid>
        <Grid item>
          <Square
            content={"CHNA Community Health Needs Assessment"}
            link={"/community#CHNA"}
          />
        </Grid>
        <Grid item>
          <Square
            content={"Patient Rights & Responsibilities"}
            link={"/information#patient-rights"}
          />
        </Grid>
        <Grid item>
          <Square
            content={"Community Education & Events"}
            link={"/community#"}
          />
        </Grid>
        {/* <Grid item>
          <Square
            content={"Daisy Award"}
            image={daisy}
            link={"/community#daisy-award"}
          />
        </Grid> */}
        <Grid item>
          <Square content={"Contact Us"} link={"/contact#"} />
        </Grid>
      </Grid>
    </Container>
  );
}
