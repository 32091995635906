import { Grid } from "@material-ui/core";
import React from "react";
import WINNER from "../../../../../assets/imgs/portrait/2021-FOT-winner.jpg";
import FOT from "../../../../../assets/imgs/ads/FOT-CURRENT.jpg";

export default function FestivalOfTrees(props) {
  return (
    <div>
      <h2 className={"primary-title"}>Festival of Trees</h2>
      <Grid container spacing={3}>
        {/* <Grid item md={12}>
          <h2>
            2022 Tickets are available here:{" "}
            <a href="http://www.weiserfestivaloftrees.afrogs.org">
              http://www.weiserfestivaloftrees.afrogs.org
            </a>
          </h2>
        </Grid> */}
        <Grid item md={6}>
          <h2 className={"primary-title"}>What is the festival?</h2>
          <div>
            <p>
              Festival of Trees is a multi-day public event that is the
              signature fundraiser for Weiser Memorial Hospital Foundation. The
              festival, held annually at the Vendome, in Weiser, ID features
              beautifully decorated trees, entertainment, community activities,
              food, shopping and more!
            </p>
            <p>
              For over 37 years, families and friends of all ages have eagerly
              awaited this fun-filled festival to kick-off the holiday season.
              With beautifully decorated trees, a gala, entertainment and
              special events, the annual Festival of Trees has become a
              tradition in the Washington County area.
            </p>
            <p>
              Over $700,000 has been raised through the Festival of Trees and
              invested in Weiser Memorial Hospital’s equipment and facilities.
              Proceeds from the Festival of Trees are used to fund WMH
              Foundation programming requests and grants. Previously funded
              projects include: equipment for the free Community Outdoor Gym,
              educational supplies for clinical staff and bassinets for the
              hospital’s Labor and Delivery Department.
            </p>
            {/* <Button color="secondary" variant="contained">
              <a href="http://www.weiserfestivaloftrees.afrogs.org">
                2022 Tickets
              </a>
            </Button> */}
          </div>
        </Grid>
        <Grid item md={6}>
          <h2 className={"primary-title"}>2021 First Place Tree</h2>
          <img src={WINNER} alt={"2021 winner"} style={{ maxWidth: "100%" }} />
          <p>
            <b>
              ** Festival of Trees is a volunteer supported event. Hundreds of
              volunteers give of their time to not only work at the festival but
              also help plan the event. In addition, the Weiser Memorial
              Hospital Foundation Board of Directors is busy planning almost
              year-round.
            </b>
          </p>
        </Grid>
      </Grid>
      {/* <Grid item md={12}>
        <img src={FOT} alt="Festival of Trees" style={{ maxWidth: "100%" }} />
      </Grid> */}
    </div>
  );
}
