import { Grid } from "@material-ui/core";
import React from "react";
import Tyler from "../../../../../assets/imgs/portrait/TylerJue.png";

export default function SkinCare() {
  return (
    <Grid container>
      <Grid item md="6">
        <p>
          Weiser Memorial Hospital (WMH) would like to re-introduce our wound
          care services. As you may know, we have offered limited wound care
          services over the years. We are happy to inform you we now offer
          advanced wound care services 5 days a week under the supervision of
          our new wound care provider, Tyler Jue ACNP-BC, CWS, CWCN-AP.
        </p>
        <p>
          WMH offers (but is not limited to) the management of diabetic ulcers,
          vascular (both arterial and venous) wounds, pressure wounds,
          autoimmune wounds, surgical wound complications, chronic ulcers,
          trauma wounds (ranging from skin tears/abrasions to full-thickness
          wounds), burns, and management of chronic edema.
        </p>
        <p>
          WMH can also provide advanced wound care, minor to extensive surgical
          wound debridement, incision and drainage abscesses, negative pressure
          wound therapy, diabetic foot assessment and nail care, paring/cutting
          down benign hyperkeratotic tissue (such as calluses), and nail removal
          with digital blocks. We look forward to offering even more services
          moving forward.
        </p>
      </Grid>
      <Grid item md="6">
        <img
          //   style={{ maxWidth: "fit-content" }}
          src={Tyler}
          alt={"Tyler Jue"}
        />
        <h2>
          <b>Tyler Jue</b> ACNP-BC, CWS, CWCN-AP
        </h2>
      </Grid>
    </Grid>
  );
}
