import { Grid } from "@material-ui/core";
import React from "react";
import giving from "../../../../../../assets/imgs/backgrounds/giftpresentyellowbow.jpg";

export default function OutrightGifts(props) {
  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <img
          src={giving}
          alt={"foundation giving"}
          style={{ maxWidth: "100%" }}
        />
      </Grid>
      <Grid item md={6}>
        <h2 className={"primary-title"}>Outright Gifts</h2>
        <div>
          <h3 className={"primary-title"}>Cash Gifts</h3>
          <p>
            This is the easiest and most convenient way to support the hospital.
            Donations may also be made online or by telephone. Please make
            checks payable to the Weiser Memorial Hospital Foundation.
          </p>
        </div>
        <div>
          <h3 className={"primary-title"}>Gifts of Rememberance</h3>
          <p>
            The Weiser Memorial Hospital Foundation gratefully accepts gifts
            made in honor or memory of a cherished family member, co-worker,
            care-giver or friend. An acknowledgment card is sent to the honored
            individual or to the family of the memorialized individual without
            mentioning the amount of the gift. Donations may also be made online
            or by telephone. Please make checks payable to the Weiser Memorial
            Hospital Foundation.
          </p>
        </div>
      </Grid>
      <Grid item md={12}>
        <div>
          <h3 className={"primary-title"}>Securities</h3>
          <p>
            Gifts of marketable securities can afford donors considerable tax
            advantages. In most cases, the full market value of the stock can be
            deducted as a charitable contribution. For additional information on
            making a gift in this way please contact our office at{" "}
            <a href={"tel:+12085494412"}>(208) 549-4412</a>.
          </p>
        </div>
        <div>
          <h3 className={"primary-title"}>Matching Grants</h3>
          <p>
            If you work for a company that has a matching gift program, you may
            double the strength of your gift. Many companies have these programs
            and we encourage you to check with yours. Matching grants can make a
            dramatic impact on patient care.
          </p>
        </div>
        <div>
          <h3 className={"primary-title"}>Payroll Deductions</h3>
          <p>
            Whether you're a small business, major corporation or even a
            nonprofit, you can join local businesses and organizations that run
            payroll deduction workplace campaigns each year.
          </p>
          <p>
            If you would like to learn more about starting a workplace campaign
            in your company or organization, or need assistance running your
            campaign, please contact our office at{" "}
            <a href={"tel:+12085494412"}>(208) 549-4412</a>.
          </p>
        </div>
      </Grid>
    </Grid>
  );
}
