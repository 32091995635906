import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import womanKid from "../../../../../../assets/imgs/portrait/mother-child4.jpg";

const useStyles = makeStyles((theme) => ({
  darkContent: {
    background: theme.palette.primary.main,
    color: "white",
    padding: 10,
    marginBottom: 10,
  },
}));

export default function PatientSafety(props) {
  const classes = useStyles();

  return (
    <Grid container alignItems={"center"} justifyContent={"center"} spacing={3}>
      <Grid item md={6}>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
          }}
        >
          <img style={{maxWidth: '100%'}} src={womanKid} alt={"patient safety"} />
        </div>
      </Grid>
      <Grid item md={6}>
        <h2>
          Thank you for giving us the opportunity to care for you or your loved
          one, we are here to serve.
        </h2>
        <h3>
          The Weiser Memorial Hospital staff including the administrative team,
          physicians, and clinical staff are highly committed and actively
          engaged in ensuring a safe quality experience with our patients while
          under our care.
        </h3>
        <div className={classes.darkContent}>
          <p>
            Weiser Memorial Hospital strives for excellence by voluntarily
            participating in the National Patient Safety Goals Project for
            Critical Access Hospitals, voluntary reporting to the Idaho Hospital
            Association and Centers for Medicare and Medicaid Services.
          </p>
          <p>
            In addition, ongoing education and partnerships with leading
            regional hospitals assists our staff in maintaining competency,
            expertise and opportunities for furthering education. As an
            organization we value teamwork and collaboration, not only with each
            other but with our families and patients. In fact, family
            involvement is critical to the recovery of our patients. We highly
            encourage open communication between family and staff to assist in
            care planning and meeting the needs of the patient.
          </p>
        </div>
      </Grid>
    </Grid>
  );
}
