import { List, ListItem, ListItemText } from "@material-ui/core";
import React from "react";

export default function ResourceList() {
  return (
    <div id="resources" style={{
      border: "1px solid black",
    }}>
      <List>
        <ListItem>
          <a
            href="https://acrobat.adobe.com/id/urn:aaid:sc:US:1d71a515-551d-4a41-8cc9-549113694e9c"
            target="_blank"
            rel="noopener noreferrer"
          >
            - WMH Services Brochure
          </a>
        </ListItem>
        <ListItem>
          <a
            href="https://acrobat.adobe.com/id/urn:aaid:sc:US:769e3bc2-bd87-4f3f-8ac7-917d27ae7d3c"
            target="_blank"
            rel="noopener noreferrer"
          >
            - Transitional Care Brochure
          </a>
        </ListItem>
        <ListItem>
          <a
            href={process.env.PUBLIC_URL + "/files/2024-discover-full-page.PNG"}
            target="_blank"
            rel="noopener noreferrer"
          >
            - Discover Services
          </a>
        </ListItem>
      </List>
      <List>
        <ListItemText typeof="p">Español</ListItemText>
        <ListItem>
          <a
            href={
              process.env.PUBLIC_URL +
              "/files/WMH_SwingBed_TransitionalCare_Bro_Span1.pdf"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            - Folleto de atención de transición (Transitional Care Brochure)
          </a>
        </ListItem>
      </List>
    </div>
  );
}
