import React from "react";
import {
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  makeStyles,
} from "@material-ui/core";
import cardioLogo from "../../../../../assets/imgs/logos/wmh/cardiopulminary_logo.svg";

const useStyles = makeStyles((theme) => ({
  list: {
    background: theme.palette.primary.main,
    color: "white",
  },
  staffChip: {
    background: theme.palette.secondary.main,
  },
}));

export default function CadioServices(props) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item>
        <h2 className={"primary-title"}>Cardiopulmonary Services</h2>
        <div style={{ marginBottom: 10 }}>
          <Chip
            style={{ margin: 5 }}
            className={classes.staffChip}
            label={
              <p>
                Respiratory Therapist <b>Monday - Friday</b> from <b>6 A.M. - 6:30 P.M.</b>
              </p>
            }
          />
        </div>
        <img
          src={cardioLogo}
          alt={"Cardio Logo"}
          style={{ maxWidth: "100%" }}
        />
        <Paper className={classes.list}>
          <List>
            <ListItem>
              <ListItemText>Oxygen Therapy - Set up oxygen</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                Medical Gas supply - Therapeutic Gases
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>Aerosol/Humidity Therapy</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>Chest Physiotherapy (CPT)</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>Incentive Spirometry</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>Continuous Mechanical Ventilation</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>Cardio-Pulmonary Resuscitation (CPR)</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                EKG Performance, Stress (treadmill) Test, Holter Monitor
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                Stat respiratory therapy and all other related respiratory
                emergencies
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                Pulmonary Function Testing including FVC
              </ListItemText>
            </ListItem>
          </List>
        </Paper>
        <p>
          **Prompt documentation of all services will be sent to the appropriate
          Health Care Providers, ensuring that your Provider can make the best
          health care decisions with you!
        </p>
      </Grid>
    </Grid>
  );
}
