import React from "react";
import { Grid } from "@material-ui/core";
import fruitbowl from "../../../../../../../assets/imgs/backgrounds/fruitbowl1.jpg";
import Bistro from "./bistro";

export default function FoodNutrition(props) {
  return (
    <Grid
      container
      spacing={3}
      justifyContent={"center"}
      alignItems={"center"}
      style={{ textAlign: "center" }}
    >
      <Grid item md={6}>
        <p>
          At Weiser Memorial Hospital, our staff knows that meals are an
          important part of the hospital experience. When you're sick, you want
          tasty, comforting food. That is why our patients get homemade food
          items, hot from the oven or grille, served with a variety of fresh
          fruits and vegetables.
        </p>
        <p>
          <i>*Our patients order breakfast, lunch and dinner from a menu.</i>
        </p>
        <Bistro />
      </Grid>
      <Grid item md={6}>
        <img style={{ maxWidth: "100%" }} src={fruitbowl} alt={"fruitbowl"} />
      </Grid>
    </Grid>
  );
}
