import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  makeStyles,
} from "@material-ui/core";
import CardioServices from "./cardio-services";
import { ExpandMoreRounded } from "@material-ui/icons";
import React from "react";
import ImagingServices from "./imaging-services";
import LabServices from "./lab-services";
import LaborDelivery from "./labor-delivery";
import { useSelector } from "react-redux";
import WaveTitle from "../../../../components/wave-title/wave-title";
import SkinCare from "./skin-care";

const useStyles = makeStyles((theme) => ({
  otherServicesRoot: {
    "& li": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));

export default function OtherServicesComponent(props) {
  const classes = useStyles();
  const openPanel = useSelector((state) => state.pagePanel);

  return (
    <div>
      <Grid
        container
        spacing={2}
        className={classes.otherServicesRoot}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item md={12} lg={12}>
          <WaveTitle title={"Other Services"} mask={"smooth-arch"} />
        </Grid>
        <Grid item style={{ alignSelf: "center" }} xs={12}>
          <Accordion defaultExpanded={openPanel === "skin-care"}>
            <AccordionSummary
              expandIcon={<ExpandMoreRounded />}
              aria-controls={"departments-lab-services"}
              id="lab-services"
            >
              <Typography>ADVANCED SKIN AND WOUND CARE</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SkinCare />{" "}
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={openPanel === "labor-delivery"}>
            <AccordionSummary
              expandIcon={<ExpandMoreRounded />}
              aria-controls={"labor-delivery-content"}
              id="labor-delivery"
            >
              <Typography>LABOR & DELIVERY SERVICES</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <LaborDelivery />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={openPanel === "cardio-services"}>
            <AccordionSummary
              expandIcon={<ExpandMoreRounded />}
              aria-controls={"departments-cardio-content"}
              id="cardio-services"
            >
              <Typography>CARDIOPULMONARY SERVICES</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CardioServices />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={openPanel === "imaging-services"}>
            <AccordionSummary
              expandIcon={<ExpandMoreRounded />}
              aria-controls={"departments-imaging-services"}
              id="imaging-services"
            >
              <Typography>DIAGNOSTIC IMAGING</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ImagingServices />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={openPanel === "lab-services"}>
            <AccordionSummary
              expandIcon={<ExpandMoreRounded />}
              aria-controls={"departments-lab-services"}
              id="lab-services"
            >
              <Typography>LABORATORY SERVICES</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <LabServices />{" "}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
}
