import React from "react";
// import GiveNow from "../ways-of-giving/give-now/give-now";

export default function Friends(props) {
  const year = new Date().getFullYear();

  return (
    <div>
      <h2 className={"primary-title"}>Friends of the Hospital</h2>
      <h3 className={"primary-title"}>Volunteer</h3>
      <p>
        <b>
          This volunteer group organized in April of 1986, is comprised of over
          50 community members, whose purpose of supporting the Hospital's
          endeavors through fund-raising for special projects and volunteering
          in-house
        </b>
        . The group has 5 main events through the year:
      </p>
      <ul>
        <li>February Recruitment Luncheon</li>
        <li>April Homemade Pies Sale</li>
        <li>October Homemade Pies Sale</li>
        <li>Fall Arts & Crafts Show Booth</li>
        <li>Festival of Trees - Decorate Tree</li>
      </ul>
      <p>
        Friends of the Hospital have raised thousands of dollars that
        continually benefit the Labor and Delivery department at Weiser
        Memorial. From the purchase of State of the Art warming beds for
        newborns to supplying a going home bag filled with hand made blankets,
        hats, booties, and cereal bowls; the Friends are an integral part of
        welcoming newborns.
      </p>
      <p>
        Most recently the Friends donated time and money to remodel the now
        beautiful and spacious delivery rooms. Other projects include the
        purchase of an ultrasound table, a rehabilitation bike, and the addition
        of a fireplace to the newly remodeled hospital admissions area.
      </p>
      <p>
        The Friends of the Hospital work throughout the year in a variety of
        project areas that benefit the health and care of patients and community
        members. Some of the volunteer opportunities include:
      </p>
      <ul>
        <li>Sewing baby blankets and burp cloths for new babies</li>
        <li>Baking pies for Friends Pie Sales</li>
        <li>
          Donating to and/or assembling children's activitiy bags for sibilings
          of patients
        </li>
        <li>Helping out with events or functions at the hospital</li>
        <li>Baking goods for the Arts & Craft Fair</li>
        <li>
          Participating in/or leading the community Fit and Fall Proof class.
        </li>
      </ul>
      <p>
        The Friends meet on the second Monday of each month, except during June,
        July, and August.
      </p>
      <h4 className={"primary-title"} style={{ marginBottom: 0 }}>
        {year} Officers
      </h4>
      <div style={{ marginLeft: 20 }}>
        <p style={{ margin: "0 auto" }}>
          <b>Layna Hafer</b> – <i>President</i>
        </p>
        <p style={{ margin: "0 auto" }}>
          <b>Terri Fritts</b> – <i>Vice President</i>
        </p>
        <p style={{ margin: "0 auto" }}>
          <b>Julie Chandler</b> – <i>Secretary</i>
        </p>
        <p style={{ margin: "0 auto" }}>
          <b>Sabrina Young</b> – <i>Treasurer</i>
        </p>
      </div>
      <p>
        The dues for one year are $5.00, and all are welcome to join. 
      </p>
      <h4>Memorial contributions or donations are always appreciated.</h4>

      <h4 className={"primary-title"} style={{ marginBottom: 0 }}>
        Please Send To:
      </h4>
      <p style={{ margin: "0 auto" }}>Friends of the Hospital</p>
      <a
        href={
          "https://maps.google.com/maps?q=465 E 5th Street Weiser, ID 83672"
        }
      >
        <p style={{ margin: "0 auto" }}>645 E. 5th Street</p>
        <p style={{ margin: "0 auto" }}>Weiser, ID 83672</p>
      </a>
      {/* <div style={{ margin: "10px auto" }}>
        <GiveNow />
      </div> */}
    </div>
  );
}
