import { Grid } from "@material-ui/core";
import React from "react";
import CHNAimg from "../../../../../assets/imgs/pdf-imgs/CHNA-2022.png";
import implement from "../../../../../assets/imgs/pdf-imgs/2022_implementation_plan_img.png";

export default function CHNA(props) {
  return (
    <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={12}>
        <h2 className={"primary-title"}>Community Health Needs Assessment</h2>
      </Grid>
      <Grid item md={6} style={{ textAlign: "center" }}>
        <h4>Assessment</h4>
        <a
          href={process.env.PUBLIC_URL + "/files/CHNA/chna-complete.pdf"}
          target={"_blank"}
        >
          <img
            src={CHNAimg}
            alt={"Assessment 2019"}
            style={{ maxWidth: "100%" }}
          />
        </a>
      </Grid>
      <Grid item md={6} style={{ textAlign: "center" }}>
        <h4>Implementation</h4>
        <a
          href={
            process.env.PUBLIC_URL + "/files/CHNA/2022_implementation_plan.pdf"
          }
          target={"_blank"}
        >
          <img
            src={implement}
            alt={"Implementation 2022"}
            style={{ maxWidth: "100%" }}
          />
        </a>
      </Grid>
    </Grid>
  );
}
