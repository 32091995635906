import React from "react";
import Page from "../page";
import { Helmet } from "react-helmet";
import ResourceList from "../../components/resources/resourceList";

export default function Resources(props) {
  const pageSections = [
    {
      //   title: "Resources",
      content: <ResourceList />,
    },
  ];
  return (
    <>
      <Helmet>
        <title>Resources</title>
        <meta name="description" content="WMH Resources." />
      </Helmet>
      <Page title="Resources" sections={pageSections} />
    </>
  );
}
