import { Grid, Button } from "@material-ui/core";
import React from "react";
import childbirth from "../../../../../../assets/imgs/pdf-imgs/childbirth-classes-img.png";

export default function ChildbirthClasses(props) {
  return (
    <Grid container justifyContent={"space-around"} alignItems={"center"} spacing={2}>
      <Grid item md={6} style={{ textAlign: "center" }}>
        <h2 className={"primary-title"}>
          Childbirth Education <i>**FREE!</i>
        </h2>
        <a
          href={
            process.env.PUBLIC_URL +
            "/files/childbirth-classes/childbirth-flyer.pdf"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={childbirth} alt={"infant"} style={{ maxWidth: "100%" }} />
        </a>
        <Button
          component="a"
          color="primary"
          type="outline"
          href="https://familymedicalweiser.org/services#maternity-care"
        >
          Family Medical Maternity Information
        </Button>
      </Grid>
    </Grid>
  );
}
