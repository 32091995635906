import { Button, ListItemIcon, Tooltip } from "@material-ui/core";
import { Phone } from "@material-ui/icons";
import React from "react";
import { HashLink } from "react-router-hash-link";
import ImgCard from "../img-card";
// import FMCImage from './FMC-img';
import MeetTheTeam from "./meet-your-providers/meet-your-providers";

export default function FMCImgCard(props) {
  return (
    <ImgCard
      image={<MeetTheTeam />}
      title={"Family Medical Center"}
      content={
        <p>
          When you are looking for a physician, we are here to provide you with
          personalized care in a friendly caring setting. We take the time to
          get to know you so we can better understand your healthcare needs. At
          Family Medical Center you will find that our staff is committed to
          making sure you have access to the care you need, when you need it. We
          welcome walk-ins and same day appointments.
        </p>
      }
      link={
        <div>
          <a
            href={"tel:1+2085494424"}
            style={{ textDecoration: "none", color: "initial" }}
            aria-label={"call family medical center"}
          >
            <Tooltip title={"(208) 549-4424"}>
              <Button
                variant={"contained"}
                color={"secondary"}
                style={{ margin: "0.5rem" }}
              >
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                Call Now
              </Button>
            </Tooltip>
          </a>
          <Button
            variant={"contained"}
            color={"primary"}
            component={HashLink}
            smooth
            to={"/services#family-medical-center"}
          >
            Learn More
          </Button>
        </div>
      }
    />
  );
}
