import { Button, Container, makeStyles, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { HashLink } from "react-router-hash-link";

export default function PageContentSection(props) {
  const useStyles = makeStyles((theme) => ({
    contentRoot: {
      margin: "4rem auto",
    },
    sectionRootOdd: {
      background: `radial-gradient(farthest-corner at 30% 40%, ${theme.palette.secondary.main} 5%, transparent 50%)`,
    },
    sectionRootEven: {
      background: `radial-gradient(farthest-corner at 70% 40%, ${theme.palette.secondary.main} 5%, transparent 50%)`,
    },
    sectionHeader: {
      width: "100%",
      background: theme.palette.primary.main,
    },
  }));

  const classes = useStyles();

  function BreadCrumbs(props) {
    return (
      <div className={classes.breadcrumbRoot} style={{ display: "flex" }}>
        <Button
          variant={"text"}
          color={"secondary"}
          component={HashLink}
          smooth
          to={"#top"}
        >
          {props.title}
        </Button>
        <p>/</p>
        <Button
          variant={"text"}
          color={"secondary"}
          component={HashLink}
          smooth
          to={`#${props.trail}`}
        >
          {props.trail}
        </Button>
      </div>
    );
  }

  return (
    props.sections &&
    Array.isArray(props.sections) &&
    props.sections.map((s, i) => (
      s.content&&
      <div
        id={s.linkTo}
        key={i}
        className={
          i % 2 === 0 ? classes.sectionRootOdd : classes.sectionRootEven
        }
      >
        <Container
          className={classes.contentRoot}
          maxWidth={"md"}
          style={{ display: "flex", flexFlow: "column" }}
        >
          {props.headers && (
            <div className={classes.sectionHeader}>
              <Container maxWidth={"xl"}>
                <BreadCrumbs title={props.title} trail={s.linkTo} />
              </Container>
            </div>
          )}
          <Paper
            elevation={4}
            style={{ background: i % 2 !== 0 ? "aliceblue" : "intial", padding: '1rem' }}
            className={classes.sectionRoot}
          >
            {s.content}
          </Paper>
        </Container>
      </div>
    ))
  );
}

PageContentSection.propTypes = {
  sections: PropTypes.array,
};
