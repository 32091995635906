import React from "react";
import { Grid, Button, makeStyles } from "@material-ui/core";
import brochure from "../../../../../../assets/imgs/backgrounds/R&R.png";

const useStyles = makeStyles((theme) => ({
  darkContent: {
    background: theme.palette.primary.main,
    color: "white",
    padding: 10,
    marginBottom: 10,
  },
  brochure: {
    maxHeight: 300,
    maxWidth: 200,
    transform: "rotate(4deg)",
    border: "2px solid grey",
    borderRadius: 5,
    margin: "15px auto",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  imgGrid: {
    background: `radial-gradient(farthest-corner at 50% 40%, ${theme.palette.secondary.main} 5%, transparent 50%)`,
  },
}));

export default function PatientRights(props) {
  const classes = useStyles();

  return (
    <Grid container alignItems={"center"} justifyContent={"center"} spacing={3}>
      <Grid item md={6}>
        <h2>Your Rights as a Patient</h2>
        <h3>
          Weiser Memorial Hospital employees and health care providers will show
          you respect, maintain your dignity and make you feel as comfortable as
          possible while you are receiving care from us.
        </h3>
        <div style={{ marginBottom: 20 }}>
          <a
            href={process.env.PUBLIC_URL + "/files/non-discrimination.pdf"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button size="small" type="link">
              Non-discrimination Policy / Complaints and Grievances
            </Button>
          </a>
        </div>
        <div className={classes.darkContent}>
          <p>
            Good health care is a partnership. You can help by providing
            accurate and timely information to your health care providers,
            asking questions, and following your individualized care plan. We
            want you and your family to make informed choices about your plan of
            care.
          </p>
          <p>
            Information about you and your care will be kept completely
            confidential (private). Your medical records are private and only
            you or other authorized people or agencies are allowed to see them.
            You have the right to see your medical records. To see your records,
            you will need to give us a request in writing. It may take a day or
            two for us to make your medical records available.
          </p>
        </div>
      </Grid>
      <Grid item md={6} className={classes.imgGrid}>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
          }}
        >
          <img
            className={classes.brochure}
            src={brochure}
            alt={"patient brochure"}
          />
          <>
            <a
              href={
                process.env.PUBLIC_URL +
                "/files/patient-rights-and-responsibilities-brochure-3-08-23.pdf"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant={"contained"} color={"primary"}>
                Patient Rights
              </Button>
            </a>
          </>
        </div>
      </Grid>
      <Grid item xs={12}>
        <h2>Spiritual Care</h2>
        <p>
          You and your family have the right to have your cultural,
          psychosocial, spiritual, and personal values, beliefs, and preferences
          respected, as long as they do not harm others or interfere with your
          treatment.
        </p>
        <p>
          If you would like to speak with a member of the clergy, please tell
          your caregiver. If you have spiritual beliefs regarding specific
          medical treatments, please discuss these beliefs with your physician.
        </p>
        <h2>Language Services</h2>
        <p>
          If you require special accommodations for vision, hearing, speech,
          language, or cognitive impairment, please tell Admitting staff or your
          nurse and we will do everything possible to meet these needs.
        </p>
        <p>
          <b>There is no charge for these services.</b>
        </p>
      </Grid>
    </Grid>
  );
}
