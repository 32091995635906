import React from "react";
import { MenuItem, makeStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Launch, PaymentRounded, VpnKey } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import ContactSelector from "../page-components/contact-components/contact-selector/contact-selector";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1.5rem",
    "& li": {
      marginBottom: 0,
    },
  },
  buttons: {
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    margin: "10px auto",
  },
  link: {
    textDecoration: "none",
    color: "initial",
    display: "flex",
    alignItems: "center",
  },
  noLarge: {
    display: "flex",
    justifyContent: "center",
    "@media(min-width: 650px)": {
      display: "none",
    },
  },
}));

export default function MenuItems() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const contacts = require("../page-components/contact-components/contact-selector/contacts.json");

  const closeMenu = () => {
    dispatch({ type: "TOGGLE_MENU", value: true });
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "space-around",
        }}
      >
        <div className={classes.noLarge}>
          <ContactSelector noLabel noIcon contacts={contacts.main} />
        </div>
      </div>
      <div
        style={{
          padding: 10,
        }}
      >
        {/* <hr /> */}
        <MenuItem onClick={closeMenu} component={Link} to={"/#"}>
          Home
        </MenuItem>
        <MenuItem onClick={closeMenu} component={Link} to={"/services#"}>
          Services
        </MenuItem>
        <MenuItem onClick={closeMenu} component={Link} to={"/careers#"}>
          Careers
        </MenuItem>
        <MenuItem onClick={closeMenu} component={Link} to={"/foundation#"}>
          Foundation
        </MenuItem>
        <MenuItem onClick={closeMenu} component={Link} to={"/information#"}>
          Patient/Visitor Information
        </MenuItem>
        <MenuItem onClick={closeMenu} component={Link} to={"/community#"}>
          Community Health
        </MenuItem>
        <MenuItem button component={Link} to={"/contact#"} onClick={closeMenu}>
          Contact Info
        </MenuItem>
        <MenuItem onClick={closeMenu} component={Link} to={"/about#"}>
          About Us
        </MenuItem>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexFlow: "column",
            marginLeft: "10px",
          }}
        >
          <a
            href={
              "https://zb.rpropayments.com/Login/CheckOutFormLogin/ZXtUJ5Nc-cu9Qzg-Uv2Uf2Wm-G4-"
            }
            rel={"noreferrer"}
            style={{
              textDecoration: "none",
              color: "initial",
              margin: 5,
            }}
            target={"_blank"}
          >
            <Button onClick={closeMenu} variant={"contained"} color={"primary"}>
              <PaymentRounded />
              Pay A Bill
            </Button>
          </a>
          <a
            href={"https://mycarecorner.net/Default.aspx"}
            style={{
              textDecoration: "none",
              color: "initial",
              margin: 5,
            }}
            target={"_blank"}
          >
            <Button
              onClick={closeMenu}
              variant={"contained"}
              color={"secondary"}
            >
              <Launch />
              Patient Portal
            </Button>
          </a>
          <a
            href={
              "https://passport.benefithub.com/?hsCtaTracking=42ac31b1-f946-457a-b6ce-279ccc044285%7C5968cb01-ae17-4453-8a56-7190a319ebfd"
            }
            style={{
              textDecoration: "none",
              color: "initial",
              margin: 5,
            }}
            target={"_blank"}
          >
            <Button
              onClick={closeMenu}
              variant={"contained"}
              color={"secondary"}
            >
              <VpnKey />
              Employee Login
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}
