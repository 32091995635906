import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import VisitingHours from "./information-components/visiting-hours";
import VisitorFood from "./information-components/visitor-food";

export default function VisitorInformation(props) {
  return (
    <div id={"visitor-information"}>
      <h2 className={"primary-title"}>Visitor Information</h2>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="open-visiting-hours"
          id="visiting-hours"
        >
          VISITING HOURS
        </AccordionSummary>
        <AccordionDetails>
          <VisitingHours />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="open-visitor-food-services"
          id="visitor-food-services"
        >
          FOOD SERVICE
        </AccordionSummary>
        <AccordionDetails>
          <VisitorFood />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
