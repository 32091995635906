import Careers from "./js/pages/careers/careers";
import Home from "./js/pages/home/home";
import Services from "./js/pages/services/services";
import Foundation from "./js/pages/foundation/foundation";
import PatientVisitorInfo from "./js/pages/patient-visitor-info/patient-visitor-info";
import CommunityEducationEvents from "./js/pages/community-education-events/community-education-events";
import Contact from "./js/pages/contact/contact";
import COVIDPage from "./js/pages/covid/covid-page";
import About from "./js/pages/about/about";
import MeetingArchive from "./js/pages/meeting-archive/meeting-archive";
import Resources from "./js/pages/resources/resources";

export const routes = [
  ["/", Home],
  ["/services", Services],
  ["/careers", Careers],
  ["/foundation", Foundation],
  ["/information", PatientVisitorInfo],
  ["/information#patient-information", PatientVisitorInfo],
  ["/community", CommunityEducationEvents],
  ["/contact", Contact],
  ["/about", About],
  ["/covid", COVIDPage],
  ["/archive", MeetingArchive],
  ["/resources", Resources],
];
