import React from "react";
import { Button, ButtonGroup, Chip, Grid, makeStyles } from "@material-ui/core";
import radiology from "../../../../../assets/imgs/backgrounds/radiology.jpeg";
import imagingLogo from "../../../../../assets/imgs/logos/wmh/diagnostic_imaging_logo.svg";

const useStyles = makeStyles((theme) => ({
  staffChip: {
    background: theme.palette.secondary.main,
  },
}));

export default function ImagingServices(props) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item>
        <h2 className={"primary-title"}>Diagnostic Imaging</h2>
        <div style={{ marginBottom: 20 }}>
          <Chip
            style={{ margin: 5 }}
            className={classes.staffChip}
            label={<p>X-Ray</p>}
          />
          <Chip
            style={{ margin: 5 }}
            className={classes.staffChip}
            label={<p>Mammography (St. Luke's Mobile Van)</p>}
          />
          <Chip
            style={{ margin: 5 }}
            className={classes.staffChip}
            label={<p>Fluoroscopy</p>}
          />
          <Chip
            style={{ margin: 5 }}
            className={classes.staffChip}
            label={<p>CT, CAT SCAN</p>}
          />
          <Chip
            style={{ margin: 5 }}
            className={classes.staffChip}
            label={<p>Ultrasound</p>}
          />
          <Chip
            style={{ margin: 5 }}
            className={classes.staffChip}
            label={<p>MRI</p>}
          />
          <Chip
            style={{ margin: 5 }}
            className={classes.staffChip}
            label={<p>Echocardiography</p>}
          />
        </div>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          spacing={2}
        >
          <Grid item lg={6}>
            <img
              src={imagingLogo}
              alt={"Cardio Logo"}
              style={{ maxWidth: "100%" }}
            />
            <div>
              <p>Hours of Operation:</p>
              <hr />
              <ul>
                <li>
                  <b>Ultrasound</b> Monday-Friday 7am-5:30pm
                </li>
                <li>
                  <b>MRI</b> Tuesday-Friday 7am-5:30pm{" "}
                </li>
                <li>
                  <b>Xray & CT</b> open 24/7
                </li>
                <li>
                  <b>Echocardiograms</b> Tuesday 8-430pm
                </li>
                <li>
                  <b>Fluoroscopy exams</b> Friday 9-noon
                </li>
              </ul>{" "}
            </div>

            <div className={"dark-content"}>
              <div style={{
                background: "white",
                color: "black",
                borderRadius: 10,
                padding: 10
              }}>
                Now offering the first of its kind MRI unit with the largest
                opening in the state. The new on-site MRI offers a more spacious
                and comfortable experience for patients.
              </div>
              <p>
                WMH provides service with state-of-the-art equipment for all
                modalities. We are committed to providing high quality
                diagnostic imaging to ensure up-to-date image/reporting
                capabilities, transferable via secure line transmission or disk
                produced on-site.
              </p>
            </div>
          </Grid>
          <Grid item lg={6}>
            <img
              style={{ maxWidth: "100%" }}
              src={radiology}
              alt={"Radiology"}
            />
          </Grid>
        </Grid>
        <p>
          **Radiology services and interpretation provided by Boise Radiology
          Group (BRG). Results will be provided the same day.
        </p>
        <ButtonGroup>
          <div style={{ display: "flex", flexFlow: "column" }}>
            <Button href="tel:12085494444" contained>
              Scheduling: 208-549-4444
            </Button>
            <Button href="fax:12085567958" contained>
              Scheduling Fax: 208-556-7958
            </Button>
          </div>
          <div style={{ display: "flex", flexFlow: "column" }}>
            <Button href="tel:12085494437" contained>
              Radiology: 208-549-4437
            </Button>
            <Button href="fax:12085494485" contained>
              Radiology Fax: 208-549-4485
            </Button>
          </div>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}
