import {
  ButtonBase,
  Container,
  Grow,
  makeStyles,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import mike from "../../../../../../assets/imgs/portrait/mike_hopkins.jpg";
import randy from "../../../../../../assets/imgs/portrait/randy_hibberd.jpg";
import david from "../../../../../../assets/imgs/portrait/davidprice.jpg";
import chuck from "../../../../../../assets/imgs/portrait/chuck_kroll.jpg";
import sherry from "../../../../../../assets/imgs/portrait/sherry_young.jpg";
// import sally from "../../../../../../assets/imgs/portrait/sally_bouvia.jpg";
import kyla from "../../../../../../assets/imgs/portrait/kyla-dickerson.jpg";
import veronica from "../../../../../../assets/imgs/portrait/veronica_vasquez.jpg";

const useStyles = makeStyles((theme) => ({
  rowRoot: {
    background: "rgba(255, 255, 255, 0.5)",
    padding: "1rem",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  cardRoot: {
    maxHeight: 150,
    maxWidth: 150,
    position: "relative",
    overflow: "hidden",
    margin: "0.5rem",
    borderRadius: 10,
    "@media(max-width: 767px)": {
      maxHeight: 100,
      maxWidth: 100,
      // margin: "2rem 10px",
    },
  },
  memberDataContent: {
    position: "absolute",
    top: "70%",
    zIndex: 20,
    padding: 5,
    background: theme.palette.primary.main,

    "& p": {
      margin: 0,
      color: "white",
    },
  },
}));

export default function MembersImgs(props) {
  const classes = useStyles();

  const Card = (props) => {
    const [showData, setShowData] = useState(false);

    useEffect(() => {
      showData &&
        setTimeout(() => {
          setShowData(false);
        }, 3000);
    }, [showData]);

    return (
      <ButtonBase onClick={() => setShowData(true)} style={{ margin: 5 }}>
        <div className={classes.cardRoot}>
          {props.image ? (
            <img src={props.image} alt={props.name} style={{ width: 85 }} />
          ) : (
            <>{props.name}<br/>(Coming Soon)</>
          )}
        </div>
        <Grow in={showData}>
          <Paper className={classes.memberDataContent}>
            <p>{props.name},</p>
            <p>
              <i>{props.title}</i>
            </p>
            <p>
              <b>{props.term}</b>
            </p>
          </Paper>
        </Grow>
      </ButtonBase>
    );
  };

  return (
    <Container maxWidth={"lg"} className={classes.rowRoot}>
      <Card
        image={mike}
        name={"Mike Hopkins"}
        title={"Chairperson"}
        term={"05/2013-05/2025"}
      />
      <Card
        image={kyla}
        name={"Kyla Dickerson"}
        title={""}
        term={"7/01/21 – 6/30/27"}
      />
      <Card
        image={randy}
        name={"Randy Hibberd"}
        title={"Secretary Treasurer"}
        term={"7/01/21 – 6/30/27"}
      />
      <Card
        image={david}
        name={"David Price"}
        title={"Board Vice Chairman"}
        term={"02/2011-05/2023"}
      />
      <Card
        image={sherry}
        name={"Sherry Young"}
        title={""}
        term={"06/2019-05/2025"}
      />
      <Card
        image={chuck}
        name={"Chuck Kroll"}
        title={""}
        term={"03/2014-05/2025"}
      />
      <Card
        image={veronica}
        name={"Veronica Vasquez"}
        title={"Trustee"}
        term={"09/2023-09/2029"}
      />
    </Container>
  );
}
