import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";
import React from "react";
import FoundationContact from "../../landing-page-components/foundation-section/foundation-contact/foundation-contact";
import OutrightGifts from "./outright-gifts/outright-gifts";
import WillTrust from "./will-trust/will-trust";

export default function WaysOfGiving(props) {
  return (
    <Container>
      <div>
        <h2 className={"primary-title"}>Ways of Giving</h2>
        <p>
          There are many ways to give to the Foundation, including cash, estate
          planning, memorials, property or assets that appreciate over the
          years, such as stocks and bonds. Gifts can be arranged in a variety of
          ways. In all instances, donors may restrict gifts to specific
          purposes. Should you have any questions, we encourage you to contact
          the Foundation office or your attorney.
        </p>
        <h4 className={"primary-title"}>Your generosity will help:</h4>
        <ul>
          <li>Ensure continued excellence in the future of the hospital.</li>
          <li>
            Strengthen and maintain the quality of existing primary care
            services.
          </li>
          <li>
            Respond to the need for charitable contributions to help fill the
            gap between costs and the actual amount collected.
          </li>
          <li>Receive acknowledgement and record of your gift.</li>
          <li>Share the pride of other supporters and the community.</li>
          <li>
            Assist in meeting the challenges facing all rural hospitals trying
            to find a balance in funding.
          </li>
          <li>Receive a tax deduction benefit.</li>
        </ul>
      </div>

      {/* Outright Gifts Section */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls={"outright-gifts"}
          id="outright-gifts"
        >
          OUTRIGHT GIFTS
        </AccordionSummary>
        <AccordionDetails>
          <OutrightGifts />
        </AccordionDetails>
      </Accordion>

      {/* Will Or Trust Section */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls={"will-or-trust"}
          id="will-or-trust"
        >
          WILL OR TRUST
        </AccordionSummary>
        <AccordionDetails>
          <WillTrust />
        </AccordionDetails>
      </Accordion>

      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={3}
      >
        <Grid item md={6}>
          <FoundationContact />
        </Grid>
      </Grid>
    </Container>
  );
}
