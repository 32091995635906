import { ButtonBase, Icon, makeStyles } from "@material-ui/core";
import { Devices } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
  rootLink: {
    textDecoration: "none",
    color: "initial",
    '&:hover': {
        color: 'white'
    }
  },
  buttonBase: {
    background: theme.palette.secondary.main,
    margin: 20,
    padding: 10,
    width: "fit-content",
    borderRadius: 10,
    transition: 'all 0.5s ease',
    '&:hover': {
        background: theme.palette.primary.main
    }
  },
  paper: {},
  icon: {
    margin: 2.5,
  },
}));

export default function PayABillButton(props) {
  const classes = useStyles();

  return (
    <a
      href={
        "https://zb.rpropayments.com/Login/CheckOutFormLogin/ZXtUJ5Nc-cu9Qzg-Uv2Uf2Wm-G4-"
      }
      className={classes.rootLink}
    >
      <ButtonBase className={classes.buttonBase}>
        <Icon className={classes.icon}>
          <Devices />
        </Icon>
        <div className={classes.paper}>
          <p>PAY ONLINE</p>
        </div>
      </ButtonBase>
    </a>
  );
}
