import {
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import React from "react";
import nurses from "../../../../../assets/imgs/portrait/nurses5.jpg";
import JobOpenings from "../job-openings/job-openings";

const useStyles = makeStyles((theme) => ({
  list: {
    background: theme.palette.primary.main,
    color: "white",
    padding: 10,
    "& li": {
      padding: "0 8px",
    },
  },
  listTwo: {
    background: theme.palette.secondary.main,
    padding: 10,
  },
}));

export default function BenefitsSummary(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={2} alignItems={"center"} justifyContent={"center"}>
      <Grid item md={6}>
        <h2>Benefits Summary</h2>
        <p>
          Along with the opportunity to work with our team of extraordinary
          people to help deliver high quality healthcare with a personal touch,
          Weiser Memorial Hospital provides a great place to work, competitive
          salaries, and excellent benefits for employees who are classified as
          full or part-time.
        </p>
        <Grid
          container
          alignItems={"flex-start"}
          justifyContent={"center"}
          spacing={2}
        >
          <Grid item>
            <List className={classes.list} subheader={"Retirement"}>
              <Paper>
                <ListItem>
                  <ListItemText>
                    PERSI (Base Plan) – Idaho State Retirement
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    PERSI (401K Choice Plan) – Voluntary Retirement Plan
                  </ListItemText>
                </ListItem>
              </Paper>
            </List>
          </Grid>
          <Grid item>
            <List
              className={classes.list}
              subheader={
                <>
                  <p style={{ marginBottom: 0 }}>Paid Time Off Accruals</p>
                  <p style={{ margin: 2.5, marginLeft: 5, fontSize: 15 }}>
                    *Accrue time based on hours worked up to 40 per week/80 per
                    pay period
                  </p>
                </>
              }
            >
              <Paper>
                <ListItem>
                  <ListItemText>
                    Paid Time Off (PTO) Accrual: 1-5 years = 22 days/year
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>&nbsp;Maximum accrual = 220 hours</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    Extended Illness Bank (EIB): 80 hours/year
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>&nbsp;Maximum accrual = 520 hours</ListItemText>
                </ListItem>
              </Paper>
            </List>
          </Grid>
          <Grid item>
            <List className={classes.list} subheader={"Insurance Benefits"}>
              <Paper>
                <ListItem>
                  <ListItemText>
                    Major Medical (Including Prescription)
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Dental</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Vision</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Life Insurance</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Long Term Disability Insurance</ListItemText>
                </ListItem>
              </Paper>
            </List>
          </Grid>
          <Grid item>
            <List className={classes.list} subheader={"Voluntary Benefits"}>
              <Paper>
                <ListItem>
                  <ListItemText>
                    Colonial Life (Accident, Short Term Disability, Medical
                    Bridge, Cancer, Term & Universal Life, Dental)
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    Employee Medical Services Discount
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    Voluntary Life $100,000 Guarantee Issue
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Employee Assistance Program</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Employee Wellness Program</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Air St Luke's Membership Discount</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Tuition Reimbursement</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Travel and Training Benefits</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    Night, Evening, and Weekend Shift Differentials
                  </ListItemText>
                </ListItem>
              </Paper>
            </List>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6}>
        <div style={{ textAlign: "center" }}>
          <img style={{ maxWidth: "100%" }} src={nurses} alt={"nurses"} />
        </div>
      </Grid>
      <Grid item>
        <p>
          <i>All positions remain open until filled.</i>
        </p>
        <p>
          Weiser Memorial Hospital is an Equal Opportunity Employer (EOE)
          offering a drug and tobacco free work environment.
        </p>
      </Grid>
      <Grid item xs={12}>
        <JobOpenings />
      </Grid>
    </Grid>
  );
}
