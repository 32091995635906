import React from "react";
import ad from "../../../../../../../assets/imgs/ads/main_ad.png";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  drPeterson: {
    color: "white",
  },
  petersonLogo: {
    maxWidth: "25%",
    padding: 15,
    background: "white",
    borderRadius: 50,
  },
  mainAd: {
    maxWidth: "100%",
    paddingTop: 10,
    borderRadius: 10,
    minHeight: 360,
    "@media(max-width: 1200px)": {
      minHeight: 300,
    },
    "@media(max-width: 767px)": {
      minHeight: 250,
    },
  },
}));

export default function SSClinicImg(props) {
  const classes = useStyles();

  return (
    <div className={classes.drPeterson}>
      <img
        src={props.image || ad}
        alt={props.alt || "Surgical Services Clinic"}
        className={classes.mainAd}
      />
      {/* <Grid
        container
        alignItems={"center"}
        spacing={2}
        style={{ width: "initial", margin: "unset" }}
      >
        <Grid item xs={12} sm={6}>
          <img
            src={props.image || liby}
            alt={props.alt || "DR. LIBY, D.O."}
            style={{ maxWidth: "100%", paddingTop: 10, borderRadius: 10 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <h1>{props.title || "DR. LIBY, D.O."}</h1>

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            {!props.title && props.title}
            <div>
              <h2 style={{ margin: 0, fontFamily: "Karla" }}>
                {props.drType || <i>General Surgeon</i>}
              </h2>
            </div>
          </div>
          {props.description && (
            <div>
              <h3 style={{ margin: 0, fontFamily: "Karla" }}>
                {props.description}
              </h3>
            </div>
          )}
        </Grid>
      </Grid>
      <h3 style={{ margin: 5, paddingBottom: 10 }}>
        <b>Call Today (208) 549-3152</b>
      </h3> */}
    </div>
  );
}
