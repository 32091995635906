import { LocalHospital, Assignment, WorkRounded } from "@material-ui/icons";
import React from "react";
import {
  Button,
  Grid,
  Hidden,
  List,
  ListItem,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { HashLink } from "react-router-hash-link";
import { useDispatch } from "react-redux";
import WaveTitle from "../../../../components/wave-title/wave-title";

const useStyles = makeStyles((theme) => ({
  sectionRoot: {
    margin: "4rem auto",
  },
  frontBox: {
    padding: 10,
    borderRadius: 8,
    maxWidth: 300,
    margin: 5,
    "@media(max-width: 989px)": {
      margin: "20px auto",
      maxWidth: "90%",
    },
  },
  textContainer: {
    width: "100%",
    height: "100%",
    "& p": {
      padding: 5,
    },
  },
  title: {
    background: "#002b5c",
    color: "black",
    borderTopRightRadius: 5,
    "& h2": {
      padding: 20,
      margin: 0,
      marginBottom: -10,
      color: "white",
      boxShadow: "4.5px -2px 3px -2px #000000",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderTopRightRadius: 5,
      "& svg": {
        height: 50,
        width: 50,
      },
    },
  },
}));

export default function CardSection() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const setPanel = (id) => {
    dispatch({ type: "SET_PANEL", payload: id });
  };

  const cards = [
    {
      link: "/information#",
      icon: <Assignment />,
      title: "Patient/Visitor Information",
      // content:
      //   "Our bill pay simplifies the billing process, putting you in control of when and how you pay.",
      list: [
        ["PATIENT INFORMATION", "patient-information"],
        ["BILLING", "billing"],
        ["FINANCIAL ASSISTANCE", "financial-assistance"],
        ["PATIENT RIGHTS", "patient-information"],
        ["VISITOR INFORMATION", "visitor-information"],
      ],
    },
    {
      link: "/services#",
      icon: <LocalHospital />,
      title: "Our Services",
      // content: `When you turn to Weiser Memorial Hospital you're turning to some of the most skilled and caring professionals in their fields.`,
      list: [
        ["FAMILY MEDICAL CENTER", "family-medical-center"],
        ["SURGICAL & SPECIALTY CLINIC", "surgical-specialty-clinic"],
        ["TRANSITIONAL CARE", "swing-bed"],
        ["EMERGENCY SERVICES", "emergency-services"],
        ["LABOR & DELIVERY SERVICES", "labor-delivery"],
        ["CARDIOPULMONARY SERVICES", "cardio-services"],
        ["IMAGING/LABORATORY", "imaging-services"],
      ],
    },
    {
      link: "/careers#",
      icon: <WorkRounded />,
      title: "Career Opportunities",
      // content:
      //   "Weiser Memorial Hospital offers a great place to work, competitive salaries, and excellent benefits.",
      list: [
        ["VIEW JOB OPENINGS", "top"],
        ["EMPLOYMENT CRITERIA", "employment-criteria"],
        ["BENEFITS SUMMARY", "benefits-summary"],
      ],
    },
    {
      title: "Quick Links",
      list: [
        ["Physician Directory", "/contact#physician-directory"],
        ["Hospital Programs", "/community#programs"],
        ["Festival of Trees", "/foundation#festival-of-trees"],
        ["Admin/Board Members", "/about#leadership"],
        [
          "Privacy Practices (HIPAA)",
          `${process.env.PUBLIC_URL + "/files/HIPAA_Notice.pdf"}`,
          true,
        ],
        ["FAQ", "/about#faq"],
        ["Resources", "/resources"],
      ],
    },
  ];

  const Card = (props) => {
    return (
      <Paper className={classes.frontBox}>
        <div>
          <WaveTitle title={props.title} icon={props.icon} mask={"gentle-wave"}>
            <p>{props.content}</p>
            {props.list && (
              <Paper
                elevation={0}
                className={"dark-content"}
                style={{ background: "aliceblue" }}
              >
                <List style={{ textAlign: "center", marginTop: "1rem" }}>
                  {Array.isArray(props.list) &&
                    props.list.map((i, index) => (
                      <ListItem key={index}>
                        <Button
                          variant={"text"}
                          color={"primary"}
                          style={{
                            width: "100%",
                            margin: 0,
                            padding: 0,
                            color: "black",
                            textDecoration: "none",
                          }}
                          onClick={() => setPanel(i[1])}
                          component={!i[2] ? HashLink : "a"}
                          href={`${props.link ? `${props.link}` : ""}${i[1]}`}
                          to={`${props.link ? `${props.link}` : ""}${i[1]}`}
                        >
                          {i[0]}
                        </Button>
                      </ListItem>
                    ))}
                  {props.link && (
                    <ListItem>
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        style={{ width: "100%", margin: 0, padding: 0 }}
                        component={HashLink}
                        to={props.link}
                      >
                        Learn More
                      </Button>
                    </ListItem>
                  )}
                </List>
              </Paper>
            )}
          </WaveTitle>
        </div>
      </Paper>
    );
  };

  return (
    <div className={classes.sectionRoot}>
      <Hidden mdUp>
        <Carousel style={{ background: "white" }} showThumbs={false}>
          {cards.map((c, i) => (
            <div className={classes.gridItem} key={i * 0.2}>
              <Card
                link={c.link}
                icon={c.icon}
                title={c.title}
                content={c.content}
                list={c.list}
              />
            </div>
          ))}
        </Carousel>
      </Hidden>
      <Hidden smDown>
        <Grid
          container
          // spacing={2}
          wrap={"nowrap"}
          justifyContent={"center"}
        >
          {cards.map((c, i) => (
            <Grid item className={classes.gridItem} key={i * 0.1}>
              <Card
                link={c.link}
                icon={c.icon}
                title={c.title}
                content={c.content}
                list={c.list}
              />
            </Grid>
          ))}
        </Grid>
      </Hidden>
    </div>
  );
}
