import {
  Button,
  Grow,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import React, { useState } from "react";
import ImgCard from "../img-card";
import { Assignment, Phone } from "@material-ui/icons";
import { Link } from "react-router-dom";
import SSClinicImg from "./SSClinic-img";

export default function SSClinicImgCard(props) {
  const [showForms, setShowForms] = useState(false);

  return (
    <ImgCard
      image={
        <SSClinicImg description="Providing colonoscopies, endoscopies, hernia repairs, soft tissue repairs, and more." />
      }
      title={"Surgical & Specialty Clinic"}
      content={
        <>
          <p style={{ marginBottom: 0 }}>
            The Surgical & Specialty Clinic has been in service since 1986, and
            at our current location at the front entrance of the hospital since
            1991. Our clinic is a facility where physicians practicing
            specialized medicine can provide service for our local community. We
            work closely with the Family Practice offices in making these
            services available close to home.
          </p>
          <Button
            style={{ marginBottom: 10, display: "flex", alignItems: "center" }}
            variant={"text"}
            onClick={() => setShowForms(!showForms)}
          >
            <Assignment />
            PATIENT FORMS
          </Button>
          <Grow unmountOnExit in={showForms}>
            <List>
              <a
                href={
                  process.env.PUBLIC_URL +
                  "/files/downloads/forms/wmh_surgicalspecialtyclinic_patientregistration_fill-in.pdf"
                }
              >
                <ListItem>
                  <ListItemText>Patient Registration Form</ListItemText>
                </ListItem>
              </a>
              <a
                href={
                  process.env.PUBLIC_URL +
                  "/files/downloads/forms/wmh_surgical-specialtyclinic_medicalhistory_form.pdf"
                }
              >
                <ListItem>
                  <ListItemText>Medical History Form</ListItemText>
                </ListItem>
              </a>
            </List>
          </Grow>
        </>
      }
      link={
        <div>
          <a
            href={"tel:1-2085493152"}
            style={{ textDecoration: "none", color: "initial" }}
            aria-label={"call surgical specialty clinic"}
          >
            <Button
              variant={"contained"}
              color={"secondary"}
              style={{ margin: "0.5rem" }}
            >
              <ListItemIcon>
                <Phone />
              </ListItemIcon>
              Call Now
            </Button>
          </a>
          <Button
            variant={"contained"}
            color={"primary"}
            style={{ margin: "0.5rem" }}
            component={Link}
            to={"/services/"}
          >
            Learn More
          </Button>
        </div>
      }
    />
  );
}
