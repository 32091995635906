import React, { useEffect } from "react";
import Page from "../page";
import shots from "../../../assets/imgs/portrait/getting-shots.jpg";
import CommunityHealth from "../../components/page-components/community-health/community-health/community-health";
// import InTheNews from "../../components/page-components/community-health/in-the-news/in-the-news";
// import DaisyAward from "../../components/page-components/community-health/daisy-award/daisy-award";
import Helmet from "react-helmet";
import CHNA from "../../components/page-components/about/chna/chna";

export default function CommunityEducationEvents() {
  const pageSections = [
    {
      title: "Community Health",
      linkTo: "community-health",
      content: <CommunityHealth />,
    },
    // {
    //   title: "Daisy Award",
    //   linkTo: "daisy-award",
    //   content: <DaisyAward />,
    // },
    // {
    //   title: "In the News",
    //   linkTo: "in-the-news",
    //   content: <InTheNews />,
    // },
    {
      title: "Community Health Needs Assessment",
      linkTo: "CHNA",
      content: <CHNA />,
    },
  ];

  useEffect(() => {
    document.title = "Community Health";
  }, []);

  return (
    <>
      <Helmet>
        <title>Community Health</title>

        <meta
          name="description"
          content="Weiser Memorial Hospital would like to encourage our patients and community to explore health issues."
        />
      </Helmet>
      <Page
        background={shots}
        title={"Community Health"}
        sections={pageSections}
        noHeaders
        svgID={3}
        imgStyles={{
          height: "80vh",
          width: "auto",
        }}
      />
    </>
  );
}
