import React, { useEffect } from "react";
import hiring from "../../../assets/imgs/backgrounds/were-hiring.svg";
import EmploymentCriteria from "../../components/page-components/careers/employment-criteria/employment-criteria";
import BenefitsSummary from "../../components/page-components/careers/benefits-summary/benefits-summary";
import Page from "../page";
import Helmet from "react-helmet";

export default function Careers() {
  useEffect(() => {
    document.title = "Careers";
  });

  const pageSections = [
    {
      title: "Employment Criteria",
      linkTo: "employment-criteria",
      content: <EmploymentCriteria />,
    },
    {
      title: "Benefits Summary",
      linkTo: "benefits-summary",
      content: <BenefitsSummary />,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Careers</title>
        <meta
          name="description"
          content="Weiser Memorial Hospital offers a great place to work, competitive salaries, and excellent benefits."
        />
      </Helmet>
      <Page
        background={hiring}
        title={"Careers"}
        sections={pageSections}
        noHeaders
        bgContain
        imgStyles={{
          marginTop: "7rem",
          width: "60%",
        }}
      />
    </>
  );
}
