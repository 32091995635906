import { Button, Paper } from "@material-ui/core";
import React from "react";

import ContactCard from "../../contact-components/contact-card/contact-card";

import workplace from "../../../../../assets/imgs/logos/workplace2023.png";

export default function JobOpenings(props) {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <div
        style={{
          margin: "20px auto",
          textAlign: "left",
          alignItems: "center",
          display: "flex",
        }}
      >
        <img
          src={workplace}
          alt="Best Places To Work 2022"
          style={{ maxHeight: 120, marginRight: 20 }}
        />
        <ContactCard
          label={"HUMAN RESOURCES OFFICE"}
          href={"650 State St Weiser, ID 83672"}
          address={
            <>
              <p>650 State St</p> <p>Weiser, ID 83672</p>
            </>
          }
          phone={"208-549-4413"}
          fax={"208-414-4268"}
          email={"humanresources@weiserhospital.org"}
        />
      </div>
      <Paper
        elevation={0}
        style={{ maxWidth: 400, margin: "20px auto", padding: 5 }}
      >
        <h2>View all job openings here!</h2>
        <a
          style={{ textDecoration: "none" }}
          href={
            "https://recruiting.paylocity.com/recruiting/jobs/All/b043e216-a149-4196-85b5-f47956158e54/Weiser-Valley-Hospital-District"
          }
        >
          <Button variant={"contained"} color={"secondary"}>
            Job Openings
          </Button>
        </a>
      </Paper>
    </div>
  );
}
