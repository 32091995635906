import React from "react";
import { Container, Grid } from "@material-ui/core";
import ContactCard from "../contact-card/contact-card";
// import ContactSelector from "../contact-selector/contact-selector";

export default function ContactInfo(props) {
  // const contacts = require("../contact-selector/contacts.json");

  return (
    <Container>
      <Grid container justifyContent={"space-around"} spacing={4}>
        <Grid item xs={12}>
          <h2>Contact Information</h2>
        </Grid>
        <Grid item style={{ background: "aliceblue" }}>
          <ContactCard
            label={"WEISER MEMORIAL HOSPITAL"}
            href={"645 East 5th Street Weiser, ID 83672"}
            address={
              <>
                <p>645 East 5th Street</p> <p>Weiser, ID 83672</p>
              </>
            }
            phone={"208-549-0370"}
            // fax={"208-549-4146"}
          />
        </Grid>
        <Grid item>
          <ContactCard
            label={"SURGICAL & SPECIALTY CLINIC"}
            href={"645 East 5th Street Weiser, ID 83672"}
            address={
              <>
                <p>645 East 5th Street</p> <p>Weiser, ID 83672</p>
              </>
            }
            phone={"208-549-3152"}
            // fax={"208-549-3153"}
          />
        </Grid>
        <Grid item style={{ background: "aliceblue" }}>
          <ContactCard
            label={"FAMILY MEDICAL CENTER"}
            href={"360 East Liberty Weiser, Idaho 83672"}
            address={
              <>
                <p>360 East Liberty</p> <p>Weiser, ID 83672</p>
              </>
            }
            phone={"208-549-4424"}
            // fax={"208-549-4146"}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <ContactSelector
            title={"General Contact"}
            contacts={[...contacts.main, ...contacts.financial]}
          />
        </Grid> */}
        <Grid item xs={12}></Grid>
        <iframe
          title={"WMH Map"}
          style={{ marginBottom: 20 }}
          width="400"
          height="500"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=645%20East%205th%20Street%20Weiser,%20ID%2083672&t=&z=15&ie=UTF8&iwloc=&output=embed"
        ></iframe>
      </Grid>
    </Container>
  );
}
